import * as yup from 'yup'
import { TransactionFeeType } from '../../../../core/interfaces'

export const countrySchema = yup.object().shape({
  name: yup.string().required(),
  code: yup.string().required(),
  numberCode: yup.number().required(),
  currencyCode: yup.string().required(),
  currencySymbol: yup.string().required(),
  minTransactionFee: yup
    .number()
    .positive()
    .required(),
  registrationPrice: yup
    .number()
    .positive()
    .required(),
  welcomeBonusAmount: yup
    .number()
    .positive()
    .required(),
  defaultTransactionFee: yup
    .number()
    .positive()
    .when('defaultTransactionFeeType', {
      is: TransactionFeeType.PERCENT,
      then: yup.number().max(100),
    })
    .required(),
  defaultTransactionFeeType: yup
    .mixed()
    .oneOf([TransactionFeeType.PERCENT, TransactionFeeType.FIX])
    .required(),
})

export const citySchema = yup.object().shape({
  name: yup.string().required(),
  timezone: yup
    .object()
    .shape({
      id: yup
        .number()
        .integer()
        .required(),
      title: yup.string().required(),
    })
    .required(),
})
