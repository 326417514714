import { useQuery } from '@apollo/react-hooks'

import { BUSINESS_API_CREDENTIALS } from '../../queries/external-api'
import { IPaginationInput } from '../../types/common'
import { IBusinessApiCredentialsData, IBusinessApiCredentialsVariables } from '../../types/external-api'

export const useBusinessApiCredentials = (businessId: number, pagination: IPaginationInput) => {
  const { data, ...q } = useQuery<IBusinessApiCredentialsData, IBusinessApiCredentialsVariables>(
    BUSINESS_API_CREDENTIALS,
    {
      variables: { businessId, pagination },
    }
  )

  const credentials = data?.businessApiCredentials.credentials || []
  const total = data?.businessApiCredentials.total || 0

  return {
    credentials,
    total,
    ...q,
  }
}
