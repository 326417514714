import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core'
import { useFormik } from 'formik'
import { ICategory } from '../../../graphql/types/categories'
import { I18nField, LoadableButton } from '../../../components/Form'
import { I18nTitles } from '../../../graphql/types/common'
import { categorySchema } from './validation'
import { useCategoriesApi } from '../../../graphql/hooks/categories'
import { CategoryImageSelector } from './CategoryImageSelector'

interface IProps {
  category: ICategory
  open: boolean
  onClose: VoidFunction
}

interface IFormValues {
  id: number
  titles: I18nTitles
  parentId: number | null
  sortOrder: number
  isUsedForMap: boolean
  icon?: File
}

export const CategoryFormDialog: React.FC<IProps> = ({ category, open, onClose }) => {
  const { upsertCategory } = useCategoriesApi()

  const formik = useFormik<IFormValues>({
    initialValues: {
      id: category.id,
      titles: category.titles ?? [],
      parentId: category.parentId,
      sortOrder: category.sortOrder,
      isUsedForMap: category.isUsedForMap,
    },
    validationSchema: categorySchema,
    async onSubmit(values, formikHelpers) {
      await upsertCategory(
        {
          id: category.id,
          titles: values.titles,
          parentId: values.parentId,
          sortOrder: values.sortOrder,
          isUsedForMap: values.isUsedForMap,
        },
        values.icon
      )
      onClose()
    },
  })

  const { touched, values, errors, isSubmitting, setFieldValue, submitForm } = formik

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>{category.title}</DialogTitle>
      <DialogContent>
        <Box mb={5}>
          <CategoryImageSelector image={category.icon} onSelect={f => setFieldValue('icon', f)} />
        </Box>
        <Box mb={2}>
          <I18nField
            fullWidth
            margin='none'
            size='small'
            label='Название'
            value={values.titles}
            onChange={t => setFieldValue('titles', t)}
            error={Boolean(touched.titles && errors.titles)}
          />
        </Box>
        <Box mb={3}>
          <FormControlLabel
            control={<Checkbox checked={values.isUsedForMap} onChange={(e, ch) => setFieldValue('isUsedForMap', ch)} />}
            label='Показывать на карте "Свободные места"'
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Закрыть</Button>
        <LoadableButton loading={isSubmitting} variant='contained' color='primary' onClick={submitForm}>
          Сохранить
        </LoadableButton>
      </DialogActions>
    </Dialog>
  )
}
