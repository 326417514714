import React, { useEffect, useRef, useState } from 'react'
import { BaseTextFieldProps, Button, Menu, MenuItem, TextField, styled, Typography, Box } from '@material-ui/core'

import { I18nTitles, ILanguage } from '../../graphql/types/common'
import { useCurrentLanguage, useSupportedLanguages } from '../../services/language'

interface IProps extends BaseTextFieldProps {
  value: I18nTitles
  onChange: (value: I18nTitles) => void
}

export const I18nField: React.FC<IProps> = ({ value, onChange, ...props }) => {
  const { currentLanguage: initialLanguage } = useCurrentLanguage()
  const { languages, defaultLanguage } = useSupportedLanguages()
  const inputRef = useRef<HTMLInputElement>(null)
  const [currentLanguage, setCurrentLanguage] = useState<ILanguage>(
    languages.find(l => l.code === initialLanguage) || defaultLanguage
  )
  const currentValue = value.find(v => v.code === currentLanguage.code)

  useEffect(() => {
    inputRef.current?.focus()
  }, [currentLanguage])

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = value.filter(v => v.code !== currentLanguage.code)

    if (target.value.trim().length > 0) {
      newValue.push({ code: currentLanguage.code, label: target.value })
    }

    onChange(newValue)
  }

  return (
    <TextField
      {...props}
      variant='outlined'
      inputRef={inputRef}
      value={currentValue?.label || ''}
      onChange={handleChange}
      disabled={languages.length === 0}
      InputProps={{
        endAdornment: (
          <LanguagesMenu
            options={languages.map(l => ({
              ...l,
              hasValue: !!value.find(v => v.code === l.code)?.label,
            }))}
            current={currentLanguage}
            onChange={setCurrentLanguage}
          />
        ),
      }}
    />
  )
}

interface ILanguageMenuProps {
  options: (ILanguage & { hasValue?: boolean })[]
  current: ILanguage
  onChange: (lang: ILanguage) => void
}

const LanguagesMenu: React.FC<ILanguageMenuProps> = ({ options, current, onChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect = (lang: ILanguage) => () => {
    onChange(lang)
    handleClose()
  }

  return (
    <div>
      <LangButton size='small' onClick={handleClick}>
        {current.code.toUpperCase()}
      </LangButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 120,
          },
        }}
      >
        {options.map(lang => (
          <MenuItem key={`lang-${lang.code}`} disabled={current.code === lang.code} onClick={handleSelect(lang)}>
            <Box display='flex' alignItems='center'>
              <Typography>{lang.label}</Typography>
              {lang.hasValue && <Dot />}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

const Dot = styled('div')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: 4,
  marginLeft: 2,
  backgroundColor: theme.palette.success.main,
}))

const LangButton = styled(Button)(({ theme }) => ({
  paddingLeft: 6,
  paddingRight: 6,
  minWidth: 40,
  backgroundColor: theme.palette.grey[100],
}))
