import React from 'react'
import { Typography } from '@material-ui/core'

import { IPromocode } from '../../../graphql/types/promocodes'
import { PromocodeDiscountType } from '../../../core/interfaces'

interface IProps {
  item: IPromocode
}

export const DiscountColumn: React.FC<IProps> = ({ item }) => {
  // TODO: currency by country
  let suffix = item.discountType === PromocodeDiscountType.PERCENT ? '%' : '₸'

  return <Typography>{`${item.discount} ${suffix}`}</Typography>
}
