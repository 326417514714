import React, { ChangeEvent } from 'react'
import { Box, Typography, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { useQuery } from '@apollo/react-hooks'
import { IAllFranchiseeData, IAllFranchiseeVariables, IFranchisee } from '../../../graphql/types/franchisee'
import { ALL_FRANCHISEE_QUERY } from '../../../graphql/queries/franchisee'

interface IProps {
  value?: Pick<IFranchisee, 'id' | 'name'> | null
  isVisible?: boolean
  isActivated?: boolean
  onSelect: (option: Pick<IFranchisee, 'id' | 'name'> | null) => void
  error?: boolean
}

export const FranchiseeAutocomplete: React.FC<IProps> = ({ value, isVisible, isActivated, onSelect, error }) => {
  const [open, setOpen] = React.useState<boolean>(false)

  const { data, loading } = useQuery<IAllFranchiseeData, IAllFranchiseeVariables>(ALL_FRANCHISEE_QUERY, {
    fetchPolicy: 'cache-and-network',
  })

  const franchisee: Pick<IFranchisee, 'id' | 'name'>[] = data?.allFranchiseeByAdmin?.franchisee || []

  const renderOption = (option: Pick<IFranchisee, 'id' | 'name'> | null) => (
    <Box width='100%' display='flex' flexDirection='row' justifyContent='space-between'>
      <Typography>
        <Typography display='inline' component='span'>
          {option?.name}
        </Typography>
        <Typography display='inline' component='span' color='textSecondary'>
          {' '}
          - {option?.id}
        </Typography>
      </Typography>
    </Box>
  )

  const handleChange = (event: ChangeEvent<{}>, option: Pick<IFranchisee, 'id' | 'name'> | null) => {
    onSelect(option)
  }

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      value={value}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => `${option.name}`}
      renderOption={renderOption}
      options={franchisee}
      loading={loading}
      noOptionsText={'Не найдено'}
      onChange={handleChange}
      fullWidth
      renderInput={params => (
        <TextField
          {...params}
          label='Франчайзи'
          error={error}
          margin='normal'
          fullWidth
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  )
}
