import { ILanguage } from '../../graphql/types/common'

export const useSupportedLanguages = () => {
  const languages: ILanguage[] = [
    { code: 'ru', label: 'Русский' },
    { code: 'en', label: 'English' },
  ]

  const defaultLanguage: ILanguage = {
    code: 'ru',
    label: 'Русский',
  }

  return { languages, defaultLanguage }
}
