import { useQuery } from '@apollo/react-hooks'

import { FRANCHISEE_BALANCE_TRANSACTIONS_QUERY } from '../../queries/franchisee'
import { IFranchiseeBalanceTransactionsData, IFranchiseeBalanceTransactionsVariables } from '../../types/franchisee'

export const useFranchiseeBalanceTransactions = (franchiseeId: number) => {
  const { data, ...query } = useQuery<IFranchiseeBalanceTransactionsData, IFranchiseeBalanceTransactionsVariables>(
    FRANCHISEE_BALANCE_TRANSACTIONS_QUERY,
    {
      variables: { franchiseeId },
    }
  )

  return {
    transactions: data?.franchiseeBalanceTransactionsForAdmin.transactions || [],
    total: data?.franchiseeBalanceTransactionsForAdmin.total || 0,
    ...query,
  }
}
