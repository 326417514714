import { useQuery } from '@apollo/react-hooks'

import { ALL_BUSINESSES_QUERY } from '../../../graphql/queries/businesses'
import { IAllBusinessesData, IAllBusinessesVariables, IBusinessesFilter } from '../../../graphql/types/businesses'
import { useQueryParams } from '../../../utils'

export const ROWS_PER_PAGE = [15, 30, 50]

export const useAllBusinesses = () => {
  const [{ query }] = useQueryParams()
  const filter = filterFromQS(query)
  const hasFilter = Object.keys(filter).filter(f => !!filter[f as keyof IBusinessesFilter]).length > 0
  const page = query.page ? parseInt(query.page) : 1
  const limit = query.limit && ROWS_PER_PAGE.includes(parseInt(query.limit)) ? parseInt(query.limit) : ROWS_PER_PAGE[0]

  const { data, ...q } = useQuery<IAllBusinessesData, IAllBusinessesVariables>(ALL_BUSINESSES_QUERY, {
    variables: {
      filter,
      pagination: { offset: (page - 1) * limit, limit },
    },
  })

  const businesses = data?.allBusinessesWithTotal.businesses || []
  const total = data?.allBusinessesWithTotal.total || 0

  return {
    businesses,
    total,
    page,
    limit,
    hasFilter,
    ...q,
  }
}

const filterFromQS = (query: { [key: string]: any }): IBusinessesFilter => {
  return {
    query: query.q || '',
    isActivated: query.isActivated ? (query.isActivated === 'true' ? true : false) : undefined,
    isVisible: query.isVisible ? (query.isVisible === 'true' ? true : false) : undefined,
    franchiseeId: !query.franchiseeId ? undefined : query.franchiseeId === 'null' ? null : parseInt(query.franchiseeId),
  }
}
