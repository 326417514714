import React from 'react'
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import { Refresh as RefreshIcon } from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'

import { generateRandomString } from '../../../utils/helpers'
import { PromocodeDiscountType } from '../../../core/interfaces'
import { LoadableButton } from '../../../components/Form'
import { promocodeSchema, updatePromocodeSchema } from './validation'
import { DATE_FORMAT } from '../../../core/constants'
import { IPromocode } from '../../../graphql/types/promocodes'

interface IProps {
  loading: boolean
  promocode?: IPromocode | null
  onSubmit: (values: IPromocodeFormValues) => void
  onCancel: () => void
}

export interface IPromocodeFormValues {
  title: string
  description: string
  code?: string | null
  discount: string
  discountType: PromocodeDiscountType
  startDate: string
  endDate: string
}

export const PromocodeForm: React.FC<IProps> = ({ loading, promocode, onSubmit, onCancel }) => {
  const CODE_LENGTH = 5
  const initialValues: IPromocodeFormValues = promocode
    ? {
        title: promocode.title,
        description: promocode.description || '',
        discount: `${promocode.discount}`,
        discountType: promocode.discountType,
        startDate: promocode.startDate,
        endDate: promocode.endDate,
      }
    : {
        title: '',
        description: '',
        code: generateRandomString(CODE_LENGTH).toUpperCase(),
        discount: '',
        discountType: PromocodeDiscountType.PERCENT,
        startDate: moment().toISOString(),
        endDate: moment()
          .add(1, 'week')
          .toISOString(),
      }

  const handleSubmit = (values: IPromocodeFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={!!promocode ? updatePromocodeSchema : promocodeSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, setFieldValue, handleChange, submitForm }: FormikProps<IPromocodeFormValues>) => (
        <div>
          <Box mb={4}>
            <TextField
              label='Название'
              name='title'
              value={values.title}
              onChange={handleChange}
              required
              fullWidth
              margin='normal'
              error={!!errors.title}
            />
            {!!promocode ? (
              <Box fontWeight={500}>{promocode.code}</Box>
            ) : (
              <TextField
                label='Код'
                name='code'
                value={values.code}
                onChange={handleChange}
                required
                fullWidth
                margin='normal'
                error={!!errors.code}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => setFieldValue('code', generateRandomString(CODE_LENGTH).toUpperCase())}
                      >
                        <RefreshIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Box>
          <Box mb={4}>
            <Box mb={1}>
              <Typography variant='subtitle2'>Скидка</Typography>
            </Box>
            <FormControl component='fieldset'>
              <RadioGroup name='discountType' value={values.discountType} onChange={handleChange}>
                <FormControlLabel value={PromocodeDiscountType.PERCENT} control={<Radio />} label={'Процент (%)'} />
                <FormControlLabel value={PromocodeDiscountType.FIX} control={<Radio />} label={'Фиксировано (₸)'} />
              </RadioGroup>
            </FormControl>
            <TextField
              label='Размер скидки'
              name='discount'
              value={values.discount}
              onChange={handleChange}
              required
              fullWidth
              margin='normal'
              error={!!errors.discount}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {values.discountType === PromocodeDiscountType.FIX ? '₸' : '%'}
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={4}>
            <Box mb={1}>
              <Typography variant='subtitle2'>Период</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  variant='inline'
                  label='Дата начала'
                  value={values.startDate}
                  onChange={date => setFieldValue('startDate', date?.toISOString())}
                  autoOk
                  format={DATE_FORMAT}
                  fullWidth
                  error={!!errors.startDate}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  variant='inline'
                  label='Дата окончания'
                  value={values.endDate}
                  onChange={date => setFieldValue('endDate', date?.toISOString())}
                  autoOk
                  format={DATE_FORMAT}
                  fullWidth
                  error={!!errors.endDate}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={4}>
            <TextField
              label='Описание'
              error={!!errors.description}
              margin='normal'
              name={'description'}
              variant={'outlined'}
              multiline
              rows={2}
              rowsMax={4}
              fullWidth
              value={values.description}
              onChange={handleChange}
            />
          </Box>
          <Box pt={4} pb={1} display='flex' flexDirection='row' justifyContent='space-between'>
            <Button onClick={onCancel}>Отмена</Button>
            <LoadableButton loading={loading} color='primary' variant='contained' onClick={submitForm}>
              Сохранить
            </LoadableButton>
          </Box>
        </div>
      )}
    </Formik>
  )
}
