import React, { useState } from 'react'
import isDeepEqual from 'fast-deep-equal/react'

import { Box, IconButton, Button, Avatar } from '@material-ui/core'
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, ImageOutlined as EmptyIcon } from '@material-ui/icons'
import SortableTree, {
  TreeItem,
  ExtendedNodeData,
  NodeData,
  FullTree,
  OnMovePreviousAndNextLocation,
  toggleExpandedForAll,
  removeNodeAtPath,
  TreeNode,
  TreeIndex,
  getTreeFromFlatData,
  changeNodeAtPath,
  addNodeUnderParent,
} from 'react-sortable-tree'
import 'react-sortable-tree/style.css'

import { categorySortOrdersFromTree, sortTreeItems } from '../../../utils/helpers'
import { useCategoriesApi } from '../../../graphql/hooks/categories'
import { useConfirm, Loader, CreateFab } from '../../../components/UI'
import { EmptyDataMessage } from '../../../components/Data'
import { CategoryEditableTitle } from './CategoryEditableTitle'
import { I18nTitles } from '../../../graphql/types/common'
import { LanguageSwitcher } from '../../../services/language'
import { ICategory } from '../../../graphql/types/categories'
import { omit } from 'lodash'
import { CategoryFormDialog } from './CategoryFormDialog'

interface IProps {}

const DEFAULT_TITLES: I18nTitles = [{ code: 'ru', label: 'Новая категория' }]

export const CategoriesTree: React.FC<IProps> = () => {
  const confirm = useConfirm()
  const { categories, loading, upsertCategory, deleteCategory, moveCategory } = useCategoriesApi()
  const ref = React.useRef(categories)

  const [treeData, setTreeData] = useState<TreeItem[]>([])
  const [expanded, setExpanded] = useState<boolean>(false)
  const [editingNode, setEditingNode] = useState<ICategory>()

  if (!isDeepEqual(ref.current, categories)) {
    ref.current = categories
  }

  React.useEffect(() => {
    const tree = getTreeFromFlatData({
      flatData: categories,
      getKey: node => node.id,
      getParentKey: node => node.parentId,
      rootKey: 'null',
    })

    setTreeData(tree)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  const getNodeKey = ({ treeIndex }: TreeIndex & TreeNode) => treeIndex

  const handleAddNode = async () => {
    const category = await upsertCategory({
      titles: DEFAULT_TITLES,
      parentId: null,
      sortOrder: treeData.length,
    })

    if (category) {
      setTreeData([...treeData, category])
    }
  }

  const handleAddChild = async ({ node, path }: ExtendedNodeData) => {
    const parentKey = path[path.length - 1]

    const category = await upsertCategory({
      titles: DEFAULT_TITLES,
      parentId: node.id,
      sortOrder: node.children ? node.children.length : 0,
    })

    if (category) {
      setTreeData(
        addNodeUnderParent({
          treeData,
          parentKey,
          expandParent: true,
          getNodeKey,
          newNode: { ...category, id: category.id },
        }).treeData
      )
    }
  }

  const handleChangeNode = async (titles: I18nTitles, { node, path }: ExtendedNodeData) => {
    const category = await upsertCategory({
      id: node.id,
      titles,
      parentId: node.parentId,
      sortOrder: node.sortOrder,
    })

    if (category) {
      setTreeData(changeNodeAtPath({ treeData, path, getNodeKey, newNode: { ...node, titles } }))
    }
  }

  const handleMoveNode = async ({
    nextParentNode,
    node,
    treeData: TD,
  }: NodeData & FullTree & OnMovePreviousAndNextLocation) => {
    const parentId = nextParentNode?.id || null

    await moveCategory(node.id, parentId, categorySortOrdersFromTree(TD))
    setTreeData(sortTreeItems(TD))
  }

  const handleRemoveNode = async ({ node, path }: ExtendedNodeData) => {
    try {
      await confirm({ message: 'Вы действительно хотите удалить?' })
    } catch {
      return
    }

    await deleteCategory(node.id)
    setTreeData(removeNodeAtPath({ treeData, path, getNodeKey }))
  }

  const toggleAll = () => {
    setTreeData(toggleExpandedForAll({ treeData, expanded: !expanded }))
    setExpanded(!expanded)
  }

  const handleOpenEdit = ({ node, path }: ExtendedNodeData) => {
    setEditingNode(omit(node, 'children') as ICategory)
  }

  const handleCloseEdit = () => {
    setEditingNode(undefined)
  }

  const generateNodeProps = (end: ExtendedNodeData) => {
    let buttons = [
      <IconButton size='small' onClick={() => handleOpenEdit(end)}>
        <EditIcon />
      </IconButton>,
      <IconButton size='small' onClick={() => handleRemoveNode(end)}>
        <DeleteIcon />
      </IconButton>,
    ]

    if (end.node.parentId === null) {
      buttons = [
        <IconButton size='small' onClick={() => handleAddChild(end)}>
          <AddIcon />
        </IconButton>,
        ...buttons,
      ]
    }

    return {
      buttons,
      title: (
        <Box display='flex' alignItems='center'>
          <Avatar src={end.node.icon?.url} style={{ marginRight: 6 }}>
            <EmptyIcon />
          </Avatar>
          <CategoryEditableTitle
            titles={end.node.titles || []}
            title={end.node.title?.toString() || ''}
            onSave={t => handleChangeNode(t, end)}
          />
        </Box>
      ),
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      <Box mb={4}>
        {treeData.length > 0 && !loading && (
          <Box display='flex' alignItems='center'>
            <Button variant='outlined' color='primary' size='small' onClick={toggleAll}>
              Раскрыть/Свернуть все
            </Button>
            <Box ml={2}>
              <LanguageSwitcher />
            </Box>
          </Box>
        )}
      </Box>
      {treeData.length > 0 && !loading ? (
        <Box height={800}>
          <SortableTree
            treeData={treeData}
            onChange={d => setTreeData(d)}
            onMoveNode={handleMoveNode}
            generateNodeProps={generateNodeProps}
            maxDepth={2}
            isVirtualized={false}
            rowHeight={84}
          />
        </Box>
      ) : (
        <EmptyDataMessage />
      )}
      <CreateFab onClick={handleAddNode} />
      {!!editingNode && <CategoryFormDialog category={editingNode} open onClose={handleCloseEdit} />}
    </div>
  )
}
