import { IRouteItem } from '../core/interfaces'

import Home from '../views/Home/Home'
import Logout from '../views/Auth/Logout'
import Businesses from '../views/Businesses/Businesses'
import BusinessDetail from '../views/Businesses/BusinessDetail'
import BusinessEdit from '../views/Businesses/BusinessEdit'
import BusinessBalanceTransactions from '../views/Businesses/BusinessBalanceTransactions'
import BusinessIntegration from '../views/Businesses/BusinessIntegration'
import SalesSupervisors from '../views/Sales/Supervisors/Supervisors'
import SalesManagers from '../views/Sales/Managers/Managers'
import Promocodes from '../views/Promocodes/Promocodes'
import Profile from '../views/Profile/Profile'
import SalesManager from '../views/Sales/Managers/Manager'
import Faqs from '../views/Faqs/Faqs'
import FaqGroup from '../views/Faqs/FaqGroup'
import Countries from '../views/Localization/Countries/Countries'
import Country from '../views/Localization/Countries/Country'
import Categories from '../views/Categories/Categories'
import Transactions from '../views/Statistics/Transactions/Transactions'
import BusinessSummaryReport from '../views/Statistics/BusinessSummary/BusinessSummaryReport'
import BusinessTransactionFeesByDaysReport from '../views/Statistics/BusinessTransactionFeesByDay/BusinessTransactionFeesByDay'
import Franchisee from '../views/Franchisee/Franchisee'
import FranchiseeDetail from '../views/Franchisee/FranchiseeDetail'
import FranchiseeBalance from '../views/Franchisee/FranchiseeBalance'
import BusinessTransactionFeesByDaysReportOld from '../views/Statistics/BusinessTransactionFeesByDayOld/BusinessTransactionFeesByDay'
import AutoLoginReport from '../views/Statistics/AutoLoginReport/AutoLoginReport'
import { TaskRunner } from '../views/Settings/TaskRunner/TaskRunner'

const items: IRouteItem[] = [
  {
    name: 'home',
    path: '/',
    component: Home,
  },
  {
    name: 'logout',
    path: '/logout',
    component: Logout,
  },
  {
    name: 'businesses',
    path: '/businesses',
    component: Businesses,
  },
  {
    name: 'business',
    path: '/businesses/:id',
    component: BusinessDetail,
  },
  {
    name: 'business-edit',
    path: '/businesses/:id/edit',
    component: BusinessEdit,
  },
  {
    name: 'business-balance-transactions',
    path: '/businesses/:id/balance',
    component: BusinessBalanceTransactions,
  },
  {
    name: 'business.integration',
    path: '/businesses/:id/integration',
    component: BusinessIntegration,
  },
  {
    name: 'sales.supervisors',
    path: '/sales/supervisors',
    component: SalesSupervisors,
  },
  {
    name: 'sales.managers',
    path: '/sales/managers',
    component: SalesManagers,
  },
  {
    name: 'sales.managers',
    path: '/sales/managers/:id',
    component: SalesManager,
  },
  {
    name: 'promocodes',
    path: '/promocodes',
    component: Promocodes,
  },
  {
    name: 'profile',
    path: '/profile',
    component: Profile,
  },
  {
    name: 'faq',
    path: '/faq/:applicationType?',
    component: Faqs,
  },
  {
    name: 'faq',
    path: '/faq/:applicationType/:id',
    component: FaqGroup,
  },
  {
    name: 'localization.countries',
    path: '/localization/countries',
    component: Countries,
  },
  {
    name: 'localization.country',
    path: '/localization/countries/:id',
    component: Country,
  },
  {
    name: 'categories',
    path: '/categories',
    component: Categories,
  },
  {
    name: 'statistics.transactions',
    path: '/statistics/transactions',
    component: Transactions,
  },
  {
    name: 'statistics.summary',
    path: '/statistics/summary',
    component: BusinessSummaryReport,
  },
  {
    name: 'statistics.transaction-fee',
    path: '/statistics/transaction-fee',
    component: BusinessTransactionFeesByDaysReport,
  },
  {
    name: 'statistics.transaction-fee-old',
    path: '/statistics/transaction-fee-old',
    component: BusinessTransactionFeesByDaysReportOld,
  },
  {
    name: 'statistics.auto-login',
    path: '/statistics/auto-login',
    component: AutoLoginReport,
  },
  {
    name: 'franchisee',
    path: '/franchisee',
    component: Franchisee,
  },
  {
    name: 'franchisee.detail',
    path: '/franchisee/:id',
    component: FranchiseeDetail,
  },
  {
    name: 'franchisee.balance',
    path: '/franchisee/:id/balance',
    component: FranchiseeBalance,
  },
  {
    name: 'settings.task-runner',
    path: '/settings/task-runner',
    component: TaskRunner,
  },
]

export default items
