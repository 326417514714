import { Box, fade, makeStyles, Theme, Tooltip } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'
import React from 'react'
import { useGetBalance } from '../../graphql/hooks/reports/useGetBalance'

const useStyles = makeStyles<
  Theme,
  {
    balance: number
  }
>(theme => ({
  root: ({ balance }) => ({
    fontSize: 10,
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: 5,
    border: `1px solid ${
      balance >= 35000
        ? blueGrey[200]
        : balance < 35000 && balance > 30000
        ? theme.palette.warning.main
        : theme.palette.error.main
    }`,
  }),
  label: ({ balance }) => ({
    padding: theme.spacing(0.4, 1),
    backgroundColor: fade(
      balance >= 35000
        ? blueGrey[200]
        : balance < 35000 && balance > 30000
        ? theme.palette.warning.main
        : theme.palette.error.main,
      0.4
    ),
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }),
  value: {
    fontWeight: 600,
    padding: theme.spacing(0.4, 1),
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  danger: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  warning: {
    color: theme.palette.warning.main,
    borderColor: theme.palette.warning.main,
  },
}))

export const SmsBalanceWidget: React.FC<{}> = () => {
  const { balance } = useGetBalance()
  const classes = useStyles({ balance })

  return (
    <Box className={classes.root}>
      <Box className={classes.label}>смс</Box>
      <Tooltip
        title={`Остаток баланса смс-центра, ${balance.toLocaleString()}т`}
        aria-label={`Остаток баланса смс-центра, ${balance.toLocaleString()}т`}
      >
        <Box className={classes.value}>{balance.toLocaleString()}т</Box>
      </Tooltip>
    </Box>
  )
}
