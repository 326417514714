import React from 'react'
import { Box, Typography } from '@material-ui/core'

import { logo } from '../../core/images'

const Home: React.FC = () => {
  return (
    <Box mb={3} display='flex' alignItems='center' justifyContent='center' height='calc(100% - 80px)'>
      <Box textAlign='center'>
        <Box mb={1}>
          <img src={logo} width={70} alt='' />
        </Box>
        <Typography variant='h4' style={{ fontWeight: 300 }}>
          PAYDA ADMIN
        </Typography>
      </Box>
    </Box>
  )
}

export default Home
