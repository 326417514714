import { useMutation } from '@apollo/react-hooks'

import { DELETE_COUNTRY_MUTATION, ALL_COUNTRIES_QUERY } from '../../queries/localization'
import { IDeleteCountryData, IDeleteCountryVariables } from '../../types/localization'

export const useDeleteCountry = () => {
  const [deleteCountryMutation, { loading, error }] = useMutation<IDeleteCountryData, IDeleteCountryVariables>(
    DELETE_COUNTRY_MUTATION
  )

  const deleteCountry = async (id: number) => {
    try {
      await deleteCountryMutation({
        variables: { id },
        refetchQueries: [{ query: ALL_COUNTRIES_QUERY }],
      })
    } catch (error) {
      console.log(error)
    }
  }

  return {
    loading,
    error,
    deleteCountry,
  }
}
