import React from 'react'
import { Box, IconButton, Typography } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'
import moment from 'moment'

import { DATE_TIME_FORMAT } from '../../core/constants'
import { IPromocode } from '../../graphql/types/promocodes'
import { useAllPromocodes } from '../../graphql/hooks/promocodes'
import { PageHeader, Content } from '../../components/Layout'
import { EmptyDataMessage, DataTable, IDataTableColumn } from '../../components/Data'
import { CreateFab, Loader } from '../../components/UI'
import { AddPromocodeModal } from './components/AddPromocodeModal'
import { EditPromocodeModal } from './components/EditPromocodeModal'
import { usePromocodesFilter } from './usePromocodesFilter'
import { DiscountColumn, TitleColumn } from './columns'

const Promocodes: React.FC = () => {
  const [addModalVisible, setAddModalVisible] = React.useState<boolean>(false)
  const [editPromocode, setEditPromocode] = React.useState<IPromocode | null>(null)
  const { renderFilter, filter } = usePromocodesFilter()
  const { promocodes, loading } = useAllPromocodes(filter)

  const columns: IDataTableColumn<IPromocode>[] = [
    { label: 'ID', accessor: 'id', style: { width: 70 } },
    { label: 'Название', accessor: item => <TitleColumn item={item} /> },
    {
      label: 'Код',
      accessor: item => (
        <Typography color={item.deactivated ? 'textSecondary' : 'textPrimary'}>{`${item.code}`}</Typography>
      ),
    },
    { label: 'Скидка', accessor: item => <DiscountColumn item={item} /> },
    { label: 'Франчайзи', accessor: item => item.franchisee?.name || '' },
    { label: 'Дата создания', accessor: item => moment(item.createdAt).format(DATE_TIME_FORMAT) },
    {
      label: '',
      accessor: item =>
        item.deactivated ? null : (
          <IconButton size='small' onClick={handleEdit(item)}>
            <EditIcon />
          </IconButton>
        ),
    },
  ]

  const toggleModal = () => {
    setAddModalVisible(!addModalVisible)
  }

  const resetEditPromocode = () => {
    setEditPromocode(null)
  }

  const handleEdit = (item: IPromocode) => () => {
    setEditPromocode(item)
  }

  return (
    <div>
      <PageHeader title='Промокоды' right={renderFilter()} />
      <Box>
        {loading && <Loader />}
        {promocodes.length === 0 && !loading ? (
          <EmptyDataMessage />
        ) : (
          <Content noGutter>
            <DataTable data={promocodes} columns={columns} />
          </Content>
        )}
      </Box>
      <CreateFab onClick={toggleModal} />
      {addModalVisible && <AddPromocodeModal open={addModalVisible} onCancel={toggleModal} />}
      {editPromocode && (
        <EditPromocodeModal open={!!editPromocode} promocode={editPromocode} onCancel={resetEditPromocode} />
      )}
    </div>
  )
}

export default Promocodes
