import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Grid, Box } from '@material-ui/core'
import {
  SwapVert as TransactionsIcon,
  People as CustomersIcon,
  AccountBalanceWallet as BalanceIcon,
  LocalAtm as ComissionIcon,
  Edit as EditIcon,
} from '@material-ui/icons'

import { useBusinessDetailWithStatsQuery } from '../../graphql/hooks/businesses'
import { PageHeader, Content } from '../../components/Layout'
import { Loader } from '../../components/UI'
import { SingleValueWidget } from '../../components/Widgets'
import PageNotFound from '../PageNotFound'
import { ImportClients } from './components'
import {
  BusinessInfo,
  TransactionFeeModal,
  BusinessVisibility,
  BusinessFranchisee,
  BusinessSmsSwitcher,
} from './components'
import { TransactionFeeType } from '../../core/interfaces'
import { plural } from '../../utils'

interface IProps extends RouteComponentProps<{ id: string }> {}

const BusinessDetail: React.FC<IProps> = ({ history, match }) => {
  const [transactionFeeModalVisble, setTransactionFeeModalVisible] = React.useState<boolean>(false)
  const { business, stats, loading } = useBusinessDetailWithStatsQuery(parseInt(match.params.id, 10))

  if (loading) {
    return <Loader />
  }

  if (!(business && stats)) {
    return <PageNotFound />
  }

  const toggleTransactionFeeModal = () => {
    setTransactionFeeModalVisible(!transactionFeeModalVisble)
  }

  const navigateToBalance = () => {
    history.push(`/businesses/${business.id}/balance`)
  }

  const transactionFeeValue = () => {
    const suffix = business.transactionFeeType === TransactionFeeType.FIX ? ` ${business.country.currencySymbol}` : '%'

    return `${business.transactionFee}${suffix}`
  }

  const minTransactionFeeValue = () => {
    return business.minTransactionFee
      ? `Мин ${business.minTransactionFee}${business.country.currencySymbol}`
      : undefined
  }

  return (
    <div>
      <PageHeader title={business.companyName} back />
      <Box mb={3} display='flex' alignItems='center' justifyContent='space-between'>
        <Box>
          <BusinessVisibility business={business} />
          <BusinessSmsSwitcher business={business} />
        </Box>
        <BusinessFranchisee business={business} />
      </Box>
      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <SingleValueWidget label='Транзакции' value={`${stats.transactionsCount}`} Icon={TransactionsIcon} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SingleValueWidget label='Клиенты' value={`${stats.contractsCount}`} Icon={CustomersIcon} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SingleValueWidget
              label='Баланс'
              value={`${business.balance} ${business.country.currencySymbol}`}
              Icon={BalanceIcon}
              onClick={navigateToBalance}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SingleValueWidget
              label='Комиссия'
              value={transactionFeeValue()}
              valueDesc={minTransactionFeeValue()}
              Icon={ComissionIcon}
              onClick={toggleTransactionFeeModal}
              OnClickIcon={EditIcon}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SingleValueWidget label='Количество сотрудников' value={`${(business.getEmployees ?? []).length}`} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SingleValueWidget label='Процент бонусов по умолчанию' value={`${business.cashbackPercent}%`} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SingleValueWidget
              label='Срок активации'
              value={`${business.bonusActivationPeriod} ${plural(
                ['день', 'дня', 'дней'],
                business.bonusActivationPeriod
              )}`}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SingleValueWidget
              label='Срок сгорания'
              value={`${business.bonusExpiryPeriod} ${plural(['день', 'дня', 'дней'], business.bonusExpiryPeriod)}`}
            />
          </Grid>
        </Grid>
      </Box>
      <Content>
        <BusinessInfo business={business} />
      </Content>
      <TransactionFeeModal open={transactionFeeModalVisble} business={business} onClose={toggleTransactionFeeModal} />

      <Box py={3}>
        <ImportClients business={business} />
      </Box>
    </div>
  )
}

export default BusinessDetail
