import React from 'react'
import { Box } from '@material-ui/core'
import { RouteComponentProps } from 'react-router-dom'

import { PageHeader } from '../../components/Layout'
import { CategoriesTree } from './components/CategoriesTree'

const Categories: React.FC<RouteComponentProps> = ({ history }) => {
  return (
    <div>
      <PageHeader title='Категории' />
      <Box>
        <CategoriesTree />
      </Box>
    </div>
  )
}

export default Categories
