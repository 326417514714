import React, { useState } from 'react'
import { Button, Typography, Box } from '@material-ui/core'

import { IBusiness } from '../../../graphql/types/businesses'
import { BusinessFranchiseeModal } from './BusinessFranchiseeModal'

interface IProps {
  business: IBusiness
}

export const BusinessFranchisee: React.FC<IProps> = ({ business }) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  const toggleModal = () => {
    setModalVisible(prev => !prev)
  }

  return (
    <div>
      <Box>
        <Typography variant='caption' color='textSecondary'>
          Франчайзи
        </Typography>
      </Box>
      <Button variant='outlined' onClick={toggleModal}>
        {!!business.franchisee ? business.franchisee.name : 'Без франчайзи'}
      </Button>
      {modalVisible && <BusinessFranchiseeModal business={business} onClose={toggleModal} />}
    </div>
  )
}
