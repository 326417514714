import gql from 'graphql-tag'

export const GET_TASK_RUNNERS_QUERY = gql`
  query GetTaskRunners {
    getTaskRunners {
      serverInstanceId
      description
      isActive
    }
  }
`

export const TOGGLE_TASK_RUNNER = gql`
  mutation ToggleTaskRunner($instanceId: String!) {
    toggleTaskRunner(serverInstanceId: $instanceId) {
      serverInstanceId
      description
      isActive
    }
  }
`
