import React from 'react'
import moment from 'moment'

import { DATE_FORMAT, DATE_TIME_FORMAT } from '../../../core/constants'
import { IDataTableColumn } from '../../../components/Data'
import { IBusinessApiCredential } from '../../../graphql/types/external-api'
import { IsActiveColumn } from './IsActiveColumn'

export const columns: IDataTableColumn<IBusinessApiCredential>[] = [
  { label: 'ID', accessor: 'id', style: { width: 70 } },
  { label: 'Название', accessor: 'title' },
  { label: 'Активно', accessor: item => <IsActiveColumn item={item} />, align: 'center' },
  { label: 'Срок действия', accessor: item => moment(item.expiresAt).format(DATE_FORMAT) },
  { label: 'Создал', accessor: item => item.createdBy.fullName },
  { label: 'Дата создания', accessor: item => moment(item.createdAt).format(DATE_TIME_FORMAT) },
]
