import React from 'react'
import { Redirect } from 'react-router-dom'
import { inject } from 'mobx-react'

import { AuthStore } from '../../stores/AuthStore'

interface IProps {
  authStore?: AuthStore
}

const Logout: React.FC<IProps> = ({ authStore }) => {
  React.useEffect(() => {
    authStore && authStore.logout()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Redirect to='/login' />
}

export default inject('authStore')(Logout)
