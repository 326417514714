import gql from 'graphql-tag'

export const All_BUSINESS_SUMMARY_REPORT_QUERY = gql`
  query AllBusinessSummaryReport($input: AllBusinessSummaryReportInput!, $pagination: PaginationInput!) {
    allBusinessSummaryReport(input: $input, pagination: $pagination) {
      reportItems {
        businessId
        companyName
        franchiseeId
        franchiseeName
        balance
        lastTransactionAt
        avgAmount
        avgCountPerDay
        avgTransactionFeePerMonth
        planingEndBalanceInDays
      }
      total
    }
  }
`

export const ALL_BUSINESS_TRANSACTION_FEES_BY_DAYS = gql`
  query AllBusinessTransactionFeesByDays(
    $input: AllBusinessTransactionFeesByDaysInput!
    $pagination: PaginationInput!
  ) {
    allBusinessTransactionFeesByDays(input: $input, pagination: $pagination) {
      data {
        businessId
        companyName
        data {
          rangeName
          amount
          count
          amountPrev
          countPrev
        }
      }
      summary {
        rangeName
        amount
        count
        amountPrev
        countPrev
      }
      total
    }
  }
`

export const BUSINESS_TRANSACTION_FEES_BY_DAYS = gql`
  query BusinessTransactionFeesByDays($input: AllBusinessTransactionFeesByDaysInput!, $pagination: PaginationInput!) {
    businessTransactionFeesByDays(input: $input, pagination: $pagination) {
      data {
        businessId
        companyName
        previousWeek {
          amount
          count
          amountPrev
          countPrev
        }
        currentWeek {
          amount
          count
          amountPrev
          countPrev
        }
        sevenDaysAgo {
          amount
          count
          amountPrev
          countPrev
        }
        sixDaysAgo {
          amount
          count
          amountPrev
          countPrev
        }
        fiveDaysAgo {
          amount
          count
          amountPrev
          countPrev
        }
        fourDaysAgo {
          amount
          count
          amountPrev
          countPrev
        }
        threeDaysAgo {
          amount
          count
          amountPrev
          countPrev
        }
        twoDayAgo {
          amount
          count
          amountPrev
          countPrev
        }
        oneDayAgo {
          amount
          count
          amountPrev
          countPrev
        }
        today {
          amount
          count
          amountPrev
          countPrev
        }
      }
      summary {
        previousWeek {
          amount
          count
          amountPrev
          countPrev
        }
        currentWeek {
          amount
          count
          amountPrev
          countPrev
        }
        sevenDaysAgo {
          amount
          count
          amountPrev
          countPrev
        }
        sixDaysAgo {
          amount
          count
          amountPrev
          countPrev
        }
        fiveDaysAgo {
          amount
          count
          amountPrev
          countPrev
        }
        fourDaysAgo {
          amount
          count
          amountPrev
          countPrev
        }
        threeDaysAgo {
          amount
          count
          amountPrev
          countPrev
        }
        twoDayAgo {
          amount
          count
          amountPrev
          countPrev
        }
        oneDayAgo {
          amount
          count
          amountPrev
          countPrev
        }
        today {
          amount
          count
          amountPrev
          countPrev
        }
      }
      total
    }
  }
`

export const GET_SMS_BALANCE = gql`
  query {
    getSmsBalance
  }
`

export const REPORT_CONTRACTS_SUMMARY_XLSX = gql`
  mutation ReportContractsSummaryXLSX($filter: ReportContractsSummaryFilterInput, $timeZoneOffset: Int) {
    reportContractsSummaryXLSX(filter: $filter, responseType: url, timeZoneOffset: $timeZoneOffset)
  }
`

export const GET_AUTO_LOGIN_REPORT = gql`
  query GetAutoLoginReport($input: AutoLoginReportInput!, $pagination: PaginationInput!) {
    getAutoLoginReport(input: $input, pagination: $pagination) {
      data {
        user {
          id
          phone
        }
        isActivated
        isApplicationUser
        expireAt
        createdAt
        updatedAt
      }
      total
      countOfNotActivated
      countOfActivated
      countOfNotActivatedByPeriod
      countOfActivatedByPeriod
      countOfApplicationUsers
      countOfApplicationUsersByPeriod
    }
  }
`
