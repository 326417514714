import { useQuery } from '@apollo/react-hooks'
import { IPromocodeFilter } from '../../../views/Promocodes/usePromocodesFilter'

import { ALL_PROMOCODES_QUERY } from '../../queries/promocodes'
import { IAllPromocodesData, IAllPromocodesVariables } from '../../types/promocodes'

export const useAllPromocodes = (filter: IPromocodeFilter) => {
  const { data, loading } = useQuery<IAllPromocodesData, IAllPromocodesVariables>(ALL_PROMOCODES_QUERY, {
    variables: { filter: { franchiseeId: filter?.franchisee?.id } },
  })

  // TODO: pagination

  return {
    promocodes: data?.allPromocodes || [],
    loading,
  }
}
