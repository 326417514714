import React from 'react'
import { CreateFab } from '../../../components/UI'
import { Dialog, DialogTitle, DialogContent, Box, ButtonBaseProps } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { IFaqGroupFormValues, FaqGroupForm } from './FaqGroupForm'
import { useMutation } from '@apollo/react-hooks'
import { UPSERT_FAQ_GROUP_MUTATION, ALL_FAQ_GROUP_QUERY } from '../../../graphql/queries/faq'
import {
  IUpsertFaqGroupData,
  IUpsertFaqGroupVariables,
  IFaqGroup,
  IUpsertFaqGroupInput,
} from '../../../graphql/types/faq'
import { ApplicationType } from '../../../core/interfaces'

interface IProps extends ButtonBaseProps {
  item?: IFaqGroup
  component?: React.ElementType
}

const FaqGroupEditorModalButton: React.FC<IProps> = ({ item, component: ButtonComponent, ...props }) => {
  const [open, setOpen] = React.useState(false)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [upsertFaqGroup, { loading }] = useMutation<IUpsertFaqGroupData, IUpsertFaqGroupVariables>(
    UPSERT_FAQ_GROUP_MUTATION
  )

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleSubmit = async (values: IFaqGroupFormValues) => {
    const input: IUpsertFaqGroupInput = {
      id: item?.id,
      titles: values.titles,
      applicationType: values.applicationType,
    }

    try {
      await upsertFaqGroup({
        variables: { input },
        refetchQueries: [
          { query: ALL_FAQ_GROUP_QUERY, variables: { applicationType: ApplicationType.BUSINESS } },
          { query: ALL_FAQ_GROUP_QUERY, variables: { applicationType: ApplicationType.CLIENT } },
        ],
      })
      handleToggle()
    } catch (err) {
      setError((err as Error).message)
    }
  }

  return (
    <>
      {ButtonComponent ? <ButtonComponent {...props} onClick={handleToggle} /> : <CreateFab onClick={handleToggle} />}
      <Dialog open={open} maxWidth='sm' fullWidth>
        <DialogTitle id='form-dialog-title'>{item?.id ? 'Изменить рубрику FAQ' : 'Новая рубрика FAQ'}</DialogTitle>
        <DialogContent>
          {!!error && (
            <Box mb={3}>
              <Alert severity='error'>{error}</Alert>
            </Box>
          )}
          <Box>
            <FaqGroupForm
              item={item}
              loading={loading}
              submitLabel={item?.id ? 'Сохранить' : 'Создать'}
              onSubmit={handleSubmit}
              onCancel={handleToggle}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default FaqGroupEditorModalButton
