import React from 'react'
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Box,
  TextField,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import { Formik, FormikProps } from 'formik'
import { useSnackbar } from 'notistack'
import * as yup from 'yup'

import { ROWS_PER_PAGE } from '../../../core/constants'
import { IBusiness, IDeleteBusinessData, IDeleteBusinessVariables } from '../../../graphql/types/businesses'
import { LoadableButton } from '../../../components/Form'
import { ALL_BUSINESSES_QUERY, DELETE_BUSINESS_MUTATION } from '../../../graphql/queries/businesses'

interface IProps {
  business: IBusiness
  open: boolean
  onClose: () => void
}

interface IDeleteBusinessValues {
  comment: string
}

const validationSchema = yup.object().shape({
  comment: yup
    .string()
    .max(255)
    .required(),
})

export const DeleteBusinessModal: React.FC<IProps> = ({ business, open, onClose }) => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [deleteBusiness, { loading }] = useMutation<IDeleteBusinessData, IDeleteBusinessVariables>(
    DELETE_BUSINESS_MUTATION
  )
  const initialValues: IDeleteBusinessValues = {
    comment: '',
  }

  const handleSubmit = async (values: IDeleteBusinessValues) => {
    try {
      await deleteBusiness({
        variables: { businessId: business.id, comment: values.comment },
        refetchQueries: [
          {
            query: ALL_BUSINESSES_QUERY,
            variables: { pagination: { offset: 0, limit: ROWS_PER_PAGE[0] }, filter: { query: '' } },
          },
        ],
      })

      enqueueSnackbar('Бизнес успешно удален!', { variant: 'success' })
      history.push('/businesses')
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, errors, handleChange, submitForm }: FormikProps<IDeleteBusinessValues>) => (
        <Dialog open={open} maxWidth='sm' fullWidth aria-labelledby='form-dialog-title'>
          <DialogTitle id='form-dialog-title'>Удаление бизнеса "{business.companyName}"</DialogTitle>
          <DialogContent>
            <Box mb={2}>
              <Alert severity='error'>
                Будут удалены все транзакции, список Клиентов и все траназакции по Балансу. После удаления данные нельзя
                восстановить.
              </Alert>
            </Box>
            <Typography>Вы действительно хотите удалить?</Typography>
            <Box>
              <TextField
                placeholder='Причина удаления'
                label='Причина удаления'
                value={values.comment}
                onChange={handleChange('comment')}
                multiline
                error={!!errors.comment}
                margin='normal'
                fullWidth
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color='default'>
              Закрыть
            </Button>
            <LoadableButton loading={loading} variant='contained' color='primary' onClick={submitForm}>
              Удалить
            </LoadableButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}
