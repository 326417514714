import React from 'react'
import { Box, Link, Popover, Typography } from '@material-ui/core'

import { IBusinessTransaction } from '../../../graphql/types/businesses'
import { BusinessTransactionStatus } from '../../../core/interfaces'
import { STATUS_OPTIONS } from '../../../core/constants'

interface IProps {
  item: IBusinessTransaction
}

export const BalanceTransactionStatus: React.FC<IProps> = ({ item }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const status = STATUS_OPTIONS[item.status]

  const labelRender = <Typography color='inherit'>{status.label}</Typography>
  return (
    <Box color={status.color}>
      {!!item.psPaymentInfo?.message ? (
        <>
          {
            <Link href='#' onClick={handleClick}>
              {labelRender}
            </Link>
          }
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography>{item.psPaymentInfo?.message}</Typography>
          </Popover>
        </>
      ) : (
        labelRender
      )}

      {!!item.error && (
        <Typography
          color={item.status === BusinessTransactionStatus.INCOMPLETE ? 'inherit' : 'error'}
          variant='caption'
        >
          {!!item.errorCode && `[${item.errorCode}] `} {item.error}
        </Typography>
      )}
      {item.psPaymentInfo?.message}
    </Box>
  )
}
