import { useMutation } from '@apollo/react-hooks'

import { ROWS_PER_PAGE } from '../../../core/constants'
import { BUSINESS_API_CREDENTIALS, DELETE_BUSINESS_API_CREDENTIAL } from '../../queries/external-api'
import { IDeleteBusinessApiCredentialData, IDeleteBusinessApiCredentialVariables } from '../../types/external-api'

export const useDeleteBusinessApiCredential = (businessId: number) => {
  return useMutation<IDeleteBusinessApiCredentialData, IDeleteBusinessApiCredentialVariables>(
    DELETE_BUSINESS_API_CREDENTIAL,
    {
      refetchQueries: [
        {
          query: BUSINESS_API_CREDENTIALS,
          variables: { businessId, pagination: { offset: 0, limit: ROWS_PER_PAGE[0] } },
        },
      ],
    }
  )
}
