import React from 'react'
import { Box, Divider, Typography } from '@material-ui/core'

import { IFranchiseeUserFormValues } from './FranchiseeUserForm'
import { IFranchiseeCommissionsFormValues } from './FranchiseeCommissionsForm'
import { IFranchiseeProcessingFormValues } from './FranchiseeProcessingForm'

interface IFormValuesStack {
  profile?: IFranchiseeUserFormValues
  commissions?: IFranchiseeCommissionsFormValues
  processing?: IFranchiseeProcessingFormValues
}

interface IProps {
  formValues: IFormValuesStack
}

export const FranchiseeFormPreview: React.FC<IProps> = ({ formValues: { profile, commissions, processing } }) => {
  if (!(profile && commissions && processing)) {
    return null
  }

  return (
    <div>
      <Box my={2}>
        <Typography variant='subtitle1'>
          {profile.name} ({profile.type === '1' ? 'Физ лицо' : 'Юр лицо'})
        </Typography>
        <Typography>{profile.phone}</Typography>
        <Typography>{profile.email}</Typography>
        {/* TODO city */}
        {/* <Typography>{profile.cityId}</Typography> */}
      </Box>
      <Divider />
      <Box my={2}>
        <Typography variant='subtitle1'>Комиссии</Typography>
        <Box mb={2} display='flex' alignItems='center' justifyContent='space-between'>
          <Box>
            <Typography variant='body2' color='textSecondary'>
              Комиссия PAYDA
            </Typography>
            <Typography variant='subtitle2'>
              {commissions.commission}
              {commissions.commissionType === '1' ? '%' : ''}
            </Typography>
          </Box>
          <Box textAlign='right'>
            <Typography variant='body2' color='textSecondary'>
              Мин комиссия
            </Typography>
            <Typography variant='subtitle2'>{commissions.commissionMin}</Typography>
          </Box>
        </Box>
        <Divider />
        <Box my={2}>
          <Typography gutterBottom variant='subtitle1'>
            Комиссии партнеров
          </Typography>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box>
              <Typography variant='body2' color='textSecondary'>
                Процент
              </Typography>
              <Typography variant='subtitle2'>
                {commissions.businessCommissionPercentMin}% - {commissions.businessCommissionPercentMax}%
              </Typography>
            </Box>
            <Box textAlign='right'>
              <Typography variant='body2' color='textSecondary'>
                Фиксировано
              </Typography>
              <Typography variant='subtitle2'>
                {commissions.businessCommissionFixedMin} - {commissions.businessCommissionFixedMax}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box my={2}>
          <Typography gutterBottom variant='subtitle1'>
            Процессинг
          </Typography>
          <Box mb={1}>
            <Typography variant='body2' color='textSecondary'>
              Платежная система
            </Typography>
            {/* TODO */}
            <Typography>PayBox</Typography>
          </Box>
          <Box mb={1}>
            <Typography variant='body2' color='textSecondary'>
              API URL
            </Typography>
            <Typography>{processing.apiUrl}</Typography>
          </Box>
          <Box mb={1}>
            <Typography variant='body2' color='textSecondary'>
              Merchant ID
            </Typography>
            <Typography>{processing.merchantId}</Typography>
          </Box>
          <Box mb={1}>
            <Typography variant='body2' color='textSecondary'>
              SECRET KEY
            </Typography>
            <Typography>{processing.secretKey}</Typography>
          </Box>
        </Box>
      </Box>
    </div>
  )
}
