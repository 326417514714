import React from 'react'
import { Box, Button, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import { ApplicationType } from '../../../core/interfaces'
import { I18nField, LoadableButton } from '../../../components/Form'
import { I18nTitles } from '../../../graphql/types/common'
import { IFaqGroup } from '../../../graphql/types/faq'
import { faqGroupSchema } from './validation'

interface IProps {
  item?: IFaqGroup
  submitLabel?: string
  loading: boolean
  onSubmit: (values: IFaqGroupFormValues) => void
  onCancel: () => void
}

export interface IFaqGroupFormValues {
  titles: I18nTitles
  applicationType: ApplicationType
}

export const FaqGroupForm: React.FC<IProps> = ({ item, submitLabel, loading, onSubmit, onCancel }) => {
  const handleSubmit = (values: IFaqGroupFormValues) => {
    onSubmit(values)
  }

  const initialValues: IFaqGroupFormValues = {
    titles: item?.titles || [],
    applicationType: item?.applicationType || ApplicationType.BUSINESS,
  }

  return (
    <Formik initialValues={initialValues} validationSchema={faqGroupSchema} onSubmit={handleSubmit}>
      {({ values, errors, setFieldValue, handleChange, submitForm }: FormikProps<IFaqGroupFormValues>) => (
        <div>
          <Box mb={4}>
            <FormControl component='fieldset'>
              <RadioGroup name='applicationType' value={values.applicationType} onChange={handleChange}>
                <FormControlLabel value={ApplicationType.BUSINESS} control={<Radio />} label={'Бизнес'} />
                <FormControlLabel value={ApplicationType.CLIENT} control={<Radio />} label={'Клиент'} />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box mb={4}>
            <I18nField
              label='Название'
              name='titles'
              value={values.titles}
              onChange={t => setFieldValue('titles', t)}
              required
              fullWidth
              margin='normal'
              error={!!errors.titles}
            />
          </Box>
          <Box pt={4} pb={1} display='flex' flexDirection='row' justifyContent='space-between'>
            <Button onClick={onCancel}>Отмена</Button>
            <LoadableButton loading={loading} color='primary' variant='contained' onClick={submitForm}>
              {submitLabel}
            </LoadableButton>
          </Box>
        </div>
      )}
    </Formik>
  )
}
