import React, { useState } from 'react'
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  Button,
  makeStyles,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import {
  FranchiseeUserForm,
  FranchiseeCommissionsForm,
  FranchiseeProcessingForm,
  IFranchiseeUserFormValues,
  IFranchiseeCommissionsFormValues,
  IFranchiseeProcessingFormValues,
  FranchiseeFormPreview,
} from '../Forms'
import { LoadableButton } from '../../../components/Form'
import { useAddFranchisee } from '../hooks'

interface IProps {
  open: boolean
  onCancel: () => void
}

const STEPS = ['Профиль', 'Комиссии', 'Процессинг', 'Подтверждение']

export const AddFranchiseeModal: React.FC<IProps> = ({ open, onCancel }) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState<number>(0)
  const { formValues, setFormValues, handleCreate, loading, error } = useAddFranchisee()

  const handleUserForm = (values: IFranchiseeUserFormValues) => {
    setFormValues(prev => ({ ...prev, profile: values }))
    setActiveStep(1)
  }

  const handleCommissionsForm = (values: IFranchiseeCommissionsFormValues) => {
    setFormValues(prev => ({ ...prev, commissions: values }))
    setActiveStep(2)
  }

  const handleProcessingForm = (values: IFranchiseeProcessingFormValues) => {
    setFormValues(prev => ({ ...prev, processing: values }))
    setActiveStep(3)
  }

  const handleSubmit = async () => {
    await handleCreate()
    onCancel()
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth onClose={onCancel}>
      <DialogTitle id='form-dialog-title'>Новый франчайзи</DialogTitle>
      <DialogContent>
        {!!error && (
          <Box mb={3}>
            <Alert severity='error'>{error.message}</Alert>
          </Box>
        )}
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {STEPS.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <FranchiseeUserForm submitButtonLabel='Далее' onSubmit={handleUserForm} onCancel={onCancel} />
        )}
        {activeStep === 1 && (
          <FranchiseeCommissionsForm
            submitButtonLabel='Далее'
            onSubmit={handleCommissionsForm}
            onCancel={() => setActiveStep(0)}
          />
        )}
        {activeStep === 2 && (
          <FranchiseeProcessingForm
            submitButtonLabel='Далее'
            onSubmit={handleProcessingForm}
            onCancel={() => setActiveStep(1)}
          />
        )}
        {activeStep === 3 && (
          <Box>
            <FranchiseeFormPreview formValues={formValues} />
            <Box py={2} display='flex' justifyContent='space-between'>
              <Button onClick={onCancel}>Отмена</Button>
              <LoadableButton loading={loading} variant='contained' color='primary' onClick={handleSubmit}>
                Сохранить
              </LoadableButton>
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  stepper: {
    padding: 0,
    marginBottom: theme.spacing(3),
  },
}))
