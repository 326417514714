import { useMutation } from '@apollo/react-hooks'

import { SWITCH_BUSINESS_SMS_NOTIFICATIONS, BUSINESS_DETAIL_QUERY } from '../../queries/businesses'
import { ISwitchBusinessSMSNotificationsData, ISwitchBusinessSMSNotificationsVariables } from '../../types/businesses'

export const useSwitchBusinessSMS = (businessId: number) =>
  useMutation<ISwitchBusinessSMSNotificationsData, ISwitchBusinessSMSNotificationsVariables>(
    SWITCH_BUSINESS_SMS_NOTIFICATIONS,
    {
      refetchQueries: [{ query: BUSINESS_DETAIL_QUERY, variables: { id: businessId } }],
    }
  )
