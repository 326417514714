import React from 'react'
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

import { TRANSACTION_FEE_TYPES_MAP } from '../../core/constants'
import { TransactionFeeType } from '../../core/interfaces'

interface IProps {
  label?: string
  value: TransactionFeeType
  onChange: (value: string) => void
}

export const TransactionFeeTypeSelect: React.FC<IProps> = ({ label, value, onChange }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    onChange(value)
  }

  return (
    <FormControl component='fieldset'>
      {label && <FormLabel component='legend'>{label}</FormLabel>}
      <RadioGroup aria-label='type' name='type' value={value} onChange={handleChange}>
        {TRANSACTION_FEE_TYPES_MAP.map((t, index) => (
          <FormControlLabel key={`t-${index}`} value={t.value} control={<Radio />} label={t.label} />
        ))}
      </RadioGroup>
    </FormControl>
  )
}
