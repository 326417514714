import {
  HomeOutlined,
  Storefront,
  ChevronRight,
  SupervisorAccountOutlined,
  LocalOfferOutlined,
  QuestionAnswerOutlined,
  LanguageOutlined,
  ListOutlined,
  BarChart,
  AccountBoxOutlined as FranchiseeIcon,
  Settings,
} from '@material-ui/icons'

import { IDrawerRouteItem, UserRole } from '../core/interfaces'

const items: IDrawerRouteItem[] = [
  {
    name: 'home',
    title: 'Главная',
    path: '/',
    icon: HomeOutlined,
  },
  {
    name: 'businesses',
    title: 'Партнеры',
    path: '/businesses',
    allowedRoles: [UserRole.ADMIN],
    icon: Storefront,
  },
  {
    name: 'sales',
    title: 'Отдел продаж',
    path: '/sales',
    icon: SupervisorAccountOutlined,
    children: [
      {
        name: 'sales.supervisors',
        title: 'Руководители',
        path: '/sales/supervisors',
        allowedRoles: [UserRole.ADMIN],
        icon: ChevronRight,
      },
      {
        name: 'sales.managers',
        title: 'Менеджеры',
        path: '/sales/managers',
        icon: ChevronRight,
      },
    ],
  },
  {
    name: 'promocodes',
    title: 'Промокоды',
    path: '/promocodes',
    icon: LocalOfferOutlined,
  },
  {
    name: 'franchisee',
    title: 'Франчайзи',
    path: '/franchisee',
    icon: FranchiseeIcon,
    allowedRoles: [UserRole.ADMIN],
  },
  {
    name: 'faqs',
    title: 'FAQ',
    path: '/faq',
    icon: QuestionAnswerOutlined,
    allowedRoles: [UserRole.ADMIN],
  },
  {
    name: 'localization',
    title: 'Локализация',
    path: '/localization',
    icon: LanguageOutlined,
    allowedRoles: [UserRole.ADMIN],
    children: [
      {
        name: 'localization.countries',
        title: 'Страны и города',
        path: '/localization/countries',
        icon: ChevronRight,
      },
    ],
  },
  {
    name: 'categories',
    title: 'Категории',
    path: '/categories',
    icon: ListOutlined,
    allowedRoles: [UserRole.ADMIN],
  },
  {
    name: 'statistics',
    title: 'Статистика',
    path: '/statistics',
    icon: BarChart,
    allowedRoles: [UserRole.ADMIN],
    children: [
      {
        name: 'statistics.transactions',
        title: 'Транзакции',
        path: '/statistics/transactions',
        icon: ChevronRight,
      },
      {
        name: 'statistics.summary',
        title: 'Сводный отчет',
        path: '/statistics/summary',
        icon: ChevronRight,
      },
      {
        name: 'statistics.transaction-fee',
        title: 'Отчет по комиссиям',
        path: '/statistics/transaction-fee',
        icon: ChevronRight,
      },
      {
        name: 'statistics.transaction-fee-old',
        title: 'Отчет по комиссиям (старая версия)',
        path: '/statistics/transaction-fee-old',
        icon: ChevronRight,
      },
      {
        name: 'statistics.auto-login',
        title: 'Статистика по переходам клиентов по ссылке в СМС',
        path: '/statistics/auto-login',
        icon: ChevronRight,
      },
    ],
  },
  {
    name: 'settings',
    title: 'Настройка',
    path: '/settings',
    icon: Settings,
    allowedRoles: [UserRole.SUPERADMIN],
    children: [
      {
        name: 'settings.task-runner',
        title: 'Task Runner',
        path: '/settings/task-runner',
        icon: ChevronRight,
      },
    ],
  },
]

export default items
