import React from 'react'
import { useHistory } from 'react-router-dom'

import { PageHeader, Content } from '../../../components/Layout'
import { EmptyDataMessage, DataList } from '../../../components/Data'
import { CreateFab, Loader } from '../../../components/UI'
import { useAllCountries } from '../../../graphql/hooks/localization'
import { CountryFormModal } from './components/CountryFormModal'
import { ICountry } from '../../../graphql/types/localization'

const Countries: React.FC = () => {
  const history = useHistory()
  const [modalVisible, setModalVisible] = React.useState<boolean>(false)
  const { countries, loading } = useAllCountries()

  const toggleModal = () => {
    setModalVisible(!modalVisible)
  }

  const navigateToCountry = (country: ICountry) => {
    history.push(`/localization/countries/${country.id}`)
  }

  return (
    <div>
      <PageHeader title='Страны и города' />
      {loading && <Loader />}
      {countries.length === 0 && !loading ? (
        <EmptyDataMessage />
      ) : (
        <Content noGutter>
          <DataList data={countries} title={'name'} secondaryText={item => item.code} onClickItem={navigateToCountry} />
        </Content>
      )}
      <CreateFab onClick={toggleModal} />
      {modalVisible && <CountryFormModal open={modalVisible} onCancel={toggleModal} />}
    </div>
  )
}

export default Countries
