import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  InputAdornment,
  IconButton,
  Checkbox,
  Chip,
} from '@material-ui/core'
import { useFormik } from 'formik'
import { Alert } from '@material-ui/lab'
import { useSnackbar } from 'notistack'
import { DatePicker } from '@material-ui/pickers'
import { Close as CloseIcon } from '@material-ui/icons'

import { IBusiness } from '../../../../graphql/types/businesses'
import { TransactionFeeType } from '../../../../core/interfaces'
import { LoadableButton } from '../../../../components/Form'
import { importClientsSchema } from './validation'
import { DATE_FORMAT } from '../../../../core/constants'
import { useUploadBusinessClients } from '../../../../graphql/hooks/businesses'

interface IProps {
  business: IBusiness
  open: boolean
  onClose: () => void
}

interface IImportClientsFormValues {
  feeType: 'default' | 'custom'
  feeAmount: string
  expiresAt: string | null
  notifyClients: boolean
  file: File | null
}

export const ImportClientsModal: React.FC<IProps> = ({ business, open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [uploadClients, { loading, error }] = useUploadBusinessClients(business.id)

  const formik = useFormik<IImportClientsFormValues>({
    initialValues: {
      feeType: 'default',
      feeAmount: `${business.minTransactionFee || ''}`,
      expiresAt: null,
      notifyClients: false,
      file: null,
    },
    validationSchema: importClientsSchema,
    async onSubmit(values, formikHelpers) {
      if (!values.file) {
        return
      }

      try {
        const { data } = await uploadClients({
          variables: {
            file: values.file,
            input: {
              businessId: business.id,
              feeType: values.feeType,
              feeAmount: values.feeAmount ? parseFloat(values.feeAmount) : null,
              expiresAt: values.expiresAt,
              notifyClients: values.notifyClients,
            },
          },
        })

        if (data?.uploadBusinessClients) {
          onClose()
          enqueueSnackbar('Импорт прошло успешно', { variant: 'success' })
        } else {
          enqueueSnackbar('Что то пошло не так', { variant: 'error' })
        }
      } catch (error) {
        console.log(error)
      }
    },
  })

  const { values, errors, setFieldValue, handleChange, submitForm } = formik

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files, validity } = event.target
    const file = files && files[0]
    if (!validity.valid || !file) {
      return null
    }

    setFieldValue('file', file)
  }

  const transactionFeeValue = () => {
    const suffix = business.transactionFeeType === TransactionFeeType.FIX ? ` ${business.country.currencySymbol}` : '%'

    return `${business.transactionFee}${suffix}`
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>Импорт клиентов (CSV)</DialogTitle>
      <DialogContent>
        <Box mb={3}>
          {!!values.file ? (
            <Chip
              label={values.file.name}
              color='secondary'
              variant='outlined'
              onDelete={() => setFieldValue('file', null)}
            />
          ) : (
            <Button variant='outlined' color='secondary' component='label'>
              <Typography>Выбрать файл (CSV)</Typography>
              <input type='file' accept='.csv' style={{ display: 'none' }} onChange={handleSelectFile} />
            </Button>
          )}
          {!!errors.file && (
            <Typography variant='body2' color='error'>
              Выберите файл
            </Typography>
          )}
        </Box>
        <Box mb={3}>
          <Typography color='textSecondary'>Комиссия</Typography>
          <FormControl margin='none'>
            <RadioGroup value={values.feeType} name='feeType' onChange={handleChange}>
              <FormControlLabel
                value={'default'}
                control={<Radio size='small' />}
                label={`По настройкам бизнеса (${transactionFeeValue()})`}
              />
              <FormControlLabel value={'custom'} control={<Radio size='small' />} label={'Другая'} />
            </RadioGroup>
          </FormControl>
          {values.feeType === 'custom' && (
            <TextField
              label='Комиссия'
              name='feeAmount'
              value={values.feeAmount}
              onChange={handleChange}
              fullWidth
              margin='none'
              error={!!errors.feeAmount}
              type='number'
            />
          )}
        </Box>
        <Box mb={3}>
          <DatePicker
            variant='inline'
            label='Дата сгорания Бонусов'
            value={values.expiresAt}
            onChange={date => setFieldValue('expiresAt', date?.toISOString())}
            autoOk
            format={DATE_FORMAT}
            fullWidth
            disablePast
            size='small'
            InputProps={{
              endAdornment: !!values.expiresAt ? (
                <InputAdornment position='end'>
                  <IconButton size='small' onClick={() => setFieldValue('expiresAt', null)}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ) : (
                undefined
              ),
            }}
            error={!!errors.expiresAt}
          />
        </Box>
        <Box mb={3}>
          <FormControlLabel
            control={
              <Checkbox checked={values.notifyClients} onChange={(e, ch) => setFieldValue('notifyClients', ch)} />
            }
            label='Уведомить клиентов'
          />
        </Box>
        {!!error && (
          <Box py={2}>
            <Alert severity='error'>{error.message}</Alert>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='default'>
          Закрыть
        </Button>
        <LoadableButton loading={loading} color='primary' onClick={submitForm}>
          Импортировать
        </LoadableButton>
      </DialogActions>
    </Dialog>
  )
}
