import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import { REGISTRATION_ADMIN_DEVICE_TOKEN } from '../../queries/auth'

import { IRegisterAdminDeviceTokenData, IRegisterAdminDeviceTokenVariables } from '../../types/auth'

export const useRegisterAdminDeviceToken = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [registerAdminDeviceToken] = useMutation<IRegisterAdminDeviceTokenData, IRegisterAdminDeviceTokenVariables>(
    REGISTRATION_ADMIN_DEVICE_TOKEN
  )

  const registerDeviceToken = async (token: string) => {
    if (!token) {
      return
    }
    try {
      setLoading(true)
      await registerAdminDeviceToken({
        variables: { token },
      })
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  return {
    registerDeviceToken,
    loading,
  }
}
