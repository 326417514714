import gql from 'graphql-tag'

export const ALL_FAQ_GROUP_QUERY = gql`
  query AllFaqGroupQuery($applicationType: String!) {
    allFaqGroups(applicationType: $applicationType) {
      id
      name
      titles {
        code
        label
      }
      faqs {
        id
        question
        i18nQuestion {
          code
          label
        }
        ask
        i18nAnswer {
          code
          label
        }
      }
      applicationType
    }
  }
`

export const FAQ_GROUP_BY_ID_QUERY = gql`
  query FaqGroupById($id: Int!) {
    faqGroupById(id: $id) {
      id
      name
      titles {
        code
        label
      }
      faqs {
        id
        question
        i18nQuestion {
          code
          label
        }
        ask
        i18nAnswer {
          code
          label
        }
        group {
          id
          name
        }
      }
      applicationType
    }
  }
`

export const UPSERT_FAQ_GROUP_MUTATION = gql`
  mutation UpsertFaqGroupMutation($input: UpsertFaqGroupInput!) {
    upsertFaqGroup(input: $input) {
      id
      name
      titles {
        code
        label
      }
      faqs {
        id
        question
        i18nQuestion {
          code
          label
        }
        ask
        i18nAnswer {
          code
          label
        }
        group {
          id
          name
        }
      }
      applicationType
    }
  }
`

export const UPSERT_FAQ_MUTATION = gql`
  mutation UpsertFaqMutation($input: UpsertFaqInput!) {
    upsertFaq(input: $input) {
      id
      question
      i18nQuestion {
        code
        label
      }
      ask
      i18nAnswer {
        code
        label
      }
      group {
        id
        name
        titles {
          code
          label
        }
      }
    }
  }
`

export const DELETE_FAQ_GROUP_MUTATION = gql`
  mutation DeleteFaqGroupMutation($id: Int!) {
    deleteFaqGroup(id: $id)
  }
`

export const DELETE_FAQ_MUTATION = gql`
  mutation DeleteFaqMutation($id: Int!) {
    deleteFaq(id: $id)
  }
`
