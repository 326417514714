import React from 'react'
import { Box, Button, TextField, MenuItem } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'

import { processingSchema } from './validation'
import { IFranchisee } from '../../../graphql/types/franchisee'

interface IProps {
  franchisee?: IFranchisee
  submitButtonLabel?: string
  onSubmit: (values: IFranchiseeProcessingFormValues) => void
  onCancel: () => void
}

export interface IFranchiseeProcessingFormValues {
  paymentSystemId: string
  merchantId: string
  apiUrl: string
  secretKey: string
}

export const FranchiseeProcessingForm: React.FC<IProps> = ({
  franchisee,
  onSubmit,
  onCancel,
  submitButtonLabel = 'Сохранить',
}) => {
  const initialValues: IFranchiseeProcessingFormValues = {
    paymentSystemId: '1', // TODO
    merchantId: franchisee?.settings.processing.merchantId || '',
    apiUrl: franchisee?.settings.processing.apiUrl || 'https://api.paybox.money',
    secretKey: franchisee?.settings.processing.secretKey || '',
  }

  const handleSubmit = (values: IFranchiseeProcessingFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={processingSchema} onSubmit={handleSubmit}>
      {({ values, errors, handleChange, submitForm }: FormikProps<IFranchiseeProcessingFormValues>) => (
        <div>
          <Box>
            <TextField
              label='Платежная система'
              name='paymentSystemId'
              value={values.paymentSystemId}
              onChange={handleChange}
              required
              fullWidth
              margin='normal'
              error={!!errors.paymentSystemId}
              select
            >
              <MenuItem value={'1'}>Paybox</MenuItem>
            </TextField>
            <TextField
              label={`Merchant ID`}
              name='merchantId'
              value={values.merchantId}
              onChange={handleChange}
              fullWidth
              margin='normal'
              error={!!errors.merchantId}
            />
            <TextField
              label={`API URL`}
              name='apiUrl'
              value={values.apiUrl}
              onChange={handleChange}
              fullWidth
              margin='normal'
              error={!!errors.apiUrl}
            />
            <TextField
              label={`SECRET KEY`}
              name='secretKey'
              value={values.secretKey}
              onChange={handleChange}
              fullWidth
              margin='normal'
              error={!!errors.secretKey}
            />
          </Box>
          <Box pt={4} pb={1} display='flex' flexDirection='row' justifyContent='space-between'>
            <Button onClick={onCancel}>Назад</Button>
            <Button color='primary' variant='contained' onClick={submitForm}>
              {submitButtonLabel}
            </Button>
          </Box>
        </div>
      )}
    </Formik>
  )
}
