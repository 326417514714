import React, { useState } from 'react'
import { Typography, Box, IconButton, Popover } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Comment as CommentIcon } from '@material-ui/icons'
import { Rating } from '@material-ui/lab'

import { ITransaction } from '../../../graphql/types/transactions'

interface IProps {
  transaction: ITransaction
}

export const ClientTransactionItem: React.FC<IProps> = ({ transaction }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const hasComment = !!transaction.review?.comment

  return (
    <Box display='flex' alignItems='center' className={classes.container}>
      <Typography color='textSecondary' variant='body2' noWrap>
        {`ID:${transaction.id}`}
      </Typography>
      {!!transaction.review && (
        <>
          <Box ml={1}>
            <Rating value={transaction.review.rating} readOnly size='small' />
          </Box>
          <Box ml={1} color='success.main'>
            <IconButton size='small' onClick={handleClick} color='inherit' disabled={!hasComment}>
              <CommentIcon fontSize='small' />
            </IconButton>
            {hasComment && (
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box className={classes.popover}>
                  <Typography color='textSecondary' variant='caption'>
                    {transaction.review.comment}
                  </Typography>
                </Box>
              </Popover>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: 6,
    padding: `0px ${theme.spacing(1)}px`,
  },
  popover: {
    width: 220,
    maxHeight: 100,
    overflowY: 'auto',
    maxWidth: '100%',
    padding: theme.spacing(1),
  },
}))
