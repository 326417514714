import { useQuery } from '@apollo/react-hooks'

import { GET_SMS_BALANCE } from '../../queries/report'
import { IGetSmsBalanceData } from '../../types/report'

export const useGetBalance = () => {
  const { data, loading } = useQuery<IGetSmsBalanceData, {}>(GET_SMS_BALANCE, { fetchPolicy: 'cache-and-network' })

  // TODO: pagination

  return {
    balance: data?.getSmsBalance || 0,
    loading,
  }
}
