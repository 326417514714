import React from 'react'
import { Typography, TypographyProps } from '@material-ui/core'
import { I18nTitles } from '../../graphql/types/common'
import { useCurrentLanguage } from '../../services/language'

interface IProps extends TypographyProps {
  value?: I18nTitles
  fallback?: string
}

export const I18nTypography: React.FC<IProps> = ({ value, fallback = '', ...typographyProps }) => {
  const { currentLanguage } = useCurrentLanguage()
  const currentValue = (value || []).find(v => v.code === currentLanguage)

  return <Typography {...typographyProps}>{currentValue?.label ?? fallback}</Typography>
}
