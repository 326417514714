import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
} from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as yup from 'yup'
import { DatePicker } from '@material-ui/pickers'
import { CheckCircle as SuccessIcon, FileCopy as CopyIcon } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import moment from 'moment'

import { IBusiness } from '../../../graphql/types/businesses'
import { LoadableButton } from '../../../components/Form'
import { DATE_FORMAT } from '../../../core/constants'
import { useCreateBusinessApiCredential } from '../../../graphql/hooks/external-api'
import { IBusinessApiCredential, IBusinessApiCredentialInput } from '../../../graphql/types/external-api'

interface IProps {
  business: IBusiness
  open: boolean
  onClose: () => void
}

interface IFormValues {
  title: string
  expiresAt: string
}

const validationSchema = yup.object().shape({
  title: yup.string().required(),
  expiresAt: yup.string().required(),
})

export const BusinessCreateCredentialModal: React.FC<IProps> = ({ business, open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [createCredential, { loading }] = useCreateBusinessApiCredential(business.id)
  const [credential, setCredential] = useState<IBusinessApiCredential>()
  const [secretKey, setSecretKey] = useState<string>()

  const initialValues: IFormValues = {
    title: '',
    expiresAt: moment()
      .add(1, 'year')
      .toISOString(),
  }

  const handleSubmit = async (values: IFormValues) => {
    try {
      const input: IBusinessApiCredentialInput = {
        businessId: business.id,
        title: values.title,
        expiresAt: values.expiresAt,
      }

      const { data } = await createCredential({
        variables: { input },
      })

      if (data?.createBusinessApiCredential.secretKey) {
        setSecretKey(data.createBusinessApiCredential.secretKey)
        setCredential(data.createBusinessApiCredential.credential)
      }
    } catch (err) {
      console.log(err)
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, errors, setFieldValue, handleChange, submitForm }: FormikProps<IFormValues>) => (
        <Dialog open={open} maxWidth='sm' fullWidth aria-labelledby='form-dialog-title'>
          <DialogTitle id='form-dialog-title'>Создание ключа доступа</DialogTitle>
          <DialogContent>
            {credential && secretKey ? (
              <SuccessContent id={credential.id} secretKey={secretKey} />
            ) : (
              <Box>
                <Box mb={2}>
                  <TextField
                    label='Название'
                    name='title'
                    value={values.title}
                    onChange={handleChange}
                    required
                    fullWidth
                    margin='normal'
                    error={!!errors.title}
                  />
                </Box>
                <DatePicker
                  variant='inline'
                  label='Срок действия до'
                  value={values.expiresAt}
                  onChange={date => setFieldValue('expiresAt', date?.toISOString())}
                  autoOk
                  format={DATE_FORMAT}
                  fullWidth
                  error={!!errors.expiresAt}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color='default'>
              Закрыть
            </Button>
            {!secretKey && (
              <LoadableButton loading={loading} color='primary' onClick={submitForm}>
                Создать
              </LoadableButton>
            )}
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

const SuccessContent: React.FC<{ id: number; secretKey: string }> = ({ id, secretKey }) => {
  return (
    <Box>
      <Box mb={3} textAlign='center'>
        <Box color='success.main' fontSize={64}>
          <SuccessIcon color='inherit' fontSize='inherit' />
        </Box>
        <Box color='success.main'>
          <Typography color='inherit'>Данные доступа успешно созданы</Typography>
        </Box>
      </Box>
      <Box mb={1}>
        <Typography>
          Ключи для доступа. Скопируйте секретный ключ и сохраните в надежном месте. После закрытия окна данный ключ
          нельзя восстановить.
        </Typography>
      </Box>
      <Box display='flex' justifyContent='space-between' bgcolor='grey.200' p={2} borderRadius={6} mb={1}>
        <Typography>{`PublicID: ${id}`}</Typography>
        <IconButton size='small' onClick={() => navigator.clipboard.writeText(`${id}`)}>
          <CopyIcon fontSize='small' color='disabled' />
        </IconButton>
      </Box>
      <Box display='flex' justifyContent='space-between' bgcolor='grey.200' p={2} borderRadius={6}>
        <Typography>{`SecretKey: ${secretKey.slice(0, 7)}...${secretKey.slice(-7)}`}</Typography>
        <IconButton size='small' onClick={() => navigator.clipboard.writeText(secretKey)}>
          <CopyIcon fontSize='small' color='disabled' />
        </IconButton>
      </Box>
    </Box>
  )
}
