import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Box, Button, Container } from '@material-ui/core'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import { BUSINESS_DETAIL_QUERY, UPDATE_BUSINESS_PROFILE_MUTATION } from '../../graphql/queries/businesses'
import {
  IBusinessDetailData,
  IBusinessDetailVariables,
  IBusinessProfileInput,
  IUpdateBusinessProfileData,
  IUpdateBusinessProfileVariables,
} from '../../graphql/types/businesses'
import { PageHeader } from '../../components/Layout'
import { Loader } from '../../components/UI'
import PageNotFound from '../PageNotFound'
import { BusinessProfileForm, DeleteBusinessModal, IProfileFormValues } from './components'
import { EditableBusinessAvatar } from './components/EditableBusinessAvatar'

interface IProps extends RouteComponentProps<{ id: string }> {}

const BusinessEdit: React.FC<IProps> = ({ location, match }) => {
  const [deleteModalVisible, setDeleteModalVisible] = React.useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const [updateProfile, { loading: saveLoading }] = useMutation<
    IUpdateBusinessProfileData,
    IUpdateBusinessProfileVariables
  >(UPDATE_BUSINESS_PROFILE_MUTATION)
  const { data, loading } = useQuery<IBusinessDetailData, IBusinessDetailVariables>(BUSINESS_DETAIL_QUERY, {
    variables: { id: parseInt(match.params.id, 10) },
  })
  const business = data?.businessDetail

  if (loading) {
    return <Loader />
  }

  if (!business) {
    return <PageNotFound />
  }

  const toggleDeleteModal = () => {
    setDeleteModalVisible(!deleteModalVisible)
  }

  const handleSubmit = async (values: IProfileFormValues) => {
    const input: IBusinessProfileInput = {
      ...values,
      ownerName: business.owner.fullName,
      cashbackPercent: parseFloat(values.cashbackPercent),
      cityId: values.cityId ? parseInt(values.cityId, 10) : undefined,
    }

    try {
      await updateProfile({
        variables: { businessId: business.id, input },
        refetchQueries: [{ query: BUSINESS_DETAIL_QUERY, variables: { id: business.id } }],
      })

      enqueueSnackbar('Профиль успешно обновлен!', { variant: 'success' })
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }

  // TODO: is temporary solution
  // remove after implemented ROLES
  const query = new URLSearchParams(location.search)
  const isDeletable = process.env.NODE_ENV !== 'production' ? true : !!query.get('deletable')

  return (
    <div>
      <PageHeader title={business.companyName} back />
      <Container maxWidth='sm'>
        <Box display='flex' justifyContent='center' mb={2}>
          <EditableBusinessAvatar business={business} />
        </Box>
        <BusinessProfileForm business={business} loading={saveLoading} onSubmit={handleSubmit} />
        {isDeletable && (
          <Box mt={5} mb={3} color='error.main'>
            <Button variant='outlined' color='inherit' onClick={toggleDeleteModal}>
              Удалить бизнес
            </Button>
            {deleteModalVisible && <DeleteBusinessModal business={business} open onClose={toggleDeleteModal} />}
          </Box>
        )}
      </Container>
    </div>
  )
}

export default BusinessEdit
