import React from 'react'
import isDeepEqual from 'fast-deep-equal/react'
import { RouteComponentProps } from 'react-router-dom'
import { Box, Typography, Grid } from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'

import { PageHeader, Content } from '../../../components/Layout'

import { IDataTableColumn, DataTable, EmptyDataMessage } from '../../../components/Data'

import { Loader } from '../../../components/UI'
import Pagination from '../../../components/Data/pagination/Pagination'
import { DATE_TIME_FORMAT, ROWS_PER_PAGE } from '../../../core/constants'

import { IPagination } from '../../../core/interfaces'
import { useAutoLoginReportFilter } from './useAutoLoginReportFilter'
import { GET_AUTO_LOGIN_REPORT } from '../../../graphql/queries/report'
import {
  IAutoLoginReportItem,
  IGetAutoLoginReportData,
  IGetAutoLoginReportVariables,
} from '../../../graphql/types/report'
import moment from 'moment'
import { SingleValueWidget } from '../../../components/Widgets'

interface IProps extends RouteComponentProps<{}> {}

const AutoLoginReport: React.FC<IProps> = ({ match }) => {
  const { filter, renderFilter } = useAutoLoginReportFilter()
  const [pagination, setPagination] = React.useState<IPagination>({ offset: 0, limit: ROWS_PER_PAGE[0], page: 0 })
  const filterRef = React.useRef(filter)

  if (!isDeepEqual(filterRef.current, filter)) {
    filterRef.current = filter
  }

  const { data, loading } = useQuery<IGetAutoLoginReportData, IGetAutoLoginReportVariables>(GET_AUTO_LOGIN_REPORT, {
    variables: {
      input: {
        period: {
          startDate: filter.startDate,
          endDate: filter.endDate,
        },
        isActivated: filter.isActivated,
      },
      pagination: {
        limit: pagination.limit,
        offset: pagination.offset,
      },
    },
    fetchPolicy: 'network-only',
  })

  const transactions = data?.getAutoLoginReport.data || []
  const total = data?.getAutoLoginReport.total || 0
  const countOfActivated = data?.getAutoLoginReport.countOfActivated || 0
  const countOfNotActivated = data?.getAutoLoginReport.countOfNotActivated || 0
  const countOfActivatedByPeriod = data?.getAutoLoginReport.countOfActivatedByPeriod || 0
  const countOfNotActivatedByPeriod = data?.getAutoLoginReport.countOfNotActivatedByPeriod || 0
  const countOfApplicationUsers = data?.getAutoLoginReport.countOfApplicationUsers || 0
  const countOfApplicationUsersByPeriod = data?.getAutoLoginReport.countOfApplicationUsersByPeriod || 0

  const handleChangePerPage = (limit: number) => {
    if (limit !== pagination.limit) {
      setPagination({ page: 0, offset: 0, limit })
    }
  }
  const handleChangePage = (page: number) => {
    setPagination({ ...pagination, offset: page * pagination.limit, page })
  }

  const columns: IDataTableColumn<IAutoLoginReportItem>[] = [
    {
      label: 'Номер телефона',
      accessor: 'user.phone',
      stickyColumn: true,
    },
    {
      label: 'Статус',
      accessor: item => (item.isActivated ? 'Прошел' : new Date() <= item.expireAt ? 'Ожидает' : 'Не прошел'),
    },
    {
      label: 'МП',
      accessor: item => (item.isApplicationUser ? 'Есть' : 'Нет'),
    },
    {
      label: 'Дата сгорание ссылки',
      accessor: item => (
        <Typography color='textSecondary' style={{ whiteSpace: 'nowrap' }}>
          {moment(item.expireAt).format(DATE_TIME_FORMAT)}
        </Typography>
      ),
    },
    {
      label: 'Дата создания',
      accessor: item => (
        <Typography color='textSecondary' style={{ whiteSpace: 'nowrap' }}>
          {moment(item.createdAt).format(DATE_TIME_FORMAT)}
        </Typography>
      ),
    },
    {
      label: 'Дата изменения',
      accessor: item => (
        <Typography color='textSecondary' style={{ whiteSpace: 'nowrap' }}>
          {moment(item.updatedAt).format(DATE_TIME_FORMAT)}
        </Typography>
      ),
    },
  ]

  React.useEffect(() => {
    setPagination({ ...pagination, page: 0, offset: 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRef.current])

  return (
    <div>
      <PageHeader
        title={`Статистика по переходам клиентов по ссылке в СМС`}
        right={<Box display='inline-flex'>{renderFilter()}</Box>}
      />
      {loading && <Loader />}
      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={3}>
            <SingleValueWidget
              label='Количество активированных за период'
              value={countOfActivatedByPeriod.toLocaleString()}
              valueDesc={`из ${(countOfActivatedByPeriod + countOfNotActivatedByPeriod).toLocaleString()}`}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <SingleValueWidget
              label='Количество активированных'
              value={countOfActivated.toLocaleString()}
              valueDesc={`из ${(countOfActivated + countOfNotActivated).toLocaleString()}`}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <SingleValueWidget
              label='Количество установленных за период'
              value={countOfApplicationUsersByPeriod.toLocaleString()}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <SingleValueWidget label='Количество установленных' value={countOfApplicationUsers.toLocaleString()} />
          </Grid>
        </Grid>
      </Box>
      {transactions.length > 0 ? (
        <Content noGutter>
          <Pagination
            rowsPerPage={pagination.limit}
            rowsPerPageOptions={ROWS_PER_PAGE}
            onChangeRowsPerPage={handleChangePerPage}
            onChangePage={handleChangePage}
            page={pagination.page}
            count={total}
          />
          <DataTable size='small' stickyHeader topHeight={340} data={transactions} columns={columns} />
          <Box pt={2}>
            <Box mr={2} display='flex' alignItems='center' justifyContent='flex-end'>
              <Box mr={2} fontWeight={500} fontSize={14}>
                Итого:
              </Box>
              <Box fontWeight={600} fontSize={16} textAlign='right'>
                {total.toLocaleString()}
              </Box>
            </Box>
            <Pagination
              rowsPerPage={pagination.limit}
              rowsPerPageOptions={ROWS_PER_PAGE}
              onChangeRowsPerPage={handleChangePerPage}
              onChangePage={handleChangePage}
              page={pagination.page}
              count={total}
            />
          </Box>
        </Content>
      ) : (
        <EmptyDataMessage />
      )}
    </div>
  )
}

export default AutoLoginReport
