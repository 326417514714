import React from 'react'
import { Tabs, Tab, Box, Divider } from '@material-ui/core'
import { RouteComponentProps, Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { LanguageSwitcher } from '../../services/language'
import { PageHeader, Content } from '../../components/Layout'
import { ApplicationType } from '../../core/interfaces'
import { ALL_FAQ_GROUP_QUERY } from '../../graphql/queries/faq'
import { IAllFaqGroupsData, IAllFaqGroupsVariables, IFaqGroup } from '../../graphql/types/faq'
import { Loader } from '../../components/UI'

import { EmptyDataMessage } from '../../components/Data'
import { FaqDataList } from './components/FaqDataList'
import FaqGroupEditorModalButton from './components/FaqGroupEditorModalButton'

interface IParams {
  applicationType?: ApplicationType
}

const APPLICATION_TYEP_LABEL = {
  [ApplicationType.BUSINESS]: 'Бизнес',
  [ApplicationType.CLIENT]: 'Клиент',
}

const Faqs: React.FC<RouteComponentProps<IParams>> = ({
  history,
  match: {
    params: { applicationType = ApplicationType.BUSINESS },
  },
}) => {
  const { loading, data } = useQuery<IAllFaqGroupsData, IAllFaqGroupsVariables>(ALL_FAQ_GROUP_QUERY, {
    variables: { applicationType },
  })

  const handleClick = (item: IFaqGroup) => {
    history.push(`/faq/${applicationType}/${item.id}`)
  }

  const renderTitle = (item: IFaqGroup) => {
    return item.name
  }

  const renderSecondaryText = (item: IFaqGroup) => {
    return `Количество: ${item.faqs.length}`
  }

  return (
    <div>
      <PageHeader title='Рубрики FAQs' />
      <Box mb={2}>
        <LanguageSwitcher />
      </Box>
      <Content noGutter>
        <Tabs value={applicationType}>
          <Tab
            value={ApplicationType.BUSINESS}
            label={APPLICATION_TYEP_LABEL[ApplicationType.BUSINESS]}
            component={Link}
            to={'/faq/business'}
          />
          <Tab
            value={ApplicationType.CLIENT}
            label={APPLICATION_TYEP_LABEL[ApplicationType.CLIENT]}
            component={Link}
            to={'/faq/client'}
          />
        </Tabs>
        <Divider />
        <Box>
          {loading && <Loader />}
          {data?.allFaqGroups.length === 0 && !loading ? (
            <EmptyDataMessage />
          ) : (
            <FaqDataList
              data={data?.allFaqGroups || []}
              title={renderTitle}
              secondaryText={renderSecondaryText}
              onClickItem={handleClick}
            />
          )}
          <FaqGroupEditorModalButton />
        </Box>
      </Content>
    </div>
  )
}

export default Faqs
