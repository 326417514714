import React from 'react'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormHelperText,
  TextField,
} from '@material-ui/core'
import { Formik, FormikProps } from 'formik'

import { addBalanceSchema } from './validation'
import { FranchiseeReplenishmentType } from '../../../core/interfaces'
import { LoadableButton } from '../../../components/Form'

interface IProps {
  loading: boolean
  onSubmit: (values: IFranchiseeAddBalanceFormValues) => void
  onCancel: () => void
}

export interface IFranchiseeAddBalanceFormValues {
  amount: string
  replenishmentType: string
}

export const FranchiseeAddBalanceForm: React.FC<IProps> = ({ loading, onSubmit, onCancel }) => {
  const initialValues: IFranchiseeAddBalanceFormValues = {
    amount: '',
    replenishmentType: '',
  }

  const handleSubmit = (values: IFranchiseeAddBalanceFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={addBalanceSchema} onSubmit={handleSubmit}>
      {({ values, errors, handleChange, submitForm }: FormikProps<IFranchiseeAddBalanceFormValues>) => (
        <div>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Выберите вид платежа</FormLabel>
            <RadioGroup
              aria-label='franchisee-rep-type'
              name='replenishmentType'
              value={values.replenishmentType}
              onChange={handleChange}
            >
              <FormControlLabel value={`${FranchiseeReplenishmentType.CASH}`} control={<Radio />} label='Наличными' />
              <FormControlLabel
                value={`${FranchiseeReplenishmentType.CASHLESS}`}
                control={<Radio />}
                label='Безналичными'
              />
            </RadioGroup>
            {!!errors.replenishmentType && <FormHelperText error>Выберите вид платежа</FormHelperText>}
          </FormControl>
          <TextField
            label='Сумма пополнения'
            name='amount'
            value={values.amount}
            onChange={handleChange}
            required
            fullWidth
            margin='normal'
            error={!!errors.amount}
          />

          <Box pt={4} pb={1} display='flex' flexDirection='row' justifyContent='space-between'>
            <Button onClick={onCancel}>Закрыть</Button>
            <LoadableButton loading={loading} color='primary' variant='contained' onClick={submitForm}>
              Пополнить
            </LoadableButton>
          </Box>
        </div>
      )}
    </Formik>
  )
}
