import { useQuery } from '@apollo/react-hooks'

import { ALL_TIMEZONES_QUERY } from '../../queries/localization'
import { IAllTimezonesData } from '../../types/localization'

export const useAllTimezones = () => {
  const { data, loading } = useQuery<IAllTimezonesData, {}>(ALL_TIMEZONES_QUERY, {
    fetchPolicy: 'cache-first',
  })

  return {
    timezones: data?.allTimezones.timezones || [],
    total: data?.allTimezones.total || 0,
    loading,
  }
}
