import { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import { IFranchiseeCommissionsFormValues, IFranchiseeProcessingFormValues, IFranchiseeUserFormValues } from '../Forms'
import {
  ALL_FRANCHISEE_QUERY,
  CREATE_FRANCHISEE_MUTATION,
  SEND_FRANCHISEE_INVITE,
} from '../../../graphql/queries/franchisee'
import {
  ICreateFranchiseeInput,
  ISendFranchiseeInviteData,
  ISendFranchiseeInviteVariables,
} from '../../../graphql/types/franchisee'

interface IFormValuesStack {
  profile?: IFranchiseeUserFormValues
  commissions?: IFranchiseeCommissionsFormValues
  processing?: IFranchiseeProcessingFormValues
}

export const useAddFranchisee = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [formValues, setFormValues] = useState<IFormValuesStack>({})

  const [createFranchisee, { loading, error }] = useMutation(CREATE_FRANCHISEE_MUTATION, {
    refetchQueries: [{ query: ALL_FRANCHISEE_QUERY }],
  })

  const [sendFranchiseeInvite] = useMutation<ISendFranchiseeInviteData, ISendFranchiseeInviteVariables>(
    SEND_FRANCHISEE_INVITE
  )

  const handleCreate = async () => {
    const { profile, commissions, processing } = formValues

    if (!(profile && commissions && processing)) {
      return
    }

    const input: ICreateFranchiseeInput = {
      name: profile.name,
      type: Number(profile.type),
      phone: profile.phone,
      email: profile.email,
      cityId: Number(profile.cityId),
      commissionType: Number(commissions.commissionType),
      commission: Number(commissions.commission),
      commissionMin: Number(commissions.commissionMin),
      businessCommission: {
        percent: {
          min: Number(commissions.businessCommissionPercentMin),
          max: Number(commissions.businessCommissionPercentMax),
        },
        fixed: {
          min: Number(commissions.businessCommissionFixedMin),
          max: Number(commissions.businessCommissionFixedMax),
        },
      },
      processing,
    }

    await createFranchisee({
      variables: { input },
    })

    sendFranchiseeInvite({
      variables: { email: input.email },
    }).then(({ data }) => {
      if (data?.sendFranchiseeInviteEmail) {
        enqueueSnackbar(`Отправлено приглашение на ${input.email}`, {
          variant: 'success',
          autoHideDuration: 2000,
        })
      }
    })
  }

  return {
    handleCreate,
    loading,
    error,
    formValues,
    setFormValues,
  }
}
