import gql from 'graphql-tag'

export const REVIEW_FRAGMENT = gql`
  fragment ReviewFragment on Review {
    id
    rating
    executorRating
    executor {
      id
      userId
      name
    }
    employeeRating
    employee {
      id
      userId
      name
    }
    comment
    createdAt
  }
`
