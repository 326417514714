import gql from 'graphql-tag'

export const BUSINESS_API_CREDENTIAL_FRAGMENT = gql`
  fragment BusinessApiCredentialFragment on BusinessApiCredential {
    id
    title
    business {
      id
      companyName
    }
    isActive
    expiresAt
    createdBy {
      id
      fullName
    }
    createdAt
  }
`

export const BUSINESS_API_CREDENTIALS = gql`
  query GetBusinessApiCredentials($businessId: Int!, $pagination: PaginationInput) {
    businessApiCredentials(businessId: $businessId, pagination: $pagination) {
      credentials {
        ...BusinessApiCredentialFragment
      }
      total
    }
  }
  ${BUSINESS_API_CREDENTIAL_FRAGMENT}
`

export const CREATE_BUSINESS_API_CREDENTIAL = gql`
  mutation CreateBusinessApiCredential($input: BusinessApiCredentialInput!) {
    createBusinessApiCredential(input: $input) {
      credential {
        ...BusinessApiCredentialFragment
      }
      secretKey
    }
  }
  ${BUSINESS_API_CREDENTIAL_FRAGMENT}
`

export const UPDATE_BUSINESS_API_CREDENTIAL = gql`
  mutation UpdateBusinessApiCredential($id: Int!, $input: BusinessApiCredentialInput!) {
    updateBusinessApiCredential(id: $id, input: $input) {
      ...BusinessApiCredentialFragment
    }
  }
  ${BUSINESS_API_CREDENTIAL_FRAGMENT}
`

export const DELETE_BUSINESS_API_CREDENTIAL = gql`
  mutation DeleteBusinessApiCredential($id: Int!) {
    deleteBusinessApiCredential(id: $id)
  }
`
