import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Box, Divider, Typography, Grid, IconButton, CircularProgress } from '@material-ui/core'
import {
  Settings as SettingsIcon,
  AccountBalanceWallet as BalanceIcon,
  Storefront as BusinessesIcon,
} from '@material-ui/icons'

import { maskPhone } from '../../utils/helpers'
import { PageHeader, Content } from '../../components/Layout'
import { FranchiseeCommissionsModal, FranchiseeProcessingModal, FranchiseeUserModal } from './components'
import { SingleValueWidget } from '../../components/Widgets'
import { Loader } from '../../components/UI'
import { useFranchiseeDetail } from '../../graphql/hooks/franchisee'
import { Alert, AlertTitle } from '@material-ui/lab'
import { useResetPasswordInfo } from '../../graphql/hooks/auth'
import { ISendFranchiseeInviteData, ISendFranchiseeInviteVariables } from '../../graphql/types/franchisee'
import { useMutation } from '@apollo/react-hooks'
import { SEND_FRANCHISEE_INVITE } from '../../graphql/queries/franchisee'
import { LoadableButton } from '../../components/Form'
import { useSnackbar } from 'notistack'
import moment from 'moment'

interface IProps extends RouteComponentProps<{ id: string }> {}

const FranchiseeDetail: React.FC<IProps> = ({ history, match }) => {
  const [comissionsModalVisible, setComissionModalVisible] = useState<boolean>(false)
  const [processingModalVisible, setProcessingModalVisible] = useState<boolean>(false)
  const [userModalVisible, setUserModalVisible] = useState<boolean>(false)
  const [sendFranchiseeInvite, { loading: sendInviteLoading }] = useMutation<
    ISendFranchiseeInviteData,
    ISendFranchiseeInviteVariables
  >(SEND_FRANCHISEE_INVITE)

  const { enqueueSnackbar } = useSnackbar()

  const { franchisee, loading } = useFranchiseeDetail(Number(match.params.id))
  const { loading: resetPasswordInfoLoading, data: resetPasswordInfo } = useResetPasswordInfo(franchisee?.user.id)

  const toggleComissionModal = () => {
    setComissionModalVisible(prev => !prev)
  }

  const toggleProcessingModal = () => {
    setProcessingModalVisible(prev => !prev)
  }

  const toggleUserModal = () => {
    setUserModalVisible(prev => !prev)
  }

  const goToBalance = () => {
    history.push(`/franchisee/${franchisee!.id}/balance`)
  }

  if (loading) {
    return <Loader />
  }

  if (!franchisee) {
    return null
  }

  return (
    <div>
      <PageHeader title='Франчайзи' parentPath={'/franchisee'} />
      <Box mb={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Content noGutter>
              <Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
                <Typography variant='subtitle1'>
                  {franchisee.name} {franchisee.type === 1 ? '(Физ лицо)' : '(Юр лицо)'}
                </Typography>
                <IconButton size='small' onClick={toggleUserModal}>
                  <SettingsIcon />
                </IconButton>
              </Box>
              <Divider />
              <Box p={2}>
                <Typography>{maskPhone(franchisee.user.phone)}</Typography>
                <Typography>{franchisee.email}</Typography>
                <Typography>{franchisee.city.name}</Typography>
              </Box>
            </Content>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SingleValueWidget
              label='Баланс'
              value={`${franchisee.balance}`}
              Icon={BalanceIcon}
              onClick={goToBalance}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SingleValueWidget label='Партнеры' value={`0`} Icon={BusinessesIcon} onClick={() => null} />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={6}>
            <Content noGutter>
              <Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
                <Typography variant='subtitle1'>Комиссии</Typography>
                <IconButton size='small' onClick={toggleComissionModal}>
                  <SettingsIcon />
                </IconButton>
              </Box>
              <Divider />
              <Box p={2} display='flex' justifyContent='space-between'>
                <Box>
                  <Typography variant='body2' color='textSecondary'>
                    Комиссия PAYDA
                  </Typography>
                  <Typography variant='subtitle2'>
                    {franchisee.settings.commission}
                    {franchisee.settings.commissionType === 1 ? '%' : '₸'}
                  </Typography>
                </Box>
                <Box textAlign='right'>
                  <Typography variant='body2' color='textSecondary'>
                    Мин комиссия
                  </Typography>
                  <Typography variant='subtitle2'>{franchisee.settings.commissionMin}₸</Typography>
                </Box>
              </Box>
              <Divider />
              <Box p={2}>
                <Typography gutterBottom variant='subtitle1'>
                  Комиссии партнеров
                </Typography>
                <Box display='flex' justifyContent='space-between'>
                  <Box>
                    <Typography variant='body2' color='textSecondary'>
                      Процент
                    </Typography>
                    <Typography variant='subtitle2'>
                      {franchisee.settings.businessCommission.percent.min}% -{' '}
                      {franchisee.settings.businessCommission.percent.max}%
                    </Typography>
                  </Box>
                  <Box textAlign='right'>
                    <Typography variant='body2' color='textSecondary'>
                      Фиксировано
                    </Typography>
                    <Typography variant='subtitle2'>
                      {franchisee.settings.businessCommission.fixed.min}₸ -{' '}
                      {franchisee.settings.businessCommission.fixed.max}₸
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Content>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Content noGutter>
              <Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
                <Typography variant='subtitle1'>Процессинг</Typography>
                <IconButton size='small' onClick={toggleProcessingModal}>
                  <SettingsIcon />
                </IconButton>
              </Box>
              <Divider />
              <Box p={2}>
                <Box mb={1}>
                  <Typography variant='body2' color='textSecondary'>
                    Платежная система
                  </Typography>
                  {/* TODO */}
                  <Typography>PayBox</Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant='body2' color='textSecondary'>
                    API URL
                  </Typography>
                  <Typography>{franchisee.settings.processing.apiUrl}</Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant='body2' color='textSecondary'>
                    Merchant ID
                  </Typography>
                  <Typography>{franchisee.settings.processing.merchantId}</Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant='body2' color='textSecondary'>
                    SECRET KEY
                  </Typography>
                  <Typography>{franchisee.settings.processing.secretKey}</Typography>
                </Box>
              </Box>
            </Content>
          </Grid>
        </Grid>
      </Box>
      {(!resetPasswordInfo?.isActivated ||
        moment()
          .add(5, 'minutes')
          .isBefore(resetPasswordInfo.updatedAt)) && (
        <Box my={2}>
          <Content>
            <Alert
              color={resetPasswordInfo?.isActivated ? 'success' : resetPasswordInfo ? 'warning' : 'error'}
              icon={resetPasswordInfo && <></>}
              variant={'outlined'}
              action={
                <LoadableButton
                  color='inherit'
                  loading={sendInviteLoading}
                  onClick={async () => {
                    try {
                      await sendFranchiseeInvite({
                        variables: {
                          email: franchisee.email,
                        },
                      })
                      enqueueSnackbar('Приглашение отправлено', { variant: 'success' })
                    } catch (error) {
                      enqueueSnackbar(error.message, { variant: 'error' })
                    }
                  }}
                >
                  {resetPasswordInfo?.isActivated ? 'Сбросить пароль' : 'Отправить повторно'}
                </LoadableButton>
              }
            >
              {resetPasswordInfoLoading && <CircularProgress color={'inherit'} />}
              {resetPasswordInfo ? (
                <AlertTitle>
                  {resetPasswordInfo.isActivated
                    ? 'Пароль был создан пользователем'
                    : 'Было отправлено приглашение на изменения пароля'}
                </AlertTitle>
              ) : (
                'При отправке приглашения на изменения пароля произошла ошибка'
              )}
              {resetPasswordInfo?.updatedAt && moment(resetPasswordInfo.updatedAt).format('DD-MM-YYYY HH:mm')}
            </Alert>
          </Content>
        </Box>
      )}
      {comissionsModalVisible && (
        <FranchiseeCommissionsModal franchisee={franchisee} open onCancel={toggleComissionModal} />
      )}
      {processingModalVisible && (
        <FranchiseeProcessingModal franchisee={franchisee} open onCancel={toggleProcessingModal} />
      )}
      {userModalVisible && <FranchiseeUserModal franchisee={franchisee} open onCancel={toggleUserModal} />}
    </div>
  )
}

export default FranchiseeDetail
