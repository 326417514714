import React from 'react'
import { Box, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useMutation } from '@apollo/react-hooks'

import { FranchiseeAddBalanceForm, IFranchiseeAddBalanceFormValues } from '../Forms'
import {
  IFranchisee,
  IAddBalanceToFranchiseeData,
  IAddBalanceToFranchiseeInput,
  IAddBalanceToFranchiseeVariables,
} from '../../../graphql/types/franchisee'
import {
  ADD_BALANCE_TO_FRANCHISEE_MUTATION,
  FRANCHISEE_BALANCE_TRANSACTIONS_QUERY,
  FRANCHISEE_BY_ID_QUERY,
} from '../../../graphql/queries/franchisee'

interface IProps {
  franchisee: IFranchisee
  open: boolean
  onCancel: () => void
}

export const AddBalanceToFranchiseeModal: React.FC<IProps> = ({ franchisee, open, onCancel }) => {
  const [addBalance, { loading, error }] = useMutation<IAddBalanceToFranchiseeData, IAddBalanceToFranchiseeVariables>(
    ADD_BALANCE_TO_FRANCHISEE_MUTATION
  )

  const handleSubmit = async (values: IFranchiseeAddBalanceFormValues) => {
    const input: IAddBalanceToFranchiseeInput = {
      amount: Number(values.amount),
      replenishmentType: Number(values.replenishmentType),
    }

    await addBalance({
      variables: {
        franchiseeId: franchisee.id,
        input,
      },
      refetchQueries: [
        { query: FRANCHISEE_BALANCE_TRANSACTIONS_QUERY, variables: { franchiseeId: franchisee.id } },
        { query: FRANCHISEE_BY_ID_QUERY, variables: { id: franchisee.id } },
      ],
    })

    onCancel()
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth onClose={onCancel}>
      <DialogTitle id='form-dialog-title'>Пополнение баланса франчайзи</DialogTitle>
      <DialogContent>
        {!!error && (
          <Box mb={3}>
            <Alert severity='error'>{error}</Alert>
          </Box>
        )}
        <FranchiseeAddBalanceForm loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
      </DialogContent>
    </Dialog>
  )
}
