import React from 'react'
import { useQuery } from '@apollo/react-hooks'

import { BUSINESS_BALANCE_TRANSACTIONS_QUERY } from '../../queries/businesses'
import { IBusinessBalanceTransactionsData, IBusinessBalanceTransactionsVariables } from '../../types/businesses'
import { LIST_LIMIT } from '../../../core/constants'

export const useBusinessBalanceTransactionsQuery = (businessId: number) => {
  const [loadingMore, setLoadingMore] = React.useState<boolean>(false)

  const { data, loading, fetchMore } = useQuery<
    IBusinessBalanceTransactionsData,
    IBusinessBalanceTransactionsVariables
  >(BUSINESS_BALANCE_TRANSACTIONS_QUERY, {
    variables: {
      businessId,
      pagination: {
        offset: 0,
        limit: LIST_LIMIT,
      },
    },
  })

  const transactions = data?.businessBalanceTransactions.transactions || []
  const total = data?.businessBalanceTransactions.total || 0

  const handleFetchMore = async () => {
    try {
      setLoadingMore(true)

      await fetchMore({
        variables: {
          businessId,
          pagination: {
            offset: transactions.length,
            limit: LIST_LIMIT,
          },
        },
        updateQuery: (
          prev,
          { fetchMoreResult }: { fetchMoreResult?: IBusinessBalanceTransactionsData }
        ): IBusinessBalanceTransactionsData => {
          if (!fetchMoreResult || fetchMoreResult.businessBalanceTransactions.transactions.length === 0) {
            return prev
          }

          return {
            businessBalanceTransactions: {
              ...prev.businessBalanceTransactions,
              transactions: prev.businessBalanceTransactions.transactions.concat(
                fetchMoreResult.businessBalanceTransactions.transactions
              ),
            },
          }
        },
      })
    } catch (error) {
      console.log(error.message)
    } finally {
      setLoadingMore(false)
    }
  }

  return {
    transactions,
    total,
    loading,
    loadingMore,
    fetchMore: handleFetchMore,
  }
}
