import { useQuery } from '@apollo/react-hooks'

import { ALL_FRANCHISEE_QUERY } from '../../queries/franchisee'
import { IAllFranchiseeData, IAllFranchiseeVariables } from '../../types/franchisee'

export const useAllFranchisee = () => {
  const { data, ...query } = useQuery<IAllFranchiseeData, IAllFranchiseeVariables>(ALL_FRANCHISEE_QUERY)

  return {
    franchisee: data?.allFranchiseeByAdmin.franchisee || [],
    total: data?.allFranchiseeByAdmin.total || 0,
    ...query,
  }
}
