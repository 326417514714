import gql from 'graphql-tag'

export const SALES_SUPERVISORS_QUERY = gql`
  query GetSalesSupervisorsQuery {
    salesSupervisors {
      id
      fullName
      phone
      email
      roles
    }
  }
`

export const CREATE_SALES_SUPERVISOR_MUTATION = gql`
  mutation CreateSalesSupervisorMutation($input: SalesSupervisorInput!) {
    createSalesSupervisor(input: $input) {
      id
      fullName
      phone
      email
      roles
    }
  }
`

export const SALES_MANAGERS_QUERY = gql`
  query GetSalesManagersQuery($filter: FilterSalesManagerInput) {
    salesManagers(filter: $filter) {
      id
      user {
        id
        fullName
        phone
        email
        roles
      }
      franchisee {
        id
        name
      }
      promocodes {
        id
        title
        code
        discount
        discountType
        startDate
        endDate
        deactivated
        deactivatedAt
        deactivatedBy {
          id
          phone
          fullName
        }
      }
      businessCount
    }
  }
`

export const SALES_MANAGER_BY_ID_QUERY = gql`
  query GetSalesManagerByIdQuery($id: Int!) {
    salesManagerById(id: $id) {
      id
      user {
        id
        fullName
        phone
        email
        roles
      }
      franchisee {
        id
        name
      }
      promocodes {
        id
        title
        code
        discount
        discountType
        startDate
        endDate
        deactivated
        deactivatedAt
        deactivatedBy {
          id
          phone
          fullName
        }
      }
      businessCount
    }
    allBusinesses(filter: { salesManagerId: $id }) {
      id
      companyName
      cashbackPercent
      transactionFee
      transactionFeeType
      isVisible
      image {
        id
        url
      }
      country {
        id
        name
        numberCode
        code
        currencyCode
        currencySymbol
        minTransactionFee
        registrationPrice
      }
    }
  }
`

export const CREATE_SALES_MANAGER_MUTATION = gql`
  mutation CreateSalesManagerMutation($input: SalesManagerInput!) {
    createSalesManager(input: $input) {
      id
      user {
        id
        fullName
        phone
        email
        roles
      }
      promocodes {
        id
        title
        code
        discount
        discountType
        startDate
        endDate
        deactivated
        deactivatedAt
        deactivatedBy {
          id
          phone
          fullName
        }
      }
      businessCount
    }
  }
`

export const UPDATE_SALES_MANAGER_MUTATION = gql`
  mutation UpdateSalesManagerMutation($id: Int!, $input: SalesManagerInput!) {
    updateSalesManager(id: $id, input: $input) {
      id
      user {
        id
        fullName
        phone
        email
        roles
      }
      promocodes {
        id
        title
        code
        discount
        discountType
        startDate
        endDate
      }
      businessCount
    }
  }
`

export const DELETE_SALES_MANAGER_MUTATION = gql`
  mutation DeleteSalesManagerMutation($id: Int!) {
    deleteSalesManager(id: $id)
  }
`
