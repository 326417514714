import React from 'react'
import { IconButton, ButtonBaseProps } from '@material-ui/core'
import { useConfirm, Loader } from '../../../components/UI'
import { useSnackbar } from 'notistack'
import { IDeleteFaqData, IDeleteFaqVariables, IFaq } from '../../../graphql/types/faq'
import { useMutation } from '@apollo/react-hooks'
import { DELETE_FAQ_MUTATION, FAQ_GROUP_BY_ID_QUERY } from '../../../graphql/queries/faq'

interface IProps extends ButtonBaseProps {
  component?: React.ElementType
  item: IFaq
  groupId: number
}

const DeleteFaqButton: React.FC<IProps> = ({
  item,
  groupId,
  component: Component = IconButton,
  children,
  ...props
}) => {
  const confirm = useConfirm()
  const { enqueueSnackbar } = useSnackbar()
  const [deleteFaq, { loading }] = useMutation<IDeleteFaqData, IDeleteFaqVariables>(DELETE_FAQ_MUTATION, {
    variables: { id: item.id },
    refetchQueries: [{ query: FAQ_GROUP_BY_ID_QUERY, variables: { id: groupId } }],
  })

  const handleDelete = async () => {
    try {
      await confirm({ message: 'Вы хотите удалить?' })
    } catch {
      return
    }

    try {
      await deleteFaq()
    } catch (error) {
      enqueueSnackbar(error.message)
    }
  }

  return (
    <>
      <Component {...props} onClick={handleDelete}>
        {loading ? <Loader size={18} noGutter /> : children}
      </Component>
    </>
  )
}

export default DeleteFaqButton
