import gql from 'graphql-tag'

export const FRANCHISE_FRAGMENT = gql`
  fragment FranchiseeFragment on Franchisee {
    id
    name
    email
    type
    balance
    createdAt
    updatedAt
    settings {
      id
      commission
      commissionType
      commissionMin
      processing
      businessCommission {
        percent {
          min
          max
        }
        fixed {
          min
          max
        }
      }
    }
    user {
      id
      fullName
      phone
    }
    city {
      id
      name
    }
  }
`

export const ALL_FRANCHISEE_QUERY = gql`
  query AllFranchiseeQuery($pagination: PaginationInput, $filter: AllFranchiseeFilterInput) {
    allFranchiseeByAdmin(pagination: $pagination, filter: $filter) {
      franchisee {
        ...FranchiseeFragment
      }
      total
    }
  }
  ${FRANCHISE_FRAGMENT}
`

export const FRANCHISEE_BY_ID_QUERY = gql`
  query FranchiseeByIdQuery($id: Int!) {
    getFranchiseeByIdAdmin(id: $id) {
      ...FranchiseeFragment
    }
  }
  ${FRANCHISE_FRAGMENT}
`

export const VALIDATE_FRANCHISEE_USER_MUTATION = gql`
  mutation ValidateFranchiseeUserMutation($input: ValidateFranchiseeUserInput!) {
    validateFranchiseeUser(input: $input)
  }
`

export const CREATE_FRANCHISEE_MUTATION = gql`
  mutation CreateFranchiseeMutation($input: FranchiseeRegisterDataInput!) {
    createFranchiseeByAdmin(input: $input) {
      ...FranchiseeFragment
    }
  }
  ${FRANCHISE_FRAGMENT}
`

export const UPDATE_FRANCHISEE_PROFILE_MUTATION = gql`
  mutation UpdateFranchiseeProfileMutation($franchiseeId: Int!, $input: FranchiseeProfileInput!) {
    updateFranchiseeProfileByAdmin(franchiseeId: $franchiseeId, input: $input) {
      ...FranchiseeFragment
    }
  }
  ${FRANCHISE_FRAGMENT}
`

export const UPDATE_FRANCHISEE_COMMISSION_MUTATION = gql`
  mutation UpdateFranchiseeCommissionMutation($franchiseeId: Int!, $input: FranchiseeCommissionInput!) {
    updateFranchiseeCommissionByAdmin(franchiseeId: $franchiseeId, input: $input) {
      ...FranchiseeFragment
    }
  }
  ${FRANCHISE_FRAGMENT}
`

export const UPDATE_FRANCHISEE_PROCESSING_MUTATION = gql`
  mutation UpdateFranchiseeProcessingMutation($franchiseeId: Int!, $input: JSON!) {
    updateFranchiseeProcessingByAdmin(franchiseeId: $franchiseeId, input: $input) {
      ...FranchiseeFragment
    }
  }
  ${FRANCHISE_FRAGMENT}
`

export const UPDATE_BUSINESS_FRANCHISEE_MUTATION = gql`
  mutation UpdateBusinessFranchiseeMutation($businessId: Int!, $franchiseeId: Int) {
    updateBusinessFranchisee(businessId: $businessId, franchiseeId: $franchiseeId)
  }
`

export const FRANCHISEE_BALANCE_TRANSACTIONS_QUERY = gql`
  query FranchiseeBalanceTransactionsQuery($franchiseeId: Int!, $pagination: PaginationInput) {
    franchiseeBalanceTransactionsForAdmin(franchiseeId: $franchiseeId, pagination: $pagination) {
      transactions {
        id
        amount
        direction
        type
        replenishmentType
        comment
        status
        businessTransaction {
          id
          amount
          business {
            id
            companyName
          }
        }
        executedBy {
          id
          fullName
        }
        createdAt
        updatedAt
        commission
        commissionType
        psPaymentId
        paymentDate
        description
        error
        errorCode
      }
      total
    }
  }
`

export const ADD_BALANCE_TO_FRANCHISEE_MUTATION = gql`
  mutation AddBalanceToFranchiseeMutation($franchiseeId: Int!, $input: AddBalanceToFranchiseeInput!) {
    addBalanceToFranchisee(franchiseeId: $franchiseeId, input: $input)
  }
`

export const SEND_FRANCHISEE_INVITE = gql`
  mutation SendFranchiseeInviteEmail($email: String!) {
    sendFranchiseeInviteEmail(email: $email)
  }
`
