import { useMutation } from '@apollo/react-hooks'

import { UPSERT_CITY_MUTATION, COUNTRY_DETAIL_QUERY } from '../../queries/localization'
import { IUpsertCityData, IUpsertCityVariables, IUpsertCityInput } from '../../types/localization'

export const useUpsertCity = (countryId: number) => {
  const [upsertCityMutation, { loading, error }] = useMutation<IUpsertCityData, IUpsertCityVariables>(
    UPSERT_CITY_MUTATION
  )

  const upsertCity = async (input: IUpsertCityInput) => {
    try {
      await upsertCityMutation({
        variables: { input },
        refetchQueries: [{ query: COUNTRY_DETAIL_QUERY, variables: { id: countryId } }],
      })
    } catch (error) {
      console.log(error)
    }
  }

  return {
    loading,
    error,
    upsertCity,
  }
}
