import * as yup from 'yup'

import { ApplicationType } from '../../../core/interfaces'

export const faqGroupSchema = yup.object().shape({
  titles: yup
    .array()
    .of(
      yup.object().shape({
        code: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .min(1),
  applicationType: yup
    .mixed()
    .oneOf([ApplicationType.BUSINESS, ApplicationType.CLIENT])
    .required(),
})

export const faqSchema = yup.object().shape({
  i18nQuestion: yup
    .array()
    .of(
      yup.object().shape({
        code: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .min(1),
  i18nAnswer: yup
    .array()
    .of(
      yup.object().shape({
        code: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .min(1),
})
