import React, { ReactElement } from 'react'
import { Box, Card, CardContent, IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import { IFaq } from '../../../graphql/types/faq'
import DeleteFaqButton from './DeleteFaqButton'
import FaqEditorModalButton from './FaqEditorModalButton'
import { I18nTypography } from '../../../components/UI'

interface IProps {
  item: IFaq
  groupId: number
}

export default function FaqCardItem({ groupId, item }: IProps): ReactElement {
  return (
    <Box my={2}>
      <Card elevation={0}>
        <CardContent>
          <Box mb={1} display='flex' alignItems='flex-start'>
            <Box flexGrow={1}>
              <I18nTypography variant='subtitle1' value={item.i18nQuestion} fallback={item.question} />
            </Box>
            <Box>
              <Box component='span' mx={1}>
                <FaqEditorModalButton groupId={groupId} item={item} component={IconButton} color='primary'>
                  <EditIcon />
                </FaqEditorModalButton>
              </Box>
              <Box component='span' color='error.main'>
                <DeleteFaqButton groupId={groupId} item={item} color='inherit'>
                  <DeleteIcon />
                </DeleteFaqButton>
              </Box>
            </Box>
          </Box>
          <Box>
            <I18nTypography variant='body2' value={item.i18nAnswer} fallback={item.ask} />
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
