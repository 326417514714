import React from 'react'
import { List } from '@material-ui/core'

import { FaqDataListItem } from './FaqDataListItem'
import { IFaqGroup } from '../../../graphql/types/faq'

type TitleFunctionType = (item: IFaqGroup) => string | React.ReactNode
type DataListElement = (p: IProps) => React.ReactElement<IProps>

interface IProps {
  data: IFaqGroup[]
  title: TitleFunctionType
  secondaryText?: (item: IFaqGroup) => string | React.ReactElement | undefined
  renderAvatar?: (item: IFaqGroup) => React.ReactElement
  onClickItem?: (item: IFaqGroup) => void
}

export const FaqDataList: DataListElement = ({ data, title, secondaryText, renderAvatar, onClickItem }) => {
  return (
    <div>
      <List>
        {data.map((item, index) => {
          const itemTitle = title(item)
          const avatar = renderAvatar && renderAvatar(item)

          return (
            <FaqDataListItem
              key={`item-${index}`}
              item={item}
              title={itemTitle}
              secondaryText={secondaryText && secondaryText(item)}
              avatar={avatar}
              onClick={() => onClickItem && onClickItem(item)}
            />
          )
        })}
      </List>
    </div>
  )
}
