import { useApolloClient } from '@apollo/react-hooks'
import React from 'react'
import { RESET_PASSWORD_INFO_QUERY } from '../../queries/auth'

import { IResetPasswordInfoData, IResetPasswordInfoResponse, IResetPasswordInfoVariables } from '../../types/auth'

export interface IUseResetPasswordInfoState {
  data?: IResetPasswordInfoResponse
  loading: boolean
}

export const useResetPasswordInfo = (userId: number | undefined | null) => {
  const client = useApolloClient()
  const [state, setState] = React.useState<IUseResetPasswordInfoState>({
    loading: true,
  })

  const fetch = React.useCallback(
    async (id: number | undefined | null) => {
      if (id || userId) {
        try {
          const { data } = await client.query<IResetPasswordInfoData, IResetPasswordInfoVariables>({
            query: RESET_PASSWORD_INFO_QUERY,
            variables: { userId: id ?? userId! },
          })
          setState({ loading: false, data: data?.resetPasswordInfoByUserId })
        } catch (error) {
          setState({ loading: false, data: undefined })
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId]
  )

  React.useEffect(() => {
    fetch(undefined)
  }, [fetch])

  return { ...state, fetch }
}
