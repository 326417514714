import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as yup from 'yup'
import { useMutation } from '@apollo/react-hooks'

import { UPDATE_BUSINESS_TRANSACTION_FEE_MUTATION, BUSINESS_DETAIL_QUERY } from '../../../graphql/queries/businesses'
import {
  IUpdateBusinessTransactionFeeData,
  IUpdateBusinessTransactionFeeVariables,
} from '../../../graphql/types/businesses'
import { TransactionFeeType } from '../../../core/interfaces'
import { IBusiness } from '../../../graphql/types/businesses'
import { LoadableButton } from '../../../components/Form'
import { TransactionFeeTypeSelect } from '../../../components/Form'

interface IProps {
  business: IBusiness
  open: boolean
  onClose: () => void
}

interface ITransactionFeeFormValues {
  type: TransactionFeeType
  amount: string
  minAmount: string
}

const validationSchema = yup.object().shape({
  type: yup
    .mixed()
    .oneOf([TransactionFeeType.PERCENT, TransactionFeeType.FIX])
    .required(),
  amount: yup
    .number()
    .moreThan(0)
    .positive()
    .when('type', {
      is: TransactionFeeType.PERCENT,
      then: yup.number().max(100),
    })
    .required(),
  minAmount: yup
    .number()
    .moreThan(0)
    .nullable(),
})

export const TransactionFeeModal: React.FC<IProps> = ({ business, open, onClose }) => {
  const initialValues: ITransactionFeeFormValues = {
    amount: `${business.transactionFee}`,
    minAmount: `${business.minTransactionFee || ''}`,
    type: business.transactionFeeType,
  }

  const [loading, setLoading] = React.useState<boolean>(false)
  const [updateTransactionFee] = useMutation<IUpdateBusinessTransactionFeeData, IUpdateBusinessTransactionFeeVariables>(
    UPDATE_BUSINESS_TRANSACTION_FEE_MUTATION
  )

  const handleSubmit = async (values: ITransactionFeeFormValues) => {
    try {
      setLoading(true)
      const { data } = await updateTransactionFee({
        variables: {
          businessId: business.id,
          input: {
            amount: parseFloat(values.amount),
            type: values.type,
            minAmount: values.minAmount ? parseFloat(values.minAmount) : undefined,
          },
        },
        refetchQueries: [{ query: BUSINESS_DETAIL_QUERY, variables: { id: business.id } }],
      })

      if (data?.updateBusinessTransactionFee) {
        onClose()
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const DefaulMinTransactionFee = () => (
    <Typography variant='caption'>
      По умолчанию для всех {business.country.minTransactionFee}
      {business.country.currencySymbol}
    </Typography>
  )

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, errors, setFieldValue, handleChange, submitForm }: FormikProps<ITransactionFeeFormValues>) => (
        <Dialog open={open} maxWidth='sm' fullWidth aria-labelledby='form-dialog-title'>
          <DialogTitle id='form-dialog-title'>Комиссия за транзакцию</DialogTitle>
          <DialogContent>
            <TransactionFeeTypeSelect value={values.type} onChange={type => setFieldValue('type', type)} />
            <TextField
              label='Комиссия'
              name='amount'
              value={values.amount}
              onChange={handleChange}
              required
              fullWidth
              margin='normal'
              error={!!errors.amount}
              type='number'
            />
            <TextField
              label={`Минимальная комиссия (${business.country.currencySymbol}) для этого партнера`}
              name='minAmount'
              value={values.minAmount}
              onChange={handleChange}
              fullWidth
              margin='normal'
              error={!!errors.minAmount}
              type='number'
              helperText={<DefaulMinTransactionFee />}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color='default'>
              Закрыть
            </Button>
            <LoadableButton loading={loading} color='primary' onClick={submitForm}>
              Применить
            </LoadableButton>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}
