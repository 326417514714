import * as yup from 'yup'

import { PHONE_REGEXP } from '../../../core/constants'
import { FranchiseeReplenishmentType } from '../../../core/interfaces'

export const userSchema = yup.object().shape({
  name: yup.string().required(),
  phone: yup
    .string()
    .trim()
    .matches(PHONE_REGEXP, 'Не правильный формат телефона')
    .required(),
  email: yup
    .string()
    .email()
    .required(),
  cityId: yup.number().required(),
})

export const commissionsSchema = yup.object().shape({
  commission: yup
    .number()
    .moreThan(0)
    .positive()
    .when('commissionType', {
      is: 1,
      then: yup.number().max(100),
    })
    .required(),
  commissionType: yup.number().required(),
  commissionMin: yup.number().required(),
  businessCommissionPercentMin: yup.number().required(),
  businessCommissionPercentMax: yup.number().required(),
  businessCommissionFixedMin: yup.number().required(),
  businessCommissionFixedMax: yup.number().required(),
})

export const processingSchema = yup.object().shape({
  paymentSystemId: yup.number().required(),
  merchantId: yup.number().required(),
  apiUrl: yup.string().required(),
  secretKey: yup.string().required(),
})

export const addBalanceSchema = yup.object().shape({
  amount: yup
    .number()
    .moreThan(0)
    .required(),
  replenishmentType: yup
    .number()
    .oneOf([FranchiseeReplenishmentType.CASH, FranchiseeReplenishmentType.CASHLESS])
    .required(),
})
