import React from 'react'
import { ISalesManager } from '../../../../graphql/types/user'
import { maskPhone } from '../../../../utils/helpers'
import { PromocodeChip } from './PromocodeChip'
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core'
import { EditManagerModal } from './EditManagerModal'

interface IProps {
  manager: ISalesManager
}

export const ManagerInfo: React.FC<IProps> = ({ manager }) => {
  const [openEdit, setOpenEdit] = React.useState<boolean>(false)

  const handleToggle = () => {
    setOpenEdit(!openEdit)
  }

  console.log(manager.franchisee)

  return (
    <div>
      <Box mb={2}>
        <Grid container spacing={3} alignItems='center'>
          <Grid item xs>
            <Typography>{manager.user.fullName}</Typography>
            <Typography color='textSecondary'>{maskPhone(manager.user.phone)}</Typography>
            {!!manager.franchisee && (
              <Typography color='textSecondary'>Франчайзи: {manager.franchisee.name}</Typography>
            )}
          </Grid>
          <Grid item>
            <Button variant='outlined' onClick={handleToggle}>
              Изменить
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box my={2} display='flex' flexWrap='wrap'>
        {manager.promocodes.map(promocode => (
          <PromocodeChip
            key={`promocode-${promocode.id}`}
            disabled={!!promocode.deactivated}
            color='primary'
            promocode={promocode}
          />
        ))}
      </Box>
      <EditManagerModal manager={manager} open={openEdit} onCancel={handleToggle} />
    </div>
  )
}
