import React from 'react'
import { Box } from '@material-ui/core'
import { CheckCircleOutlined as ActivatedIcon, HighlightOffOutlined as OffIcon } from '@material-ui/icons'
import { green } from '@material-ui/core/colors'

import { IBusinessApiCredential } from '../../../graphql/types/external-api'

interface IProps {
  item: IBusinessApiCredential
}

export const IsActiveColumn: React.FC<IProps> = ({ item }) => {
  return item.isActive ? (
    <Box color={green[500]}>
      <ActivatedIcon color='inherit' />
    </Box>
  ) : (
    <OffIcon color='disabled' />
  )
}
