import React from 'react'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Grid,
} from '@material-ui/core'
import { Formik, FormikProps } from 'formik'

import { commissionsSchema } from './validation'
import { IFranchisee } from '../../../graphql/types/franchisee'

interface IProps {
  franchisee?: IFranchisee
  submitButtonLabel?: string
  onSubmit: (values: IFranchiseeCommissionsFormValues) => void
  onCancel: () => void
}

export interface IFranchiseeCommissionsFormValues {
  commission: string
  commissionType: string
  commissionMin: string
  businessCommissionPercentMin: string
  businessCommissionPercentMax: string
  businessCommissionFixedMin: string
  businessCommissionFixedMax: string
}

export const FranchiseeCommissionsForm: React.FC<IProps> = ({
  franchisee,
  onSubmit,
  onCancel,
  submitButtonLabel = 'Сохранить',
}) => {
  const initialValues: IFranchiseeCommissionsFormValues = {
    commissionType: franchisee?.settings.commissionType.toString() || '1',
    commission: franchisee?.settings.commission.toString() || '',
    commissionMin: franchisee?.settings.commissionMin.toString() || '',
    businessCommissionPercentMin: franchisee?.settings.businessCommission.percent.min.toString() || '',
    businessCommissionPercentMax: franchisee?.settings.businessCommission.percent.max.toString() || '',
    businessCommissionFixedMin: franchisee?.settings.businessCommission.fixed.min.toString() || '',
    businessCommissionFixedMax: franchisee?.settings.businessCommission.fixed.max.toString() || '',
  }

  const handleSubmit = (values: IFranchiseeCommissionsFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={commissionsSchema} onSubmit={handleSubmit}>
      {({ values, errors, handleChange, submitForm }: FormikProps<IFranchiseeCommissionsFormValues>) => (
        <div>
          <Box mt={3}>
            <FormControl component='fieldset'>
              <RadioGroup
                row
                aria-label='franchisee-commission-type'
                name='commissionType'
                value={values.commissionType}
                onChange={handleChange}
              >
                <FormControlLabel value={'1'} control={<Radio />} label='Процент' />
                <FormControlLabel value={'2'} control={<Radio />} label='Фиксировано' />
              </RadioGroup>
            </FormControl>
            <TextField
              label='Комиссия'
              name='commission'
              value={values.commission}
              onChange={handleChange}
              required
              fullWidth
              margin='normal'
              error={!!errors.commission}
              type='number'
            />
            <TextField
              label={`Минимальная комиссия`}
              name='commissionMin'
              value={values.commissionMin}
              onChange={handleChange}
              fullWidth
              margin='normal'
              error={!!errors.commissionMin}
              type='number'
            />
          </Box>
          <Box mt={3}>
            <Typography variant='h6'>Комиссии партнеров</Typography>

            <Grid container spacing={2} alignItems='flex-end'>
              <Grid item xs={12} sm={6}>
                <Typography gutterBottom>Процент</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  label='Мин комиссия'
                  name='businessCommissionPercentMin'
                  value={values.businessCommissionPercentMin}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin='normal'
                  error={!!errors.businessCommissionPercentMin}
                  type='number'
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  label={`Макс комиссия`}
                  name='businessCommissionPercentMax'
                  value={values.businessCommissionPercentMax}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.businessCommissionPercentMax}
                  type='number'
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems='flex-end'>
              <Grid item xs={12} sm={6}>
                <Typography gutterBottom>Фиксировано</Typography>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  label='Мин комиссия'
                  name='businessCommissionFixedMin'
                  value={values.businessCommissionFixedMin}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin='normal'
                  error={!!errors.businessCommissionFixedMin}
                  type='number'
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  label={`Макс комиссия`}
                  name='businessCommissionFixedMax'
                  value={values.businessCommissionFixedMax}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.businessCommissionFixedMax}
                  type='number'
                />
              </Grid>
            </Grid>
          </Box>
          <Box pt={4} pb={1} display='flex' flexDirection='row' justifyContent='space-between'>
            <Button onClick={onCancel}>Назад</Button>
            <Button color='primary' variant='contained' onClick={submitForm}>
              {submitButtonLabel}
            </Button>
          </Box>
        </div>
      )}
    </Formik>
  )
}
