import React, { Fragment, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Box, Divider, Typography } from '@material-ui/core'

import { PageHeader, Content } from '../../components/Layout'
import { CreateFab, Loader } from '../../components/UI'
import { DataTable, IDataTableColumn, EmptyDataMessage } from '../../components/Data'
import { IFranchiseeTransaction } from '../../graphql/types/franchisee'
import { useFranchiseeBalanceTransactions, useFranchiseeDetail } from '../../graphql/hooks/franchisee'
import PageNotFound from '../PageNotFound'
import { AddBalanceToFranchiseeModal } from './components'
import { AmountColumn, BalanceTransactionStatus, BusinessColumn, CreatedAtColumn, TypeColumn } from './balanceColumns'

interface IProps extends RouteComponentProps<{ id: string }> {}

const columns: IDataTableColumn<IFranchiseeTransaction>[] = [
  { label: 'ID', accessor: 'id' },
  { label: 'Сумма', accessor: item => <AmountColumn item={item} currency={'₸'} /> },
  { label: 'Статус', accessor: item => <BalanceTransactionStatus item={item} /> },
  {
    label: 'Тип',
    accessor: item => <TypeColumn item={item} />,
  },
  {
    label: 'Бизнес',
    accessor: item => <BusinessColumn item={item} currency={'₸'} />,
  },
  {
    label: 'Дата',
    accessor: item => <CreatedAtColumn item={item} />,
  },
]

const FranchiseeBalance: React.FC<IProps> = ({ match }) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const franchiseeId = parseInt(match.params.id, 10)
  const { franchisee, loading: franchiseeLoading } = useFranchiseeDetail(franchiseeId)
  const { transactions, total, loading } = useFranchiseeBalanceTransactions(franchiseeId)

  if (franchiseeLoading) {
    return <Loader />
  }

  if (!franchisee) {
    return <PageNotFound />
  }

  const toggleAddBalanceModal = () => {
    setModalVisible(prev => !prev)
  }

  return (
    <div>
      <PageHeader title={`${franchisee.name} - Баланс`} back />
      <Content noGutter>
        <Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
          <Typography>Всего: {total}</Typography>
          <Typography variant='h6'>{franchisee.balance}₸</Typography>
        </Box>
        <Divider />
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            {transactions.length > 0 ? <DataTable data={transactions} columns={columns} /> : <EmptyDataMessage />}
          </Fragment>
        )}
      </Content>
      <CreateFab onClick={toggleAddBalanceModal} />
      {modalVisible && <AddBalanceToFranchiseeModal franchisee={franchisee} open onCancel={toggleAddBalanceModal} />}
    </div>
  )
}

export default FranchiseeBalance
