import React from 'react'
import { ButtonBaseProps, IconButton } from '@material-ui/core'
import { useConfirm, Loader } from '../../../components/UI'
import { useMutation } from '@apollo/react-hooks'
import { DELETE_FAQ_GROUP_MUTATION, ALL_FAQ_GROUP_QUERY } from '../../../graphql/queries/faq'
import { IDeleteFaqGroupData, IDeleteFaqGroupVariables, IFaqGroup } from '../../../graphql/types/faq'
import { useSnackbar } from 'notistack'

interface IProps extends ButtonBaseProps {
  component?: React.ElementType
  item: IFaqGroup
}

export default function DeleteFaqGroupButton({ item, component: Component = IconButton, children, ...props }: IProps) {
  const confirm = useConfirm()
  const { enqueueSnackbar } = useSnackbar()
  const [deleteFaqGroup, { loading }] = useMutation<IDeleteFaqGroupData, IDeleteFaqGroupVariables>(
    DELETE_FAQ_GROUP_MUTATION,
    {
      variables: { id: item.id },
      refetchQueries: [{ query: ALL_FAQ_GROUP_QUERY, variables: { applicationType: item.applicationType } }],
    }
  )

  const handleDelete = async () => {
    try {
      await confirm({ message: 'Вы хотите удалить?' })
    } catch {
      return
    }

    try {
      await deleteFaqGroup()
    } catch (error) {
      enqueueSnackbar(error.message)
    }
  }

  return (
    <>
      <Component {...props} onClick={handleDelete}>
        {loading ? <Loader size={18} noGutter /> : children}
      </Component>
    </>
  )
}
