import React from 'react'
import isDeepEqual from 'fast-deep-equal/react'
import { RouteComponentProps } from 'react-router-dom'
import { Box, Link } from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'

import { PageHeader, Content } from '../../../components/Layout'
import { IBusinessShortest } from '../../../graphql/types/businesses'
import { All_BUSINESS_SUMMARY_REPORT_QUERY } from '../../../graphql/queries/report'
import { IDataTableColumn, DataTable, EmptyDataMessage } from '../../../components/Data'
import { Loader } from '../../../components/UI'
import Pagination from '../../../components/Data/pagination/Pagination'
import { ROWS_PER_PAGE } from '../../../core/constants'
import {
  IAllBusinessSummaryReportData,
  IAllBusinessSummaryReportVariables,
  IAllBusinessSummaryReportItem,
} from '../../../graphql/types/report'
import { useBusinessSummaryFilter } from './useBusinessSummaryFilter'
import { IPagination } from '../../../core/interfaces'
import { IFranchisee } from '../../../graphql/types/franchisee'

interface IProps extends RouteComponentProps<{}> {}

type Value<T, K extends keyof T> = T[K]

const BusinessSummaryReport: React.FC<IProps> = ({ match }) => {
  const { filter, renderFilter, handleChangeFilter } = useBusinessSummaryFilter()
  const [pagination, setPagination] = React.useState<IPagination>({ offset: 0, limit: ROWS_PER_PAGE[0], page: 0 })

  const filterRef = React.useRef(filter)

  if (!isDeepEqual(filterRef.current, filter)) {
    filterRef.current = filter
  }

  const { data, loading } = useQuery<IAllBusinessSummaryReportData, IAllBusinessSummaryReportVariables>(
    All_BUSINESS_SUMMARY_REPORT_QUERY,
    {
      variables: {
        input: {
          businessId: filter.business?.id,
          franchiseeId: filter.franchisee?.id,
          startDate: filter.startDate,
          endDate: filter.endDate,
        },
        pagination: {
          limit: pagination.limit,
          offset: pagination.offset,
        },
      },
    }
  )

  const items = data?.allBusinessSummaryReport.reportItems || []
  const total = data?.allBusinessSummaryReport.total || 0

  const handleChangePerPage = (limit: number) => {
    if (limit !== pagination.limit) {
      setPagination({ page: 0, offset: 0, limit })
    }
  }
  const handleChangePage = (page: number) => {
    setPagination({ ...pagination, offset: page * pagination.limit, page })
  }

  const columns: IDataTableColumn<IAllBusinessSummaryReportItem>[] = [
    {
      label: 'Партнер',
      stickyColumn: true,
      accessor: item => {
        const business: IBusinessShortest = {
          id: item.businessId,
          balance: item.balance,
          companyName: item.companyName,
        }

        const franchisee: Pick<IFranchisee, 'id' | 'name'> = { id: item.franchiseeId, name: item.franchiseeName }

        return (
          <Box>
            <Link
              href='#'
              onClick={(event: { preventDefault: () => void }) => {
                event.preventDefault()
                handleChangeFilter('business')(business)
              }}
            >
              {business.companyName}
            </Link>
            {franchisee && (
              <React.Fragment>
                <br />
                <Link
                  href='#'
                  onClick={(event: { preventDefault: () => void }) => {
                    event.preventDefault()
                    handleChangeFilter('franchisee')(franchisee)
                  }}
                >
                  {franchisee.name}
                </Link>
              </React.Fragment>
            )}
          </Box>
        )
      },
    },
    {
      label: 'Время последней транзакции',
      align: 'center',
      accessor: item => moment(item.lastTransactionAt).format('DD.MM.YYYY HH:mm'),
    },
    { label: 'Среднее количество транзакций в день', align: 'right', accessor: 'avgCountPerDay' },
    {
      label: 'Средний чек',
      accessor: 'avgAmount',
      align: 'right',
    },
    {
      label: 'Средняя ежедневная комиссия за 30 дней',
      accessor: 'avgTransactionFeePerMonth',
      align: 'right',
    },
    {
      label: 'Баланс',
      accessor: 'balance',
      align: 'right',
    },
    {
      label: 'Планированное завершение Баланса в днях',
      accessor: 'planingEndBalanceInDays',
      align: 'right',
    },
  ]

  React.useEffect(() => {
    setPagination({ ...pagination, page: 0, offset: 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRef.current])

  return (
    <div>
      <PageHeader title={`Сводная статистика по партнерам`} right={renderFilter()} />
      {loading && <Loader />}
      {items.length > 0 ? (
        <Content noGutter>
          <Pagination
            rowsPerPage={pagination.limit}
            rowsPerPageOptions={ROWS_PER_PAGE}
            onChangeRowsPerPage={handleChangePerPage}
            onChangePage={handleChangePage}
            page={pagination.page}
            count={total}
          />
          <DataTable data={items} size='small' stickyHeader topHeight={280} columns={columns} />
          <Pagination
            rowsPerPage={pagination.limit}
            rowsPerPageOptions={ROWS_PER_PAGE}
            onChangeRowsPerPage={handleChangePerPage}
            onChangePage={handleChangePage}
            page={pagination.page}
            count={total}
          />
        </Content>
      ) : (
        <EmptyDataMessage />
      )}
    </div>
  )
}

export default BusinessSummaryReport
