import React from 'react'
import { Typography } from '@material-ui/core'

import { IPromocode } from '../../../graphql/types/promocodes'

interface IProps {
  item: IPromocode
}

export const TitleColumn: React.FC<IProps> = ({ item }) => {
  return (
    <>
      <Typography color={item.deactivated ? 'textSecondary' : 'textPrimary'}>{`${item.title}`}</Typography>
      {item.deactivated && (
        <Typography variant='caption' color='textSecondary'>
          Деактивирован
        </Typography>
      )}
    </>
  )
}
