import React, { useState } from 'react'
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useMutation } from '@apollo/react-hooks'
import { useSnackbar } from 'notistack'

import { IBusiness } from '../../../graphql/types/businesses'
import { LoadableButton } from '../../../components/Form'
import { useAllFranchisee } from '../../../graphql/hooks/franchisee'
import {
  IFranchisee,
  IUpdateBusinessFranchiseeData,
  IUpdateBusinessFranchiseeVariables,
} from '../../../graphql/types/franchisee'
import { UPDATE_BUSINESS_FRANCHISEE_MUTATION } from '../../../graphql/queries/franchisee'
import { TransactionFeeType } from '../../../core/interfaces'
import { BUSINESS_DETAIL_QUERY } from '../../../graphql/queries/businesses'

interface IProps {
  business: IBusiness
  onClose: () => void
}

export const BusinessFranchiseeModal: React.FC<IProps> = ({ business, onClose }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { franchisee, loading } = useAllFranchisee()
  const [selectedFranchisee, setSelectedFranchisee] = useState(business.franchisee || null)
  const [updateFranchisee] = useMutation<IUpdateBusinessFranchiseeData, IUpdateBusinessFranchiseeVariables>(
    UPDATE_BUSINESS_FRANCHISEE_MUTATION
  )

  const handleChangeFranchisee = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.value === 'null') {
      setSelectedFranchisee(null)
      return
    }

    setSelectedFranchisee(franchisee.find(f => f.id === Number(target.value)) || null)
  }

  const handleSubmit = async () => {
    if (!commissionInRange(business, selectedFranchisee)) {
      return false
    }

    try {
      await updateFranchisee({
        variables: { businessId: business.id, franchiseeId: selectedFranchisee?.id || null },
        refetchQueries: [{ query: BUSINESS_DETAIL_QUERY, variables: { id: business.id } }],
      })

      enqueueSnackbar('Настройки сохранены успешно', { variant: 'success' })
      onClose()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Dialog open maxWidth='sm' fullWidth aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>Франчайзи</DialogTitle>
      <DialogContent>
        <TextField
          label='Франчайзи'
          name='franchiseeId'
          value={selectedFranchisee?.id || 'null'}
          onChange={handleChangeFranchisee}
          variant='outlined'
          size='small'
          margin='normal'
          fullWidth
          select
        >
          <MenuItem value={'null'}>Без франчайзи</MenuItem>
          {franchisee.map(fr => (
            <MenuItem key={`franchisee-select-${fr.id}`} value={fr.id}>
              {fr.name}
            </MenuItem>
          ))}
        </TextField>
        <Box mt={3}>
          <CommissionsComparison business={business} franchisee={selectedFranchisee} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='default'>
          Закрыть
        </Button>
        <LoadableButton loading={loading} color='primary' onClick={handleSubmit}>
          Применить
        </LoadableButton>
      </DialogActions>
    </Dialog>
  )
}

const commissionInRange = (business: IBusiness, franchisee: IFranchisee | null) => {
  if (!franchisee) {
    return true
  }

  const franchiseeCommissions = franchisee.settings.businessCommission

  if (business.transactionFeeType === TransactionFeeType.FIX) {
    return (
      business.transactionFee >= franchiseeCommissions.fixed.min &&
      business.transactionFee <= franchiseeCommissions.fixed.max
    )
  }

  if (business.transactionFeeType === TransactionFeeType.PERCENT) {
    return (
      business.transactionFee >= franchiseeCommissions.percent.min &&
      business.transactionFee <= franchiseeCommissions.percent.max
    )
  }
}

const CommissionsComparison: React.FC<{ business: IBusiness; franchisee: IFranchisee | null }> = ({
  business,
  franchisee,
}) => {
  const transactionFeeValue = () => {
    const suffix = business.transactionFeeType === TransactionFeeType.FIX ? ` ${business.country.currencySymbol}` : '%'

    return `${business.transactionFee}${suffix}`
  }

  const minTransactionFeeValue = () => {
    return business.minTransactionFee
      ? `Мин ${business.minTransactionFee}${business.country.currencySymbol}`
      : `Мин ${business.country.minTransactionFee}${business.country.currencySymbol}`
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6}>
        <Box>
          <Typography>Текущие настройки комиссии</Typography>
          <Typography variant='caption' component='p' color='textSecondary'>
            {transactionFeeValue()}
          </Typography>
          <Typography variant='caption' component='p' color='textSecondary'>
            {minTransactionFeeValue()}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box>
          <Typography>Настройки франчайзи</Typography>
          {franchisee && (
            <Box>
              <Typography variant='caption' component='p' color='textSecondary'>
                {franchisee.settings.businessCommission.percent.min}% -{' '}
                {franchisee.settings.businessCommission.percent.max}%
              </Typography>
              <Typography variant='caption' component='p' color='textSecondary'>
                {franchisee.settings.businessCommission.fixed.min}₸ - {franchisee.settings.businessCommission.fixed.max}
                ₸
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
      {!commissionInRange(business, franchisee) && (
        <Grid item xs={12}>
          <Box py={2}>
            <Alert severity='error'>Комиссия бизнеса не входит в диапазон разрешенный для франчайзи.</Alert>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}
