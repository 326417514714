import React from 'react'
import { Box, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useUpsertCity } from '../../../../graphql/hooks/localization'
import { IUpsertCityInput, ICity } from '../../../../graphql/types/localization'
import { CityForm, ICityFormValues } from './CityForm'

interface IProps {
  open: boolean
  countryId: number
  city?: ICity
  onCancel: () => void
}

export const CityFormModal: React.FC<IProps> = ({ open, countryId, city, onCancel }) => {
  const { loading, error, upsertCity } = useUpsertCity(countryId)

  const handleSubmit = async (values: ICityFormValues) => {
    const input: IUpsertCityInput = {
      id: city ? city.id : undefined,
      countryId,
      name: values.name,
      timezoneId: values.timezone?.id,
    }

    await upsertCity(input)
    onCancel()
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle id='form-dialog-title'>Город</DialogTitle>
      <DialogContent>
        {!!error && (
          <Box mb={2}>
            <Alert severity='error'>{error.message}</Alert>
          </Box>
        )}
        <Box>
          <CityForm city={city} loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
