import * as yup from 'yup'

export const importClientsSchema = yup.object().shape({
  feeType: yup
    .mixed()
    .oneOf(['default', 'custom'])
    .required(),
  feeAmount: yup
    .number()
    .moreThan(0)
    .positive()
    .when('feeType', {
      is: 'custom',
      then: yup
        .number()
        .positive()
        .required(),
    }),
  file: yup
    .mixed()
    .required()
    .test('fileFormat', 'CSV only', value => {
      return value && ['text/csv'].includes(value.type)
    }),
})
