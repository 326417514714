import React from 'react'
import isDeepEqual from 'fast-deep-equal/react'
import { RouteComponentProps } from 'react-router-dom'
import { PageHeader, Content } from '../../../components/Layout'
import {
  Box,
  makeStyles,
  Link,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
} from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import classnames from 'classnames'

import { IBusinessShortest } from '../../../graphql/types/businesses'
import {
  BUSINESS_TRANSACTION_FEES_BY_DAYS,
} from '../../../graphql/queries/report'
import { EmptyDataMessage } from '../../../components/Data'
import { Loader } from '../../../components/UI'
import Pagination from '../../../components/Data/pagination/Pagination'
import { ROWS_PER_PAGE } from '../../../core/constants'
import {
  IBusinessTransactionFeesByDaysData,
  IBusinessTransactionFeesByDaysVariables,
  IBusinessTransactionFeesByDayItem,
  IBusinessTransactionFeesByDay,
} from '../../../graphql/types/report'
import { useBusinessTransactionFeesByDayFilter } from './useBusinessTransactionFeesByDayFilter'
import { IPagination } from '../../../core/interfaces'
import { DiffValue } from './components/DiffValue'

interface IProps extends RouteComponentProps<{}> {}

type Value<T, K extends keyof T> = T[K]

const BusinessTransactionFeesByDaysTableCells: React.FC<{
  className: string
  item: IBusinessTransactionFeesByDayItem
}> = ({ className, item }) => {
  return (
    <>
      <TableCell align='right' className={className}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          {item.count.toLocaleString()}
          <DiffValue current={item.count} prev={item.countPrev} />
        </Box>
      </TableCell>
      <TableCell align='right' className={className}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          {item.amount.toLocaleString()}
          <DiffValue current={item.amount} prev={item.amountPrev} isFloat />
        </Box>
      </TableCell>
    </>
  )
}

const BusinessTransactionFeesByDaysReport: React.FC<IProps> = ({ match }) => {
  const classes = useStyles()
  const { renderFilter, filter, handleChangeFilter } = useBusinessTransactionFeesByDayFilter()
  const filterRef = React.useRef(filter)

  if (!isDeepEqual(filterRef.current, filter)) {
    filterRef.current = filter
  }
  const [pagination, setPagination] = React.useState<IPagination>({
    offset: 0,
    limit: ROWS_PER_PAGE[ROWS_PER_PAGE.length - 1],
    page: 0,
  })

  const { data, loading } = useQuery<IBusinessTransactionFeesByDaysData, IBusinessTransactionFeesByDaysVariables>(
    BUSINESS_TRANSACTION_FEES_BY_DAYS,
    {
      variables: {
        input: {
          businessId: filter.business?.id,
          isVisible: filter.isVisible || undefined,
          balanceType: filter.balanceType,
        },
        pagination: {
          limit: pagination.limit,
          offset: pagination.offset,
        },
      },
      fetchPolicy: 'network-only',
    }
  )
  const items = data?.businessTransactionFeesByDays.data || []
  const total = data?.businessTransactionFeesByDays.total || 0
  const summary: IBusinessTransactionFeesByDay = data?.businessTransactionFeesByDays.summary || {
    previousWeek: { count: 0, countPrev: 0, amount: 0, amountPrev: 0 },
    currentWeek: { count: 0, countPrev: 0, amount: 0, amountPrev: 0 },
    sevenDaysAgo: { count: 0, countPrev: 0, amount: 0, amountPrev: 0 },
    sixDaysAgo: { count: 0, countPrev: 0, amount: 0, amountPrev: 0 },
    fiveDaysAgo: { count: 0, countPrev: 0, amount: 0, amountPrev: 0 },
    fourDaysAgo: { count: 0, countPrev: 0, amount: 0, amountPrev: 0 },
    threeDaysAgo: { count: 0, countPrev: 0, amount: 0, amountPrev: 0 },
    twoDayAgo: { count: 0, countPrev: 0, amount: 0, amountPrev: 0 },
    oneDayAgo: { count: 0, countPrev: 0, amount: 0, amountPrev: 0 },
    today: { count: 0, countPrev: 0, amount: 0, amountPrev: 0 },
  }
  // const handleChange = <K extends keyof IFilter>(field: K) => (value: Value<IFilter, K>) => {
  //   setFilter({ ...filter, [field]: value, offset: 0, page: 0 })
  // }

  const handleChangePerPage = (limit: number) => {
    if (limit !== pagination.limit) {
      setPagination({ page: 0, offset: 0, limit })
    }
  }
  const handleChangePage = (page: number) => {
    setPagination({ ...pagination, offset: page * pagination.limit, page })
  }

  React.useEffect(() => {
    setPagination({ ...pagination, page: 0, offset: 0 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRef.current])

  return (
    <div>
      <PageHeader title={`Отчет по комиссиям`} right={renderFilter()} />
      {loading && <Loader />}
      {items.length > 0 ? (
        <Content noGutter>
          <Pagination
            rowsPerPage={pagination.limit}
            rowsPerPageOptions={ROWS_PER_PAGE}
            onChangeRowsPerPage={handleChangePerPage}
            onChangePage={handleChangePage}
            page={pagination.page}
            count={total}
          />
          <TableContainer className={classes.container}>
            <Table aria-label='simple table' stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={classnames(classes.cell, classes.head, classes.stickyHeadCell)}
                    align='center'
                    rowSpan={2}
                  >
                    Партнер
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    Прошлая неделя
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    Текущая неделя
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    7 дней назад
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    6 дней назад
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    5 дней назад
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    4 дня назад
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    3 дня назад
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    2 дня назад
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell className={classnames(classes.cell, classes.head)} align='center' colSpan={2}>
                    1 день назад
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                  <TableCell align='center' className={classnames(classes.cell, classes.head)} colSpan={2}>
                    Сегодня
                    <Box display='flex' justifyContent='space-around'>
                      <Box>Кол-во</Box>
                      <Box>Сумма</Box>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item, rowIndex) => (
                  <TableRow className={classes.row} key={`partner-${item.businessId}`}>
                    <TableCell
                      align='left'
                      className={classnames(classes.cell, classes.stickyCol, { [classes.oddColor]: !(rowIndex % 2) })}
                    >
                      <Link
                        href='#'
                        onClick={(event: { preventDefault: () => void }) => {
                          event.preventDefault()
                          const business: IBusinessShortest = {
                            id: item.businessId,
                            balance: 0,
                            companyName: item.companyName,
                          }
                          handleChangeFilter('business')(business)
                        }}
                      >
                        {item.companyName}
                      </Link>
                    </TableCell>
                    <BusinessTransactionFeesByDaysTableCells
                      className={classnames(classes.cell, classes.subCell)}
                      item={item.previousWeek}
                    />
                    <BusinessTransactionFeesByDaysTableCells
                      className={classnames(classes.cell, classes.subCell)}
                      item={item.currentWeek}
                    />
                    <BusinessTransactionFeesByDaysTableCells
                      className={classnames(classes.cell, classes.subCell)}
                      item={item.sevenDaysAgo}
                    />
                    <BusinessTransactionFeesByDaysTableCells
                      className={classnames(classes.cell, classes.subCell)}
                      item={item.sixDaysAgo}
                    />
                    <BusinessTransactionFeesByDaysTableCells
                      className={classnames(classes.cell, classes.subCell)}
                      item={item.fiveDaysAgo}
                    />
                    <BusinessTransactionFeesByDaysTableCells
                      className={classnames(classes.cell, classes.subCell)}
                      item={item.fourDaysAgo}
                    />
                    <BusinessTransactionFeesByDaysTableCells
                      className={classnames(classes.cell, classes.subCell)}
                      item={item.threeDaysAgo}
                    />
                    <BusinessTransactionFeesByDaysTableCells
                      className={classnames(classes.cell, classes.subCell)}
                      item={item.twoDayAgo}
                    />
                    <BusinessTransactionFeesByDaysTableCells
                      className={classnames(classes.cell, classes.subCell)}
                      item={item.oneDayAgo}
                    />
                    <BusinessTransactionFeesByDaysTableCells
                      className={classnames(classes.cell, classes.subCell)}
                      item={item.today}
                    />
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter className={classes.stickyFooter}>
                <TableRow>
                  <TableCell
                    align='left'
                    className={classnames(classes.cell, classes.summary, classes.stickyFooterCol)}
                  >
                    Итого:
                  </TableCell>
                  <BusinessTransactionFeesByDaysTableCells
                    className={classnames(classes.cell, classes.summary, classes.subCell, classes.stickyFooter)}
                    item={summary.previousWeek}
                  />
                  <BusinessTransactionFeesByDaysTableCells
                    className={classnames(classes.cell, classes.summary, classes.subCell, classes.stickyFooter)}
                    item={summary.currentWeek}
                  />
                  <BusinessTransactionFeesByDaysTableCells
                    className={classnames(classes.cell, classes.summary, classes.subCell, classes.stickyFooter)}
                    item={summary.sevenDaysAgo}
                  />
                  <BusinessTransactionFeesByDaysTableCells
                    className={classnames(classes.cell, classes.summary, classes.subCell, classes.stickyFooter)}
                    item={summary.sixDaysAgo}
                  />
                  <BusinessTransactionFeesByDaysTableCells
                    className={classnames(classes.cell, classes.summary, classes.subCell, classes.stickyFooter)}
                    item={summary.fiveDaysAgo}
                  />
                  <BusinessTransactionFeesByDaysTableCells
                    className={classnames(classes.cell, classes.summary, classes.subCell, classes.stickyFooter)}
                    item={summary.fourDaysAgo}
                  />
                  <BusinessTransactionFeesByDaysTableCells
                    className={classnames(classes.cell, classes.summary, classes.subCell, classes.stickyFooter)}
                    item={summary.threeDaysAgo}
                  />
                  <BusinessTransactionFeesByDaysTableCells
                    className={classnames(classes.cell, classes.summary, classes.subCell, classes.stickyFooter)}
                    item={summary.twoDayAgo}
                  />
                  <BusinessTransactionFeesByDaysTableCells
                    className={classnames(classes.cell, classes.summary, classes.subCell, classes.stickyFooter)}
                    item={summary.oneDayAgo}
                  />
                  <BusinessTransactionFeesByDaysTableCells
                    className={classnames(classes.cell, classes.summary, classes.subCell, classes.stickyFooter)}
                    item={summary.today}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <Pagination
            rowsPerPage={pagination.limit}
            rowsPerPageOptions={ROWS_PER_PAGE}
            onChangeRowsPerPage={handleChangePerPage}
            onChangePage={handleChangePage}
            page={pagination.page}
            count={total}
          />
        </Content>
      ) : (
        <EmptyDataMessage />
      )}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  filterContainer: {
    margin: theme.spacing(1, 0),
  },
  container: {
    maxHeight: 'max(60vh, 100vh - 280px)',
  },
  summary: {
    color: theme.palette.grey[800],
    fontWeight: 600,
  },
  head: {
    color: theme.palette.grey[800],
    fontWeight: 400,
  },
  stickyHeadCell: {
    position: 'sticky',
    width: 160,
    minWidth: 160,
    maxWidth: 160,
    left: 0,
    zIndex: 4,
  },
  stickyFooter: {
    position: 'sticky',
    bottom: 0,
    zIndex: 3,
    backgroundColor: 'white',
  },
  stickyFooterCol: {
    position: 'sticky',
    backgroundColor: 'white',
    width: 160,
    minWidth: 160,
    maxWidth: 160,
    left: 0,
    bottom: 0,
    zIndex: 4,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  stickyCol: {
    position: 'sticky',
    backgroundColor: 'white',
    width: 160,
    minWidth: 160,
    maxWidth: 160,
    left: 0,
    zIndex: 3,
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  cell: {
    padding: theme.spacing(0.5, 1),
    borderRight: '1px solid rgba(224, 224, 224, 1)',
  },
  subCell: {
    minWidth: 90,
  },
  oddColor: {
    backgroundColor: '#F5F5F5',
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F5F5F5',
    },
  },
}))

export default BusinessTransactionFeesByDaysReport
