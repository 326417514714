import React from 'react'
import { Typography, Card, CardContent, CardActions, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconComponent, DoubleArrow } from '@material-ui/icons'

interface IProps {
  label: string
  value: string
  valueDesc?: string
  onClick?: () => void
  Icon?: SvgIconComponent
  OnClickIcon?: SvgIconComponent
}

export const SingleValueWidget: React.FC<IProps> = ({ label, value, valueDesc, onClick, Icon, OnClickIcon }) => {
  const classes = useStyles()

  return (
    <Card className={classes.card} elevation={0}>
      <CardContent className={classes.content}>
        <Typography className={classes.label}>{label}</Typography>
        <Typography className={classes.value}>
          {value}
          {!!valueDesc && (
            <Typography component='span' variant='caption' color='textSecondary'>
              {' '}
              {valueDesc}
            </Typography>
          )}
        </Typography>
        {Icon && <Icon className={classes.icon} />}
      </CardContent>
      {onClick && (
        <CardActions>
          <IconButton color='primary' onClick={onClick}>
            {OnClickIcon ? <OnClickIcon /> : <DoubleArrow />}
          </IconButton>
        </CardActions>
      )}
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    height: '100%',
  },
  content: {
    padding: theme.spacing(2),
    paddingBottom: 0,
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  label: {
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.text.hint,
    fontSize: theme.typography.fontSize - 4,
    textTransform: 'uppercase',
  },
  value: {
    fontSize: theme.typography.fontSize + 12,
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: 10,
    fontSize: 60,
    color: theme.palette.primary.light,
    opacity: 0.6,
  },
}))
