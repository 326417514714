import React from 'react'
import { Button, ButtonGroup } from '@material-ui/core'
import { useSupportedLanguages } from './useSupportedLanguages'
import { useCurrentLanguage } from './CurrentLanguageContext'

export const LanguageSwitcher: React.FC = () => {
  const { currentLanguage, changeLanguage } = useCurrentLanguage()
  const { languages } = useSupportedLanguages()

  return (
    <ButtonGroup size='small' disableElevation variant='outlined' color='primary'>
      {languages.map(l => (
        <Button key={`ln-${l.code}`} disabled={currentLanguage === l.code} onClick={() => changeLanguage(l.code)}>
          {l.label}
        </Button>
      ))}
    </ButtonGroup>
  )
}
