import * as yup from 'yup'

export const categorySchema = yup.object().shape({
  titles: yup
    .array()
    .of(
      yup.object().shape({
        code: yup.string().required(),
        label: yup.string().required(),
      })
    )
    .min(1),
  parentId: yup
    .number()
    .integer()
    .nullable(),
  sortOrder: yup.number(),
})
