import React, { createContext, useContext, useState } from 'react'

interface ICurrentLanguageContextProps {
  currentLanguage: string
  changeLanguage: (lang: string) => void
}

const initialState: ICurrentLanguageContextProps = {
  currentLanguage: 'ru',
  changeLanguage: () => {},
}

const CurrentLanguageContext = createContext(initialState)

const CurrentLanguageProvider: React.FC = ({ children }) => {
  const [language, setLanguage] = useState('ru')

  const handleChange = (lang: string) => {
    setLanguage(lang)
  }

  return (
    <CurrentLanguageContext.Provider
      value={{
        currentLanguage: language,
        changeLanguage: handleChange,
      }}
    >
      {children}
    </CurrentLanguageContext.Provider>
  )
}

const useCurrentLanguage = () => useContext(CurrentLanguageContext)

export { useCurrentLanguage, CurrentLanguageProvider }
