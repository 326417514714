import React, { ChangeEvent } from 'react'
import { Box, Typography, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ITimezone } from '../../../../graphql/types/localization'
import { useAllTimezones } from '../../../../graphql/hooks/localization'

interface IProps {
  value: ITimezone | null
  onSelect: (option: ITimezone | null) => void
  error?: boolean
}

export const TimezoneAutocomplete: React.FC<IProps> = ({ value, onSelect, error }) => {
  const [open, setOpen] = React.useState<boolean>(false)
  const { timezones, loading } = useAllTimezones()

  const renderOption = (option: ITimezone | null) => (
    <Box width='100%' display='flex' flexDirection='row' justifyContent='space-between'>
      <Typography>
        <Typography display='inline' component='span'>
          {option?.title}
        </Typography>
      </Typography>
    </Box>
  )

  const handleChange = (event: ChangeEvent<{}>, option: ITimezone | null) => {
    onSelect(option)
  }

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      value={value}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => `${option.title}`}
      renderOption={renderOption}
      options={timezones}
      loading={loading}
      noOptionsText={'Не найдено'}
      onChange={handleChange}
      fullWidth
      renderInput={params => (
        <TextField
          {...params}
          label='Таймзона'
          error={error}
          margin='normal'
          fullWidth
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  )
}
