import React from 'react'
import { Box, Button, TextField } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'

import { LoadableButton } from '../../../../components/Form'
import { ICity, ITimezone } from '../../../../graphql/types/localization'
import { TimezoneAutocomplete } from './TimezonesAutocomplete'
import { citySchema } from './validation'

interface IProps {
  loading: boolean
  city?: ICity
  onSubmit: (values: ICityFormValues) => void
  onCancel: () => void
}

export interface ICityFormValues {
  name: string
  timezone: ITimezone | null
}

export const CityForm: React.FC<IProps> = ({ city, loading, onSubmit, onCancel }) => {
  const initialValues: ICityFormValues = {
    name: city?.name || '',
    timezone: city?.timezone || null,
  }

  const handleSubmit = (values: ICityFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={citySchema} onSubmit={handleSubmit}>
      {({ values, errors, handleChange, setFieldValue, submitForm }: FormikProps<ICityFormValues>) => (
        <div>
          <Box mb={2}>
            <TextField
              label='Название'
              name='name'
              value={values.name}
              onChange={handleChange}
              required
              fullWidth
              margin='normal'
              error={!!errors.name}
            />
          </Box>
          <Box mb={4}>
            <TimezoneAutocomplete
              value={values.timezone}
              onSelect={option => setFieldValue('timezone', option)}
              error={!!errors.timezone}
            />
          </Box>
          <Box pt={4} pb={1} display='flex' flexDirection='row' justifyContent='space-between'>
            <Button onClick={onCancel}>Отмена</Button>
            <LoadableButton loading={loading} color='primary' variant='contained' onClick={submitForm}>
              Сохранить
            </LoadableButton>
          </Box>
        </div>
      )}
    </Formik>
  )
}
