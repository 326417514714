import React, { useEffect, useState } from 'react'
import { ClickAwayListener, Box } from '@material-ui/core'
import { I18nField } from '../../../components/Form'
import { I18nTitles } from '../../../graphql/types/common'
import { I18nTypography } from '../../../components/UI'

interface IProps {
  title: string
  titles: I18nTitles
  onSave: (titles: I18nTitles) => void
}

export const CategoryEditableTitle: React.FC<IProps> = ({ title, titles, onSave }) => {
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [value, setValue] = useState(titles)

  useEffect(() => {
    setValue(titles)
  }, [titles])

  const toggleEdit = () => {
    setIsEdit(!isEdit)
  }

  const handleSave = () => {
    onSave(value)
    toggleEdit()
  }

  if (isEdit) {
    return (
      <ClickAwayListener onClickAway={handleSave}>
        <Box>
          <I18nField
            fullWidth
            margin='none'
            size='small'
            label='Название'
            value={value}
            onChange={setValue}
            // error={Boolean(touched.titles && errors.titles)}
          />
        </Box>
      </ClickAwayListener>
    )
  }

  return <I18nTypography variant='subtitle2' value={value} fallback={title} onClick={toggleEdit} />
}
