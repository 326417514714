import React from 'react'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import { Alert } from '@material-ui/lab'
import { useMutation } from '@apollo/react-hooks'

import { LoadableButton, PhoneField } from '../../../components/Form'
import { userSchema } from './validation'
import { useAllCountries } from '../../../graphql/hooks/localization'
import {
  IFranchisee,
  IValidateFranchiseeUserData,
  IValidateFranchiseeUserInput,
  IValidateFranchiseeUserVariables,
} from '../../../graphql/types/franchisee'
import { VALIDATE_FRANCHISEE_USER_MUTATION } from '../../../graphql/queries/franchisee'

interface IProps {
  franchisee?: IFranchisee
  submitButtonLabel?: string
  onSubmit: (values: IFranchiseeUserFormValues) => void
  onCancel?: () => void
}

export interface IFranchiseeUserFormValues {
  name: string
  phone: string
  email: string
  type: string
  cityId: string
}

export const FranchiseeUserForm: React.FC<IProps> = ({
  franchisee,
  onSubmit,
  onCancel,
  submitButtonLabel = 'Сохранить',
}) => {
  const { countries } = useAllCountries()
  const [validateFranchiseeUser, { loading, error }] = useMutation<
    IValidateFranchiseeUserData,
    IValidateFranchiseeUserVariables
  >(VALIDATE_FRANCHISEE_USER_MUTATION)

  const initialValues: IFranchiseeUserFormValues = {
    name: franchisee?.name || '',
    phone: franchisee?.user.phone || '',
    email: franchisee?.email || '',
    type: franchisee?.type.toString() || '1',
    cityId: franchisee?.city.id.toString() || '',
  }

  const handleSubmit = async (values: IFranchiseeUserFormValues) => {
    const input: IValidateFranchiseeUserInput = {
      ...values,
      type: parseInt(values.type, 10),
      cityId: parseInt(values.cityId, 10),
    }

    try {
      const { data } = await validateFranchiseeUser({
        variables: { input },
      })

      if (data?.validateFranchiseeUser) {
        onSubmit(values)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={userSchema} onSubmit={handleSubmit}>
      {({ values, errors, setFieldValue, handleChange, submitForm }: FormikProps<IFranchiseeUserFormValues>) => (
        <div>
          {!!error && (
            <Box mb={3}>
              <Alert severity='error'>{error.message.replace('GraphQL error: ', '')}</Alert>
            </Box>
          )}
          <FormControl component='fieldset'>
            <RadioGroup row aria-label='franchisee-type' name='type' value={values.type} onChange={handleChange}>
              <FormControlLabel value={'1'} control={<Radio />} label='Физ лицо' />
              <FormControlLabel value={'2'} control={<Radio />} label='Юр лицо' />
            </RadioGroup>
          </FormControl>
          <TextField
            label='Имя'
            name='name'
            value={values.name}
            onChange={handleChange}
            required
            fullWidth
            margin='normal'
            error={!!errors.name}
          />
          {!franchisee && (
            <>
              <PhoneField
                label='Телефон'
                name='phone'
                value={values.phone}
                onChange={e => setFieldValue('phone', `+${e.target.value.replace(/\D+/g, '')}`)}
                error={!!errors.phone}
                required
                fullWidth
                margin='normal'
              />
              <TextField
                label='Email'
                name='email'
                value={values.email}
                onChange={handleChange}
                required
                fullWidth
                margin='normal'
                error={!!errors.email}
              />
            </>
          )}
          <FormControl fullWidth margin='normal' error={!!errors.cityId}>
            <InputLabel htmlFor='city-select'>Город</InputLabel>
            <Select native name='cityId' value={values.cityId} onChange={handleChange} id='city-select'>
              <option aria-label='None' value='' />
              {countries.map(
                country =>
                  country.cities.length > 0 && (
                    <optgroup key={`country-${country.id}`} label={country.name}>
                      {country.cities.map(city => (
                        <option key={`city-${city.id}`} value={`${city.id}`}>
                          {city.name}
                        </option>
                      ))}
                    </optgroup>
                  )
              )}
            </Select>
          </FormControl>
          <Box
            pt={4}
            pb={1}
            display='flex'
            flexDirection='row'
            justifyContent={onCancel ? 'space-between' : 'flex-end'}
          >
            {onCancel && <Button onClick={onCancel}>Назад</Button>}
            <LoadableButton loading={loading} color='primary' variant='contained' onClick={submitForm}>
              {submitButtonLabel}
            </LoadableButton>
          </Box>
        </div>
      )}
    </Formik>
  )
}
