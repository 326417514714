import React from 'react'
import { Box, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useMutation } from '@apollo/react-hooks'

import { FranchiseeCommissionsForm, IFranchiseeCommissionsFormValues } from '../Forms'
import {
  IFranchisee,
  IUpdateFranchiseeCommissionData,
  IUpdateFranchiseeCommissionInput,
  IUpdateFranchiseeCommissionVariables,
} from '../../../graphql/types/franchisee'
import { UPDATE_FRANCHISEE_COMMISSION_MUTATION } from '../../../graphql/queries/franchisee'

interface IProps {
  franchisee: IFranchisee
  open: boolean
  onCancel: () => void
}

export const FranchiseeCommissionsModal: React.FC<IProps> = ({ franchisee, open, onCancel }) => {
  const [updateCommissions, { error }] = useMutation<
    IUpdateFranchiseeCommissionData,
    IUpdateFranchiseeCommissionVariables
  >(UPDATE_FRANCHISEE_COMMISSION_MUTATION)

  const handleSubmit = async (values: IFranchiseeCommissionsFormValues) => {
    const input: IUpdateFranchiseeCommissionInput = {
      commissionType: Number(values.commissionType),
      commission: Number(values.commission),
      commissionMin: Number(values.commissionMin),
      businessCommission: {
        percent: {
          min: Number(values.businessCommissionPercentMin),
          max: Number(values.businessCommissionPercentMax),
        },
        fixed: {
          min: Number(values.businessCommissionFixedMin),
          max: Number(values.businessCommissionFixedMax),
        },
      },
    }

    await updateCommissions({
      variables: { franchiseeId: franchisee.id, input },
    })

    onCancel()
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth onClose={onCancel}>
      <DialogTitle id='form-dialog-title'>Комиссии</DialogTitle>
      <DialogContent>
        {!!error && (
          <Box mb={3}>
            <Alert severity='error'>{error}</Alert>
          </Box>
        )}
        <FranchiseeCommissionsForm franchisee={franchisee} onSubmit={handleSubmit} onCancel={onCancel} />
      </DialogContent>
    </Dialog>
  )
}
