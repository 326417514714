import firebase from 'firebase/app'
import 'firebase/messaging'

if (firebase.messaging.isSupported()) {
  var firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
  }

  firebase.initializeApp(firebaseConfig)
  var messaging = firebase.messaging()
}
export const getToken = () => {
  return messaging?.getToken({
    vapidKey: process.env.REACT_APP_FB_VAPID_KEY,
  })
}

export const onMessageListener = () =>
  new Promise<firebase.messaging.MessagePayload>(resolve => {
    messaging?.onMessage((payload: firebase.messaging.MessagePayload) => {
      resolve(payload)
    })
  })
