import React from 'react'
import { Box, Button, TextField, Grid, Typography, makeStyles } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'

import { LoadableButton, TransactionFeeTypeSelect } from '../../../../components/Form'
import { ICountry } from '../../../../graphql/types/localization'
import { countrySchema } from './validation'
import { TransactionFeeType } from '../../../../core/interfaces'

interface IProps {
  loading: boolean
  country?: ICountry
  onSubmit: (values: ICountryFormValues) => void
  onCancel: () => void
}

export interface ICountryFormValues {
  name: string
  code: string
  numberCode: string
  currencyCode: string
  currencySymbol: string
  registrationPrice: string
  welcomeBonusAmount: string
  defaultTransactionFee: string
  defaultTransactionFeeType: TransactionFeeType
  minTransactionFee: string
  balanceNotifyThreshold: string
  balanceNotifyMinThreshold: string
}

export const CountryForm: React.FC<IProps> = ({ country, loading, onSubmit, onCancel }) => {
  const classes = useStyles()

  const initialValues: ICountryFormValues = {
    name: country?.name || '',
    code: country?.code || '',
    numberCode: country?.numberCode.toString() || '',
    currencyCode: country?.currencyCode || '',
    currencySymbol: country?.currencySymbol || '',
    registrationPrice: country?.registrationPrice.toString() || '',
    welcomeBonusAmount: country?.welcomeBonusAmount.toString() || '',
    defaultTransactionFee: country?.defaultTransactionFee.toString() || '',
    defaultTransactionFeeType: country?.defaultTransactionFeeType || TransactionFeeType.FIX,
    minTransactionFee: country?.minTransactionFee.toString() || '',
    balanceNotifyThreshold: country?.balanceNotifyThreshold.toString() || '',
    balanceNotifyMinThreshold: country?.balanceNotifyMinThreshold.toString() || '',
  }

  const handleSubmit = (values: ICountryFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={countrySchema} onSubmit={handleSubmit}>
      {({ values, errors, setFieldValue, handleChange, submitForm }: FormikProps<ICountryFormValues>) => (
        <div>
          <Box mb={4}>
            <TextField
              label='Название'
              name='name'
              value={values.name}
              onChange={handleChange}
              required
              fullWidth
              margin='normal'
              error={!!errors.name}
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label='Код страны'
                  name='code'
                  value={values.code}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin='normal'
                  error={!!errors.code}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label='Цифровой код'
                  name='numberCode'
                  value={values.numberCode}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin='normal'
                  error={!!errors.numberCode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label='Код валюты'
                  name='currencyCode'
                  value={values.currencyCode}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin='dense'
                  error={!!errors.currencyCode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label='Символ валюты'
                  name='currencySymbol'
                  value={values.currencySymbol}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin='dense'
                  error={!!errors.currencySymbol}
                />
              </Grid>
            </Grid>
          </Box>
          <Box mb={4}>
            <Box mb={1}>
              <Typography variant='subtitle1'>Регистрация</Typography>
            </Box>
            <Box className={classes.box}>
              <TextField
                label='Стоимость регистрации'
                name='registrationPrice'
                value={values.registrationPrice}
                onChange={handleChange}
                required
                fullWidth
                margin='dense'
                error={!!errors.registrationPrice}
                helperText={'Стоимость регистрации бизнеса в валюте страны'}
              />
              <TextField
                label='Подарочный бонус'
                name='welcomeBonusAmount'
                value={values.welcomeBonusAmount}
                onChange={handleChange}
                required
                fullWidth
                margin='dense'
                error={!!errors.welcomeBonusAmount}
                helperText={'Размер подарочного бонуса за регистрацию бизнеса'}
              />
            </Box>
          </Box>
          <Box mb={4}>
            <Box mb={1}>
              <Typography variant='subtitle1'>Комиссия</Typography>
            </Box>
            <Box className={classes.box}>
              <TextField
                label='Комиссия по умолчанию'
                name='defaultTransactionFee'
                value={values.defaultTransactionFee}
                onChange={handleChange}
                required
                fullWidth
                margin='dense'
                error={!!errors.defaultTransactionFee}
                helperText={'Комиссия по умолчанию для новых зарегистрированных бизнесов в валюте страны'}
              />
              <TransactionFeeTypeSelect
                value={values.defaultTransactionFeeType}
                onChange={type => setFieldValue('defaultTransactionFeeType', type)}
              />
              <TextField
                label='Мин комиссия'
                name='minTransactionFee'
                value={values.minTransactionFee}
                onChange={handleChange}
                required
                fullWidth
                margin='dense'
                error={!!errors.minTransactionFee}
                helperText={'Минимальная комиссия за транзакцию в валюте страны'}
              />
            </Box>
          </Box>
          <Box mb={4}>
            <Box mb={1}>
              <Typography variant='subtitle1'>Порог баланса</Typography>
            </Box>
            <Box className={classes.box}>
              <TextField
                label='Порог баланса'
                name='balanceNotifyThreshold'
                value={values.balanceNotifyThreshold}
                onChange={handleChange}
                required
                fullWidth
                margin='dense'
                error={!!errors.balanceNotifyThreshold}
                helperText={'Уведомление о снижение порога баланса'}
              />
              <TextField
                label='Минимальный порог баланса'
                name='balanceNotifyMinThreshold'
                value={values.balanceNotifyMinThreshold}
                onChange={handleChange}
                required
                fullWidth
                margin='dense'
                error={!!errors.balanceNotifyMinThreshold}
                helperText={'Уведомление о снижение минимального порога баланса'}
              />
            </Box>
          </Box>
          <Box pt={4} pb={1} display='flex' flexDirection='row' justifyContent='space-between'>
            <Button onClick={onCancel}>Отмена</Button>
            <LoadableButton loading={loading} color='primary' variant='contained' onClick={submitForm}>
              Сохранить
            </LoadableButton>
          </Box>
        </div>
      )}
    </Formik>
  )
}

const useStyles = makeStyles(theme => ({
  box: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
}))
