import React, { Fragment, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Box, Divider, IconButton, Typography } from '@material-ui/core'
import { FilterList as FilterIcon, Close as CloseIcon } from '@material-ui/icons'

import { useQueryParams } from '../../utils'
import { IBusiness } from '../../graphql/types/businesses'
import { PageHeader, Content } from '../../components/Layout'
import { Loader } from '../../components/UI'
import { DataTable, EmptyDataMessage } from '../../components/Data'
import Pagination from '../../components/Data/pagination/Pagination'
import { columns } from './businessesColumns'
import { BusinessesFilter } from './components'
import { useAllBusinesses, ROWS_PER_PAGE } from './hooks'

interface IProps extends RouteComponentProps {}

const Businesses: React.FC<IProps> = ({ history }) => {
  const [, setQuery] = useQueryParams()
  const { businesses, total, loading, page, limit, hasFilter } = useAllBusinesses()
  const [filterVisible, setFilterVisible] = useState<boolean>(hasFilter)

  const toggleFilter = () => {
    setFilterVisible(prev => !prev)

    if (filterVisible) {
      history.push(`/businesses`)
    }
  }

  const handleClickItem = (item: IBusiness) => {
    history.push(`/businesses/${item.id}`)
  }

  const handleChangePerPage = (perPage: number) => {
    if (perPage !== limit) {
      setQuery({ page: 1, limit: perPage })
    }
  }

  const handleChangePage = (p: number) => {
    setQuery({ page: p + 1 })
  }

  return (
    <div>
      <PageHeader title='Партнеры' />
      <Content noGutter>
        <Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
          <Typography>Всего: {total}</Typography>
          <IconButton onClick={toggleFilter}>{filterVisible ? <CloseIcon /> : <FilterIcon />}</IconButton>
        </Box>
        {filterVisible && <BusinessesFilter />}
        <Divider />
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            {businesses.length > 0 ? (
              <Fragment>
                <DataTable data={businesses} columns={columns} onClickItem={handleClickItem} />
                <Box mt={3}>
                  <Pagination
                    rowsPerPage={limit}
                    rowsPerPageOptions={ROWS_PER_PAGE}
                    onChangeRowsPerPage={handleChangePerPage}
                    onChangePage={handleChangePage}
                    page={page - 1}
                    count={total}
                  />
                </Box>
              </Fragment>
            ) : (
              <EmptyDataMessage />
            )}
          </Fragment>
        )}
      </Content>
    </div>
  )
}

export default Businesses
