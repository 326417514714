import React from 'react'
import { CircularProgress, IconButton, Menu, MenuItem } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useMutation } from '@apollo/react-hooks'
import {
  IFilteredBusinessTransactionsXLSXData,
  IFilteredBusinessTransactionsXLSXVariables,
  ITransactionReportByBusinessXLSXData,
  ITransactionReportByBusinessXLSXVariables,
} from '../../../graphql/types/transactions'
import {
  FILTERED_BUSINESS_TRANSACTIONS_XLSX_MUTATION,
  TRANSACTION_REPORT_BY_BUSINESS_XLSX_MUTATION,
} from '../../../graphql/queries/transactions'
import { ITransactionFilter } from './useTransactionsFilter'
import { IReportContractsSummaryXLSXData, IReportContractsSummaryXLSXVariables } from '../../../graphql/types/report'
import { REPORT_CONTRACTS_SUMMARY_XLSX } from '../../../graphql/queries/report'

export const useTransactionsDownload = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>()

  const [reportXLSX, { loading: loadingXLSXBusinessTransactions }] = useMutation<
    IFilteredBusinessTransactionsXLSXData,
    IFilteredBusinessTransactionsXLSXVariables
  >(FILTERED_BUSINESS_TRANSACTIONS_XLSX_MUTATION)

  const [reportXLSXAllTransactions, { loading: loadingXLSXTransactions }] = useMutation<
    ITransactionReportByBusinessXLSXData,
    ITransactionReportByBusinessXLSXVariables
  >(TRANSACTION_REPORT_BY_BUSINESS_XLSX_MUTATION)

  const [reportContractsSummaryXLSX, { loading: loadingReportContractsSummaryXLSX }] = useMutation<
    IReportContractsSummaryXLSXData,
    IReportContractsSummaryXLSXVariables
  >(REPORT_CONTRACTS_SUMMARY_XLSX)

  const handleDownloadBusinessTransactionXLSX = (filter: ITransactionFilter) => async () => {
    handleClose()

    const { data } = await reportXLSX({
      variables: {
        filter: {
          businessId: filter.business?.id,
          salesManagerId: filter.manager?.id,
          isOnline: filter.isOnline,
          isManagerBusinesses: filter.isManagerBusinesses,
          type: filter.type,
          status: filter.status,
          startDate: filter.startDate,
          endDate: filter.endDate,
        },
        timeZoneOffset: new Date().getTimezoneOffset(),
      },
    })
    if (!!data?.filteredBusinessTransactionsXLSX) {
      const link = document.createElement('a')
      link.href = data?.filteredBusinessTransactionsXLSX
      link.click()
      link.remove()
    }
  }

  const handleDownloadAllTransactionXLSX = (filter: ITransactionFilter) => async () => {
    handleClose()

    let startDate: string
    let endDate: string
    if (typeof filter.startDate == 'object') {
      startDate = filter.startDate.toISOString()
    } else {
      startDate = filter.startDate
    }
    if (typeof filter.endDate == 'object') {
      endDate = filter.endDate.toISOString()
    } else {
      endDate = filter.endDate
    }
    const { data } = await reportXLSXAllTransactions({
      variables: {
        businessId: filter.business?.id,
        filter: {
          dateRange: {
            startDate: startDate,
            endDate: endDate,
          },
        },
        timeZoneOffset: new Date().getTimezoneOffset(),
      },
    })
    if (!!data?.transactionReportByBusinessXLSX) {
      const link = document.createElement('a')
      link.href = data?.transactionReportByBusinessXLSX
      link.click()
      link.remove()
    }
  }

  const handleDownloadContractSummaryXLSX = (filter: ITransactionFilter) => async () => {
    handleClose()

    const { data } = await reportContractsSummaryXLSX({
      variables: {
        filter: {
          businessId: filter.business?.id,
        },
        timeZoneOffset: new Date().getTimezoneOffset(),
      },
    })
    if (!!data?.reportContractsSummaryXLSX) {
      const link = document.createElement('a')
      link.href = data?.reportContractsSummaryXLSX
      link.click()
      link.remove()
    }
  }

  const loading = loadingXLSXBusinessTransactions || loadingXLSXTransactions || loadingReportContractsSummaryXLSX

  const renderDownload = (filter: ITransactionFilter) => {
    return (
      <>
        <IconButton disabled={loading} onClick={handleClick}>
          {loading ? <CircularProgress size={20} /> : <GetAppIcon />}
        </IconButton>
        <Menu
          id='transction-download-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleDownloadBusinessTransactionXLSX(filter)}>
            по payda{'<->'}партенр транзакциям
          </MenuItem>
          <MenuItem onClick={handleDownloadAllTransactionXLSX(filter)}>по партнер{'<->'}клиент транзакциям</MenuItem>
          <MenuItem onClick={handleDownloadContractSummaryXLSX(filter)}>отчет по контрактам партнера</MenuItem>
        </Menu>
      </>
    )
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return {
    renderDownload,
  }
}
