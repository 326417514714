import { useQuery, useMutation } from '@apollo/react-hooks'

import {
  ALL_CATEGORIES_QUERY,
  UPSERT_CATEGORY_MUTATION,
  DELETE_CATEGORY_MUTATION,
  MOVE_CATEGORY_MUTATION,
} from '../../queries/categories'
import {
  IAllCategoriesData,
  IAllCategoriesVariables,
  IUpsertCategoryData,
  IUpsertCategoryVariables,
  IDeleteCategoryData,
  IDeleteCategoryVariables,
  IMoveCategoryData,
  IMoveCategoryVariables,
  IUpsertCategoryInput,
  ICategorySortOrder,
} from '../../types/categories'

export const useCategoriesApi = () => {
  const { data, loading } = useQuery<IAllCategoriesData, IAllCategoriesVariables>(ALL_CATEGORIES_QUERY)
  const [upsertCategoryMutation] = useMutation<IUpsertCategoryData, IUpsertCategoryVariables>(UPSERT_CATEGORY_MUTATION)
  const [deleteCategoryMutation] = useMutation<IDeleteCategoryData, IDeleteCategoryVariables>(DELETE_CATEGORY_MUTATION)
  const [moveCategoryMutation] = useMutation<IMoveCategoryData, IMoveCategoryVariables>(MOVE_CATEGORY_MUTATION)

  const upsertCategory = async (input: IUpsertCategoryInput, icon?: File) => {
    try {
      const { data } = await upsertCategoryMutation({ variables: { input, icon } })

      return data?.upsertCategory
    } catch (error) {
      console.log(error)
    }
  }

  const deleteCategory = async (id: number) => {
    try {
      await deleteCategoryMutation({ variables: { id } })
    } catch (error) {
      console.log(error)
    }
  }

  const moveCategory = async (id: number, parentId: number | null, sortOrders: ICategorySortOrder[]) => {
    try {
      await moveCategoryMutation({ variables: { id, parentId, sortOrders } })
    } catch (error) {
      console.log(error)
    }
  }

  return {
    loading,
    categories: data?.allCategories || [],
    upsertCategory,
    deleteCategory,
    moveCategory,
  }
}
