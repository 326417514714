import React from 'react'
import { Grid, Typography, Box, Avatar, Link, Button, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Storefront as BusinessIcon } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { IBusiness } from '../../../graphql/types/businesses'
import { maskPhone, makeHttpLink } from '../../../utils/helpers'
import { DATE_TIME_FORMAT } from '../../../core/constants'

interface IProps {
  business: IBusiness
}

export const BusinessInfo: React.FC<IProps> = ({ business }) => {
  const history = useHistory()
  const classes = useStyles()
  const createdAt = moment(business.createdAt).format(DATE_TIME_FORMAT)

  const navigateToEdit = () => {
    history.push(`/businesses/${business.id}/edit`)
  }

  const navigateToIntegration = () => {
    history.push(`/businesses/${business.id}/integration`)
  }

  return (
    <div>
      <Box mb={3}>
        <Grid container spacing={3} alignItems='center'>
          <Grid item>
            <Avatar src={business.image?.url || undefined} className={classes.avatar}>
              <BusinessIcon />
            </Avatar>
          </Grid>
          <Grid item xs>
            <Typography>{business.owner.fullName}</Typography>
            <Typography color='textSecondary'>{maskPhone(business.owner.phone)}</Typography>
            <Typography color='textSecondary'>{business.country.name}</Typography>
          </Grid>
          <Grid item>
            <Box mr={1} mb={1}>
              <Button variant='outlined' onClick={navigateToEdit}>
                Редактировать профиль
              </Button>
            </Box>
            <Button variant='outlined' onClick={navigateToIntegration}>
              Интеграция
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box mt={4}>
        <Typography color='textSecondary' variant='body2' gutterBottom>
          Дата регистрации:
        </Typography>
        <Typography>{createdAt}</Typography>
      </Box>
      <Box mt={3}>
        <Typography color='textSecondary' variant='body2' gutterBottom>
          Описание:
        </Typography>
        {!!business.description ? <Typography>{business.description}</Typography> : <Typography> - </Typography>}
      </Box>
      <Box mt={3}>
        <Typography color='textSecondary' variant='body2' gutterBottom>
          Контакты:
        </Typography>
        {!!business.address && <Typography>{business.address}</Typography>}
        {!!business.phone && <Typography>{maskPhone(business.phone)}</Typography>}
        {!!business.website && (
          <Link href={makeHttpLink(business.website)} target='_blank'>
            {business.website}
          </Link>
        )}
      </Box>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 120,
    height: 120,
    maxWidth: '100%',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[200],
  },
}))
