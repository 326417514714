import * as yup from 'yup'

import { PHONE_REGEXP } from '../../../core/constants'

export const loginSchema = yup.object().shape({
  phone: yup
    .string()
    .trim()
    .matches(PHONE_REGEXP, 'Не правильный формат телефона')
    .required(),
  password: yup.string().required(),
})
