import React from 'react'
import { Route, Redirect, RouteProps } from 'react-router-dom'
import { observer } from 'mobx-react'

import { AuthStore } from '../../stores/AuthStore'
import useStores from '../../stores/hooks/useStores'

interface IProps extends RouteProps {
  authStore?: AuthStore
}

const PrivateRoute: React.FC<IProps> = ({ component: Component, ...routeProps }) => {
  const { authStore } = useStores()

  return (
    <Route
      {...routeProps}
      render={props =>
        authStore && authStore.loggedIn && Component ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default observer(PrivateRoute)
