import React, { Fragment } from 'react'
import { Box } from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'

import { ROLES_LABELS } from '../../../core/constants'
import { SALES_SUPERVISORS_QUERY } from '../../../graphql/queries/users'
import { ISalesSupervisorsData, ISalesSupervisorsVariables, IUser } from '../../../graphql/types/user'
import { PageHeader, Content } from '../../../components/Layout'
import { DataList, EmptyDataMessage } from '../../../components/Data'
import { Loader, CreateFab } from '../../../components/UI'
import { AddSupervisorModal } from './components/AddSupervisorModal'

const SalesSupervisors: React.FC = () => {
  const [userModalVisible, setUserModalVisible] = React.useState<boolean>(false)
  const { data, loading } = useQuery<ISalesSupervisorsData, ISalesSupervisorsVariables>(SALES_SUPERVISORS_QUERY)

  const toggleModal = () => {
    setUserModalVisible(!userModalVisible)
  }

  const renderRoles = (item: IUser) => {
    return item.roles.map(role => ROLES_LABELS[role]).join(', ')
  }

  return (
    <div>
      <PageHeader title='Руководители отдела продаж' />
      <Box>
        {loading && <Loader />}
        {data?.salesSupervisors && (
          <Fragment>
            {data.salesSupervisors.length === 0 && !loading ? (
              <EmptyDataMessage />
            ) : (
              <Content noGutter>
                <DataList data={data.salesSupervisors} title='fullName' secondaryText={renderRoles} />
              </Content>
            )}
          </Fragment>
        )}
      </Box>
      <AddSupervisorModal open={userModalVisible} onCancel={toggleModal} />
      <CreateFab onClick={toggleModal} />
    </div>
  )
}

export default SalesSupervisors
