import React, { Fragment } from 'react'
import { Box, Typography } from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'

import { SALES_MANAGERS_QUERY } from '../../../graphql/queries/users'
import { ISalesManagersData, ISalesManagersVariables, ISalesManager } from '../../../graphql/types/user'
import { PageHeader, Content } from '../../../components/Layout'
import { DataList, EmptyDataMessage } from '../../../components/Data'
import { Loader, CreateFab } from '../../../components/UI'
import { AddManagerModal } from './components/AddManagerModal'
import { plural } from '../../../utils/helpers'
import { RouteComponentProps } from 'react-router-dom'
import { useManagersFilter } from './useManagersFilter'

const SalesManagers: React.FC<RouteComponentProps> = ({ history }) => {
  const [addModalVisible, setAddModalVisible] = React.useState<boolean>(false)

  const { renderFilter, filter } = useManagersFilter()

  const toggleModal = () => {
    setAddModalVisible(!addModalVisible)
  }

  const renderTitle = (item: ISalesManager) => {
    return (
      <Typography>
        <Typography component='span'>{item.user.fullName}</Typography>
        {!!item.franchisee && (
          <Typography component='span' variant='caption' color='textSecondary'>
            {' '}
            Франчайзи: {item.franchisee.name}
          </Typography>
        )}
      </Typography>
    )
  }

  const { data, loading } = useQuery<ISalesManagersData, ISalesManagersVariables>(SALES_MANAGERS_QUERY, {
    fetchPolicy: 'network-only',
    variables: { filter: { franchiseeId: filter?.franchisee?.id } },
  })

  const renderPromocode = (item: ISalesManager) => {
    return (
      <Box component='span' display='block'>
        <Box component='span' display='block'>
          {/* {item.promocode.title} - {item.promocode.code} */}
        </Box>
        <Box component='span'>
          <Box component='span' fontWeight={500}>
            {item.businessCount || 0}
          </Box>{' '}
          {plural(['партнер', 'партнера', 'партнеров'], item.businessCount || 0)}
        </Box>
      </Box>
    )
  }

  const handleClickItem = (item: ISalesManager) => {
    history.push(`/sales/managers/${item.id}`)
  }

  return (
    <div>
      <PageHeader title='Менеджеры' right={renderFilter()} />
      <Box>
        {loading && <Loader />}
        {data?.salesManagers && (
          <Fragment>
            {data.salesManagers.length === 0 && !loading ? (
              <EmptyDataMessage />
            ) : (
              <Content noGutter>
                <DataList
                  data={data.salesManagers}
                  title={renderTitle}
                  secondaryText={renderPromocode}
                  onClickItem={handleClickItem}
                />
              </Content>
            )}
          </Fragment>
        )}
      </Box>
      {addModalVisible && <AddManagerModal open onCancel={toggleModal} />}
      <CreateFab onClick={toggleModal} />
    </div>
  )
}

export default SalesManagers
