import React from 'react'
import { Box, Button } from '@material-ui/core'
import { observer } from 'mobx-react'

import useStores from '../../../../stores/hooks/useStores'
import { UserRole } from '../../../../core/interfaces'
import { IBusiness } from '../../../../graphql/types/businesses'
import { ImportClientsModal } from './ImportClientsModal'

interface IProps {
  business: IBusiness
}

export const ImportClients: React.FC<IProps> = observer(({ business }) => {
  const { authStore } = useStores()
  const [modalVisible, setModalVisible] = React.useState(false)

  const toggleModal = () => {
    setModalVisible(prev => !prev)
  }

  if (!authStore.hasRole(UserRole.SUPERADMIN)) {
    return null
  }

  return (
    <Box>
      <Button variant='outlined' onClick={toggleModal}>
        Импорт клиентов (CSV)
      </Button>
      {modalVisible && <ImportClientsModal open business={business} onClose={toggleModal} />}
    </Box>
  )
})
