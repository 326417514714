import { useMutation, useQuery } from '@apollo/react-hooks'
import { Box, List, ListItem, ListItemSecondaryAction, ListItemText, Switch } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'
import { Content, PageHeader } from '../../../components/Layout'
import { Loader } from '../../../components/UI'
import { GET_TASK_RUNNERS_QUERY, TOGGLE_TASK_RUNNER } from '../../../graphql/queries/system'
import {
  IGetTaskRunnersData,
  IGetTaskRunnersVariables,
  ITaskRunner,
  IToggleTaskRunnerData,
  IToggleTaskRunnerVariables,
} from '../../../graphql/types/system'

export const TaskRunner: React.FC<{}> = () => {
  const [toggle, { loading: toggleLoading }] = useMutation<IToggleTaskRunnerData, IToggleTaskRunnerVariables>(
    TOGGLE_TASK_RUNNER
  )

  const { data, loading } = useQuery<IGetTaskRunnersData, IGetTaskRunnersVariables>(GET_TASK_RUNNERS_QUERY, {
    fetchPolicy: 'network-only',
  })

  const handleChange = async (item: ITaskRunner) => {
    try {
      await toggle({
        variables: { instanceId: item.serverInstanceId },
        refetchQueries: [{ query: GET_TASK_RUNNERS_QUERY }],
      })
    } catch (error) {}
  }

  return (
    <Box>
      <PageHeader title='Task Runner' />

      <Box my={2} width='100%'>
        <Alert severity='warning'>Обработка фоновых задач, только один сервер должен быть включен</Alert>
      </Box>
      <Content noGutter>
        {loading && <Loader />}
        <List>
          {data?.getTaskRunners.map(item => (
            <TaskRunnerItem
              key={item.serverInstanceId}
              item={item}
              disabled={toggleLoading}
              title={item.serverInstanceId}
              secondaryText={item.description ?? undefined}
              onChange={() => handleChange(item)}
            />
          ))}
        </List>
      </Content>
    </Box>
  )
}

interface IProps {
  item: ITaskRunner
  title: string | React.ReactNode
  disabled: boolean
  secondaryText?: string | React.ReactElement | null
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
}

export const TaskRunnerItem: React.FC<IProps> = ({ onChange, title, item, disabled, secondaryText }) => {
  return (
    <ListItem button>
      <ListItemText primary={title} secondary={secondaryText} />
      <ListItemSecondaryAction>
        <Switch checked={item.isActive} onChange={onChange}></Switch>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
