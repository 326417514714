import React, { ReactElement } from 'react'
import { Box } from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import { FAQ_GROUP_BY_ID_QUERY } from '../../graphql/queries/faq'
import { RouteComponentProps } from 'react-router-dom'
import { IFaqGroupByIdData, IFaqGroupByIdVariables } from '../../graphql/types/faq'
import { PageHeader } from '../../components/Layout'
import { Loader } from '../../components/UI'
import { EmptyDataMessage } from '../../components/Data'
import FaqCardItem from './components/FaqCardItem'
import FaqEditorModalButton from './components/FaqEditorModalButton'
import { LanguageSwitcher } from '../../services/language'

interface IParams {
  id?: string
}

export default function FaqGroup({
  match: {
    params: { id = '0' },
  },
}: RouteComponentProps<IParams>): ReactElement {
  const groupId = parseInt(id, 10)
  const { loading, data } = useQuery<IFaqGroupByIdData, IFaqGroupByIdVariables>(FAQ_GROUP_BY_ID_QUERY, {
    variables: { id: groupId },
  })

  const applicationType = data?.faqGroupById.applicationType
  const faqs = data?.faqGroupById?.faqs || []
  const title = data?.faqGroupById?.name || ''

  return (
    <div>
      <PageHeader title={`Рубрика FAQ - ${title}`} parentPath={`/faq/${applicationType || ''}`} />
      <Box mb={2}>
        <LanguageSwitcher />
      </Box>
      <Box>
        {!loading && <Box>Всего: {faqs.length}</Box>}
        {loading && <Loader />}
        {faqs.length === 0 && !loading ? (
          <EmptyDataMessage />
        ) : (
          <Box>
            {faqs.map(faq => (
              <FaqCardItem key={`faq-card-${faq.id}`} groupId={groupId} item={faq} />
            ))}
          </Box>
        )}
        <FaqEditorModalButton groupId={groupId} aria-label='create' />
      </Box>
    </div>
  )
}
