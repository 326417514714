import { TreeItem } from 'react-sortable-tree'
import { ICategorySortOrder } from '../graphql/types/categories'

export const generateRandomString = (length: number): string => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export const makeHttpLink = (value: string) => {
  if (value.substr(0, 4) === 'http') {
    return value
  }

  return `http://${value}`
}

export const maskPhone = (value: string, prefix = '+') => {
  const matches = value.replace(/\D/g, '').match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/)
  const country = (matches && matches[1]) || ''
  const code = (matches && matches[2]) || ''
  const number1 = (matches && matches[3]) || ''
  const number2 = (matches && matches[4]) || ''
  const number3 = (matches && matches[5]) || ''

  const masked = `${prefix}${country} (${code}) ${number1}-${number2}-${number3}`

  return masked
}

export const unmaskPhoneNumber = (phone: string) => {
  return phone.replace(/\D+/g, '')
}

// https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-465305186
export const stripTypenames = (obj: any, propToDelete: string) => {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj.property
      const newData = stripTypenames(obj[property], propToDelete)
      obj[property] = newData
    } else {
      if (property === propToDelete) {
        delete obj[property]
      }
    }
  }

  return obj
}

export const plural = (labels: string[], number: number): string => {
  let index
  // @see http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html
  if (number % 10 === 1 && number % 100 !== 11) {
    index = 0 // many
  } else if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
    index = 1 // few
  } else {
    index = 2 // one
  }
  return labels[index] || ''
}

export const categorySortOrdersFromTree = (treeData: TreeItem[]): ICategorySortOrder[] => {
  return treeData.reduce<ICategorySortOrder[]>((prev, current, index) => {
    if (current.children) {
      return [
        ...prev,
        { id: current.id, sortOrder: index },
        ...categorySortOrdersFromTree(current.children as TreeItem[]),
      ]
    }
    return [...prev, { id: current.id, sortOrder: index }]
  }, [])
}

export const sortTreeItems = (treeData: TreeItem[]): TreeItem[] => {
  return treeData.map((current, index) => {
    if (current.children) {
      return { ...current, sortOrder: index, children: sortTreeItems(current.children as TreeItem[]) }
    }
    return { ...current, sortOrder: index }
  })
}
