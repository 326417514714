import React, { Fragment } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Grid, Typography, Divider, Box, Button, IconButton } from '@material-ui/core'
import { Edit as EditIcon, DeleteOutlined as DeleteIcon } from '@material-ui/icons'

import { PageHeader, Content } from '../../../components/Layout'
import { Loader, useConfirm } from '../../../components/UI'
import { useCountry, useDeleteCountry, useDeleteCity } from '../../../graphql/hooks/localization'
import PageNotFound from '../../PageNotFound'
import { EmptyDataMessage, DataList } from '../../../components/Data'
import { CountryFormModal } from './components/CountryFormModal'
import { CityFormModal } from './components/CityFormModal'
import { ICity } from '../../../graphql/types/localization'
import { TransactionFeeType } from '../../../core/interfaces'

const Countries: React.FC<RouteComponentProps<{ id?: string }>> = ({ history, match }) => {
  const countryId = match.params.id ? parseInt(match.params.id, 10) : 0

  const [countryModalVisible, setCountryModalVisible] = React.useState<boolean>(false)
  const [cityModalVisible, setCityModalVisible] = React.useState<boolean>(false)
  const [openedCity, setOpenedCity] = React.useState<ICity>()

  const confirm = useConfirm()
  const { country, loading } = useCountry(countryId)
  const { deleteCountry } = useDeleteCountry()
  const { deleteCity } = useDeleteCity(countryId)

  if (!countryId || !country) {
    return <PageNotFound />
  }

  if (loading) {
    return <Loader />
  }

  const toggleCountryModal = () => {
    setCountryModalVisible(!countryModalVisible)
  }

  const openCityModal = () => {
    setCityModalVisible(true)
  }

  const closeCityModal = () => {
    setOpenedCity(undefined)
    setCityModalVisible(false)
  }

  const handleEditCity = (item: ICity) => {
    setOpenedCity(item)
    openCityModal()
  }

  const handleDeleteCountry = async () => {
    try {
      await confirm({ message: 'Вы действительно хотите удалить?' })
    } catch {
      return
    }

    await deleteCountry(countryId)
    history.push(`/localization/countries`)
  }

  const handleDeleteCity = async (item: ICity) => {
    try {
      await confirm({ message: 'Вы действительно хотите удалить?' })
    } catch {
      return
    }

    await deleteCity(item.id)
  }

  const renderCityActions = (item: ICity) => {
    return (
      <>
        <IconButton size='small' onClick={() => handleEditCity(item)}>
          <EditIcon />
        </IconButton>
        <IconButton size='small' onClick={() => handleDeleteCity(item)}>
          <DeleteIcon />
        </IconButton>
      </>
    )
  }

  return (
    <div>
      <PageHeader
        title={country.name}
        parentPath={`/localization/countries`}
        right={
          <Fragment>
            <IconButton onClick={toggleCountryModal}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDeleteCountry}>
              <DeleteIcon />
            </IconButton>
          </Fragment>
        }
      />
      <Content>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant='body2' color='textSecondary'>
              Код страны
            </Typography>
            <Typography variant='subtitle2'>{country.code}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='body2' color='textSecondary'>
              Цифровой код
            </Typography>
            <Typography variant='subtitle2'>{country.numberCode}</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='body2' color='textSecondary'>
              Код валюты
            </Typography>
            <Typography variant='subtitle2'>{country.currencyCode}</Typography>
          </Grid>
        </Grid>
        <Box marginY={2}>
          <Divider />
        </Box>
        <Box>
          <Box mb={1}>
            <Typography variant='subtitle2'>Регистрация</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant='body2' color='textSecondary'>
                Стоимость регистрации
              </Typography>
              <Typography variant='subtitle2'>
                {country.registrationPrice} {country.currencySymbol}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='body2' color='textSecondary'>
                Подарочный бонус
              </Typography>
              <Typography variant='subtitle2'>{country.welcomeBonusAmount}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box marginY={2}>
          <Divider />
        </Box>
        <Box>
          <Box mb={1}>
            <Typography variant='subtitle2'>Комиссия</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant='body2' color='textSecondary'>
                Комиссия по умолчанию
              </Typography>
              <Typography variant='subtitle2'>
                {country.defaultTransactionFee}{' '}
                {country.defaultTransactionFeeType === TransactionFeeType.PERCENT ? '%' : country.currencySymbol}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='body2' color='textSecondary'>
                Мин комиссия
              </Typography>
              <Typography variant='subtitle2'>
                {country.minTransactionFee} {country.currencySymbol}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box marginY={2}>
          <Divider />
        </Box>
        <Box>
          <Box mb={1}>
            <Typography variant='subtitle2'>Порог баланса</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant='body2' color='textSecondary'>
                Уведомление о снижение порога баланса
              </Typography>
              <Typography variant='subtitle2'>{country.balanceNotifyThreshold.toLocaleString()}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant='body2' color='textSecondary'>
                Уведомление о снижение минимального порога баланса
              </Typography>
              <Typography variant='subtitle2'>{country.balanceNotifyMinThreshold.toLocaleString()}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Content>
      <Box mt={3} mb={2} display='flex' flexDirection='row' justifyContent='space-between'>
        <Typography variant='subtitle2'>Города</Typography>
        <Button size='small' onClick={openCityModal}>
          Добавить город
        </Button>
      </Box>
      {country.cities.length > 0 ? (
        <Content noGutter>
          <DataList
            data={country.cities}
            title={'name'}
            secondaryText={item => item.timezone?.title}
            renderActions={renderCityActions}
          />
        </Content>
      ) : (
        <EmptyDataMessage />
      )}
      {countryModalVisible && <CountryFormModal country={country} open onCancel={toggleCountryModal} />}
      {cityModalVisible && <CityFormModal city={openedCity} countryId={country.id} open onCancel={closeCityModal} />}
    </div>
  )
}

export default Countries
