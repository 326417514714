import * as React from 'react'

import { useSnackbar } from 'notistack'

import { getToken, onMessageListener } from './firebase'
import { Box, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { useRegisterAdminDeviceToken } from '../../graphql/hooks/users/useSendDeviceToken'

export const FirebaseNotificationListener: React.FC<{}> = () => {
  // const [isTokenFound, setTokenFound] = React.useState(true)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { registerDeviceToken } = useRegisterAdminDeviceToken()

  const installFirebaseSW = async() => {
    const firebaseSwUrl = `${process.env.PUBLIC_URL}/firebase-sw.js`
    if ('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.ready
        registration?.unregister()
      } catch (error) {}

      navigator.serviceWorker
        .register(firebaseSwUrl)
        .then(function(registration) {
          console.log('Registration Firebase successful, scope is:', registration.scope)
        })
        .catch(function(err) {
          console.log('Service worker Firebase registration failed, error:', err)
        })
    }
  }

  const handleListener = async () => {
    const token = await getToken()
    if (token) {
      registerDeviceToken(token)
    }

    onMessageListener()
      .then(payload => {
        enqueueSnackbar(
          <Box ml={1}>
            <Box fontWeight={600}>{payload.notification?.title}</Box>
            <Box>{payload.notification?.body}</Box>
          </Box>,
          { variant: 'warning' }
        )
      })
      .catch(err => console.log('failed: ', err))
    installFirebaseSW()
  }

  const handlePermission = async () => {
    console.log(Notification.permission)

    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification')
      return
    } else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      handleListener()
    } else if (Notification.permission !== 'denied' && Notification.permission === 'default') {
      Notification.requestPermission(permission => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          handleListener()
        }
      })
    } else {
      console.log('permission denied')
      enqueueSnackbar('Разрешите уведомления для Payda Admin', {
        autoHideDuration: null,
        variant: 'error',
        anchorOrigin: { horizontal: 'center', vertical: 'bottom' },
        action: key => (
          <IconButton color={'inherit'} onClick={() => closeSnackbar(key)}>
            <CloseIcon />
          </IconButton>
        ),
      })
    }
  }

  React.useEffect(() => {
    handlePermission()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}
