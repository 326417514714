import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'
import { ApolloLink, fromPromise } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'

import * as stores from '../stores'
import { stripTypenames } from '../utils/helpers'
import { REFRESH_TOKEN_MUTATION } from '../graphql/queries/auth'
import { IRefreshTokenData } from '../graphql/types/auth'
import { history } from './history'

const API_URL = process.env.REACT_APP_API_URL

const httpLink: ApolloLink = createUploadLink({
  uri: API_URL,
})

const authMiddlewareLink: ApolloLink = new ApolloLink((operation, forward) => {
  const token = stores.authStore.getToken()

  operation.setContext({
    headers: {
      authorization: `Bearer ${token}`,
    },
  })

  return forward(operation)
})

const refreshAuthMiddlewareLink: ApolloLink = new ApolloLink((operation, forward) => {
  const token = stores.authStore.getRefreshToken()

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  })

  return forward(operation)
})

const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const variables = Object.keys(operation.variables)

    if (!variables.includes('file')) {
      operation.variables = stripTypenames(operation.variables, '__typename')
    }
  }

  return forward(operation)
})

const refreshTokenClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(async ({ message, locations, path, extensions }) => {
          if (extensions && ['NO_SESSION', 'INVALID_TOKEN'].includes(extensions.code)) {
            stores.authStore.logout()
            history.push('/login')
          }
        })
      }
      forward(operation)
    }),
    refreshAuthMiddlewareLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
})

const getNewToken = async () => {
  const { data } = await refreshTokenClient.mutate<IRefreshTokenData, {}>({
    mutation: REFRESH_TOKEN_MUTATION,
  })

  if (!!data) {
    stores.authStore.login(data.refreshToken.user, data.refreshToken.token, data.refreshToken.refreshToken)
  }
  return data
}

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (let { message, locations, path, extensions } of graphQLErrors ?? []) {
          if (extensions && ['NO_SESSION'].includes(extensions.code)) {
            stores.authStore.logout()
            history.push('/login')
          }

          if (extensions && ['INVALID_TOKEN'].includes(extensions.code)) {
            return fromPromise(
              getNewToken().catch(error => {
                return
              })
            )
              .filter(value => Boolean(value))
              .flatMap(data => {
                const oldHeaders = operation.getContext().headers
                // modify the operation context with a new token
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${stores.authStore.getToken()}`,
                  },
                })

                // retry the request, returning the new observable
                return forward(operation)
              })
          }

          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        }
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
      }
    }),
    authMiddlewareLink,
    removeTypenameMiddleware,
    httpLink,
  ]),
  cache: new InMemoryCache(),
})
