import { useQuery } from '@apollo/react-hooks'

import { BUSINESS_DETAIL_QUERY } from '../../queries/businesses'
import { IBusinessDetailData, IBusinessDetailVariables } from '../../types/businesses'

export const useBusinessDetail = (id: number) => {
  const { data, ...q } = useQuery<IBusinessDetailData, IBusinessDetailVariables>(BUSINESS_DETAIL_QUERY, {
    variables: { id },
  })

  const business = data?.businessDetail

  return {
    business,
    ...q,
  }
}
