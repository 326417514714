import React, { Fragment } from 'react'
import { Box, Divider, Grid, MenuItem, TextField } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { debounce } from 'lodash'

import { useAllFranchisee } from '../../../graphql/hooks/franchisee'
import { useQueryParams } from '../../../utils'

export const BusinessesFilter = () => {
  const { franchisee } = useAllFranchisee()
  const [{ query }, setQuery] = useQueryParams()

  const [searchText, setSearchText] = React.useState<string>(query.q || '')

  const updateSearchTextQuery = () => {
    const method = searchText === '' ? 'remove' : 'push'
    setQuery({ q: searchText }, method)
  }

  const delayedUpdateSearch = React.useCallback(debounce(updateSearchTextQuery, 500), [searchText])

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.name === 'searchText') {
      setSearchText(target.value)
    } else {
      const method = target.value === 'all' ? 'remove' : 'push'
      setQuery({ [target.name]: target.value }, method)
    }
  }

  React.useEffect(() => {
    delayedUpdateSearch()

    return delayedUpdateSearch.cancel
  }, [searchText, delayedUpdateSearch])

  return (
    <Fragment>
      <Divider />
      <Box paddingX={2} paddingY={1} bgcolor={grey[50]}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label='Название'
              name='searchText'
              value={searchText}
              variant='outlined'
              size='small'
              onChange={handleChange}
              fullWidth
              margin='normal'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label='Видимость'
              variant='outlined'
              size='small'
              name='isVisible'
              onChange={handleChange}
              fullWidth
              margin='normal'
              select
              value={query.isVisible || 'all'}
            >
              <MenuItem value={'all'}>Все</MenuItem>
              <MenuItem value={'true'}>Видимый</MenuItem>
              <MenuItem value={'false'}>Не видимый</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label='Активация'
              variant='outlined'
              size='small'
              name='isActivated'
              onChange={handleChange}
              fullWidth
              margin='normal'
              select
              value={query.isActivated || 'all'}
            >
              <MenuItem value={'all'}>Все</MenuItem>
              <MenuItem value={'true'}>Активированные</MenuItem>
              <MenuItem value={'false'}>Не активированные</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              label='Франчайзи'
              variant='outlined'
              size='small'
              name='franchiseeId'
              onChange={handleChange}
              fullWidth
              margin='normal'
              select
              value={query.franchiseeId || 'all'}
            >
              <MenuItem value={'all'}>Все</MenuItem>
              <MenuItem value={'null'}>Без франчайзи</MenuItem>
              {franchisee.map(fr => (
                <MenuItem key={`franchisee-select-${fr.id}`} value={fr.id}>
                  {fr.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  )
}
