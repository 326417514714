import React from 'react'
import { ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, IconButton, Box } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import DeleteFaqGroupButton from './DeleteFaqGroupButton'
import { IFaqGroup } from '../../../graphql/types/faq'
import FaqGroupEditorModalButton from './FaqGroupEditorModalButton'
import { I18nTypography } from '../../../components/UI'

interface IProps<ItemT> {
  item: ItemT
  title: string | React.ReactNode
  secondaryText?: string | React.ReactElement
  avatar?: React.ReactElement
  onClick?: () => void
}

export const FaqDataListItem: React.FC<IProps<IFaqGroup>> = ({ item, title, secondaryText, avatar, onClick }) => {
  return (
    <ListItem button onClick={onClick}>
      {avatar && <ListItemAvatar>{avatar}</ListItemAvatar>}
      <ListItemText
        primary={<I18nTypography value={item.titles || []} fallback={item.name} />}
        secondary={secondaryText}
      />
      <ListItemSecondaryAction>
        <FaqGroupEditorModalButton color='primary' item={item} component={IconButton} aria-label='edit'>
          <EditIcon />
        </FaqGroupEditorModalButton>
        <Box component='span' ml={1} color='error.main'>
          <DeleteFaqGroupButton item={item} color='inherit'>
            <DeleteIcon />
          </DeleteFaqGroupButton>
        </Box>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
