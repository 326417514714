import { useQuery } from '@apollo/react-hooks'

import { ALL_COUNTRIES_QUERY } from '../../queries/localization'
import { IAllCountriesData, IAllCountriesVariables } from '../../types/localization'

export const useAllCountries = () => {
  const { data, loading } = useQuery<IAllCountriesData, IAllCountriesVariables>(ALL_COUNTRIES_QUERY)

  return {
    countries: data?.allCountries.countries || [],
    total: data?.allCountries.total || 0,
    loading,
  }
}
