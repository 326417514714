import React from 'react'
import {
  Box,
  CardContent,
  Card,
  makeStyles,
  Typography,
  FormControlLabel,
  Checkbox,
  InputLabel,
  FormControl,
  Select,
  Grid,
  IconButton,
  ClickAwayListener,
} from '@material-ui/core'
import { Close as CloseIcon, FilterList as FilterIcon } from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import cn from 'classnames'

import { BusinessAutocomplete } from '../components/BusinessAutocomplete'
import { SalesManagerAutocomplete } from '../components/SalesManagerAutocomplete'
import { BUSINESS_TRANSACTION_TYPE_LABELS, PAYMENT_TYPE_LABELS, STATUS_OPTIONS } from '../../../core/constants'
import { BusinessTransactionStatus, BusinessTransactionType, PaymentType } from '../../../core/interfaces'
import { EnumHelpers } from '../../../utils/enum-helpers'
import { IBusiness, IBusinessShortest } from '../../../graphql/types/businesses'
import { ISalesManager } from '../../../graphql/types/user'
import { FranchiseeAutocomplete } from '../../Franchisee/components/FranchiseeAutocomplete'
import { IFranchisee } from '../../../graphql/types/franchisee'

export interface ITransactionFilter {
  business: IBusinessShortest | IBusiness | null
  franchisee: Pick<IFranchisee, 'id' | 'name'> | null
  manager: ISalesManager | null
  type: string | null
  paymentType?: PaymentType
  status: string | null
  isOnline: boolean
  isManagerBusinesses: boolean
  startDate: Date | string
  endDate: Date | string
}

type Value<T, K extends keyof T> = T[K]

export const useTransactionsFilter = () => {
  const classes = useStyles()
  const initialFilter: ITransactionFilter = {
    business: null,
    manager: null,
    franchisee: null,
    isOnline: false,
    isManagerBusinesses: false,
    type: null,
    status: null,
    startDate: moment()
      .subtract(7, 'week')
      .startOf('day')
      .toDate(),
    endDate: moment()
      .endOf('day')
      .toDate(),
  }

  const [filter, setFilter] = React.useState<ITransactionFilter>(initialFilter)
  const [filterVisible, setFilterVisible] = React.useState<boolean>(false)

  const toggleFilter = () => {
    setFilterVisible(!filterVisible)
  }

  const closeFilter = () => {
    setFilterVisible(false)
  }

  const handleChange = <K extends keyof ITransactionFilter>(field: K) => (value: Value<ITransactionFilter, K>) => {
    setFilter({ ...filter, [field]: value })
  }

  const renderFilter = () => (
    <ClickAwayListener onClickAway={closeFilter}>
      <Box position='relative'>
        <IconButton onClick={toggleFilter}>
          <FilterIcon />
        </IconButton>
        <Card className={cn(classes.container, { [classes.containerClosed]: !filterVisible })} square>
          <CardContent>
            <div className={classes.appBarSpacer} />
            <Box>
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                <Typography variant='subtitle2'>Фильтр</Typography>
                <IconButton size='small' onClick={toggleFilter}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box>
                <BusinessAutocomplete value={filter.business} onSelect={handleChange('business')} />
              </Box>
              <Box mb={1}>
                <SalesManagerAutocomplete value={filter.manager} onSelect={handleChange('manager')} />
              </Box>
              <Box>
                <FranchiseeAutocomplete value={filter.franchisee} onSelect={handleChange('franchisee')} />
              </Box>
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <DatePicker
                      value={filter.startDate}
                      format='YYYY-MM-DD'
                      margin='normal'
                      label='От'
                      fullWidth
                      maxDate={filter.endDate}
                      onChange={momentDate => {
                        if (momentDate) {
                          handleChange('startDate')(momentDate.startOf('day').toDate())
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      value={filter.endDate}
                      margin='normal'
                      format='YYYY-MM-DD'
                      fullWidth
                      minDate={filter.startDate}
                      label='До'
                      onChange={momentDate => {
                        if (momentDate) {
                          handleChange('endDate')(momentDate.endOf('day').toDate())
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <FormControl fullWidth margin='normal'>
                  <InputLabel htmlFor='transaction-status-select'>Статус</InputLabel>
                  <Select
                    native
                    value={filter?.status || ''}
                    onChange={event => {
                      if (!!event.target.value) {
                        handleChange('status')(event.target.value as string)
                      } else {
                        handleChange('status')(null)
                      }
                    }}
                    inputProps={{
                      id: 'transaction-status-select',
                    }}
                  >
                    <option aria-label='None' value='' />
                    {EnumHelpers.getNames(BusinessTransactionStatus).map(key => {
                      return (
                        <option key={`status-${key}`} value={key}>
                          {STATUS_OPTIONS[key].label}
                        </option>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth margin='normal'>
                  <InputLabel htmlFor='transaction-type-select'>Тип транзакции</InputLabel>
                  <Select
                    native
                    value={filter?.type || ''}
                    onChange={event => {
                      if (!!event.target.value) {
                        handleChange('type')(event.target.value as string)
                      } else {
                        handleChange('type')(null)
                      }
                    }}
                    inputProps={{
                      id: 'transaction-type-select',
                    }}
                  >
                    <option aria-label='None' value='' />
                    {EnumHelpers.getValues(BusinessTransactionType).map(key => {
                      return (
                        <option key={`type-${key}`} value={`${key}`}>
                          {BUSINESS_TRANSACTION_TYPE_LABELS[key]}
                        </option>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth margin='normal'>
                  <InputLabel htmlFor='payment-type-select'>Вид платежа</InputLabel>
                  <Select
                    native
                    value={filter?.paymentType || undefined}
                    onChange={event => {
                      if (!!event.target.value) {
                        handleChange('paymentType')(event.target.value as number)
                      } else {
                        handleChange('paymentType')(undefined)
                      }
                    }}
                    inputProps={{
                      id: 'transaction-type-select',
                    }}
                  >
                    <option aria-label='None' value='' />
                    {EnumHelpers.getValues(PaymentType).map(key => {
                      return (
                        <option key={`type-${key}`} value={`${key}`}>
                          {PAYMENT_TYPE_LABELS[key]}
                        </option>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Box mt={1} display='flex' flexWrap='wrap' alignItems='flex-end'>
              <Box mr={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.isOnline}
                      onChange={event => {
                        handleChange('isOnline')(event.target.checked)
                      }}
                      name='isOnline'
                    />
                  }
                  label='Онлайн платеж'
                />
              </Box>
              <Box mr={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.isManagerBusinesses}
                      onChange={event => {
                        handleChange('isManagerBusinesses')(event.target.checked)
                      }}
                      name='isManagerBusinesses'
                    />
                  }
                  label='Подключенные менеджером'
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </ClickAwayListener>
  )

  return {
    filter,
    renderFilter,
    handleChangeFilter: handleChange,
  }
}

const useStyles = makeStyles(theme => ({
  container: {
    width: 260,
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    zIndex: theme.zIndex.drawer,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  containerClosed: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
  },
  appBarSpacer: theme.mixins.toolbar,
}))
