import React, { Fragment, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Box, Divider, Typography } from '@material-ui/core'

import { Content, PageHeader } from '../../components/Layout'
import { CreateFab, Loader } from '../../components/UI'
import PageNotFound from '../PageNotFound'
import { useBusinessDetail } from '../../graphql/hooks/businesses/useBusinessDetail'
import { DataTable, EmptyDataMessage } from '../../components/Data'
import { BusinessCreateCredentialModal, BusinessEditCredentialModal } from './components'
import { IPaginationInput } from '../../graphql/types/common'
import { ROWS_PER_PAGE } from '../../core/constants'
import { useBusinessApiCredentials } from '../../graphql/hooks/external-api'
import Pagination from '../../components/Data/pagination/Pagination'
import { columns } from './integrationColumns'
import { IBusinessApiCredential } from '../../graphql/types/external-api'

interface IProps extends RouteComponentProps<{ id: string }> {}

const BusinessIntegration: React.FC<IProps> = ({ history, match }) => {
  const businessId = parseInt(match.params.id, 10)
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedCredential, setSelectedCredential] = useState<IBusinessApiCredential>()
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [pagination, setPagination] = useState<IPaginationInput>({ offset: 0, limit: ROWS_PER_PAGE[0] })
  const { business } = useBusinessDetail(businessId)
  const { credentials, total, loading } = useBusinessApiCredentials(businessId, pagination)

  if (!business) {
    return <PageNotFound />
  }

  const toggleModal = () => {
    setModalVisible(prev => !prev)
  }

  const handleSelectItem = (item: IBusinessApiCredential) => {
    setSelectedCredential(item)
  }

  const handleCloseEdit = () => {
    setSelectedCredential(undefined)
  }

  const handleChangePerPage = (limit: number) => {
    if (limit !== pagination.limit) {
      setCurrentPage(0)
      setPagination({ offset: 0, limit })
    }
  }

  const handleChangePage = (page: number) => {
    setCurrentPage(page)
    setPagination({ ...pagination, offset: page * pagination.limit })
  }

  return (
    <div>
      <PageHeader title={`${business.companyName} - Интеграция`} back />
      <Box>
        <Content noGutter>
          <Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
            <Typography>Всего: {total}</Typography>
          </Box>
          <Box>
            <Divider />
            {loading ? (
              <Loader />
            ) : (
              <Fragment>
                {credentials.length > 0 ? (
                  <Fragment>
                    <DataTable data={credentials} columns={columns} onClickItem={handleSelectItem} />
                    <Box mt={3}>
                      <Pagination
                        rowsPerPage={pagination.limit}
                        rowsPerPageOptions={ROWS_PER_PAGE}
                        onChangeRowsPerPage={handleChangePerPage}
                        onChangePage={handleChangePage}
                        page={currentPage}
                        count={total}
                      />
                    </Box>
                  </Fragment>
                ) : (
                  <EmptyDataMessage />
                )}
              </Fragment>
            )}
          </Box>
        </Content>
      </Box>
      <CreateFab onClick={toggleModal} />
      {modalVisible && <BusinessCreateCredentialModal business={business} open onClose={toggleModal} />}
      {!!selectedCredential && (
        <BusinessEditCredentialModal credential={selectedCredential} onClose={handleCloseEdit} />
      )}
    </div>
  )
}

export default BusinessIntegration
