import { useMutation } from '@apollo/react-hooks'

import { DELETE_CITY_MUTATION, COUNTRY_DETAIL_QUERY } from '../../queries/localization'
import { IDeleteCityData, IDeleteCityVariables } from '../../types/localization'

export const useDeleteCity = (countryId: number) => {
  const [deleteCityMutation, { loading, error }] = useMutation<IDeleteCityData, IDeleteCityVariables>(
    DELETE_CITY_MUTATION
  )

  const deleteCity = async (id: number) => {
    try {
      await deleteCityMutation({
        variables: { id },
        refetchQueries: [{ query: COUNTRY_DETAIL_QUERY, variables: { id: countryId } }],
      })
    } catch (error) {
      console.log(error)
    }
  }

  return {
    loading,
    error,
    deleteCity,
  }
}
