import { useQuery } from '@apollo/react-hooks'

import { COUNTRY_DETAIL_QUERY } from '../../queries/localization'
import { ICountryDetailData, ICountryDetailVariables } from '../../types/localization'

export const useCountry = (id: number) => {
  const { data, loading } = useQuery<ICountryDetailData, ICountryDetailVariables>(COUNTRY_DETAIL_QUERY, {
    variables: { id },
  })

  return {
    country: data?.countryDetail,
    loading,
  }
}
