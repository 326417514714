import React from 'react'
import { Box, Button } from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import { I18nField, LoadableButton } from '../../../components/Form'
import { IFaq } from '../../../graphql/types/faq'
import { faqSchema } from './validation'
import { I18nTitles } from '../../../graphql/types/common'

interface IProps {
  item?: IFaq
  submitLabel?: string
  loading: boolean
  onSubmit: (values: IFaqFormValues) => void
  onCancel: () => void
}

export interface IFaqFormValues {
  i18nQuestion: I18nTitles
  i18nAnswer: I18nTitles
}

export const FaqForm: React.FC<IProps> = ({ item, submitLabel, loading, onSubmit, onCancel }) => {
  const handleSubmit = (values: IFaqFormValues) => {
    onSubmit(values)
  }

  const initialValues: IFaqFormValues = {
    i18nQuestion: item?.i18nQuestion || [],
    i18nAnswer: item?.i18nAnswer || [],
  }

  return (
    <Formik initialValues={initialValues} validationSchema={faqSchema} onSubmit={handleSubmit}>
      {({ values, errors, setFieldValue, handleChange, submitForm }: FormikProps<IFaqFormValues>) => (
        <div>
          <Box mb={2}>
            <I18nField
              label='Вопрос'
              name='i18nQuestion'
              value={values.i18nQuestion}
              onChange={t => setFieldValue('i18nQuestion', t)}
              required
              fullWidth
              margin='normal'
              error={!!errors.i18nQuestion}
            />
          </Box>
          <Box mb={2}>
            <I18nField
              label='Ответ'
              name='i18nAnswer'
              value={values.i18nAnswer}
              multiline
              rows={4}
              rowsMax={5}
              fullWidth
              onChange={t => setFieldValue('i18nAnswer', t)}
              required
              margin='normal'
              error={!!errors.i18nAnswer}
            />
          </Box>
          <Box pt={4} pb={1} display='flex' flexDirection='row' justifyContent='space-between'>
            <Button onClick={onCancel}>Отмена</Button>
            <LoadableButton loading={loading} color='primary' variant='contained' onClick={submitForm}>
              {submitLabel}
            </LoadableButton>
          </Box>
        </div>
      )}
    </Formik>
  )
}
