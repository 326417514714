import { useQuery } from '@apollo/react-hooks'

import { FRANCHISEE_BY_ID_QUERY } from '../../queries/franchisee'
import { IFranchiseeDetailData, IFranchiseeDetailVariables } from '../../types/franchisee'

export const useFranchiseeDetail = (id: number) => {
  const { data, ...query } = useQuery<IFranchiseeDetailData, IFranchiseeDetailVariables>(FRANCHISEE_BY_ID_QUERY, {
    variables: { id },
  })

  const franchisee = data?.getFranchiseeByIdAdmin

  return { franchisee, ...query }
}
