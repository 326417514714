import gql from 'graphql-tag'

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($phone: PhoneNumber!, $password: String!) {
    loginAdminByPassword(phone: $phone, password: $password) {
      user {
        id
        phone
        fullName
        firstName
        lastName
        email
        birthDate
        gender
        ageGroup
        roles
      }
      token
      refreshToken
    }
  }
`

export const REGISTRATION_ADMIN_DEVICE_TOKEN = gql`
  mutation RegisterAdminDeviceToken($token: String!) {
    registerAdminDeviceToken(token: $token)
  }
`

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshTokenMutation {
    refreshToken {
      user {
        id
        phone
        fullName
        firstName
        lastName
        email
        birthDate
        gender
        ageGroup
        roles
      }
      token
      refreshToken
    }
  }
`

export const RESET_PASSWORD_INFO_QUERY = gql`
  query ResetPasswordInfo($userId: Int!) {
    resetPasswordInfoByUserId(userId: $userId) {
      updatedAt
      isActivated
    }
  }
`
