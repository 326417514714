import gql from 'graphql-tag'

export const ALL_COUNTRIES_QUERY = gql`
  query AllCountriesQuery($pagination: PaginationInput, $filter: CountryFilterInput) {
    allCountries(pagination: $pagination, filter: $filter) {
      countries {
        id
        name
        numberCode
        code
        currencyCode
        currencySymbol
        defaultTransactionFee
        defaultTransactionFeeType
        minTransactionFee
        registrationPrice
        welcomeBonusAmount
        balanceNotifyThreshold
        balanceNotifyMinThreshold
        cities {
          id
          name
          timezone {
            id
            title
          }
        }
      }
      total
    }
  }
`

export const COUNTRY_DETAIL_QUERY = gql`
  query CountryDetailQuery($id: Int!) {
    countryDetail(id: $id) {
      id
      name
      numberCode
      code
      currencyCode
      currencySymbol
      defaultTransactionFee
      defaultTransactionFeeType
      minTransactionFee
      registrationPrice
      welcomeBonusAmount
      balanceNotifyThreshold
      balanceNotifyMinThreshold
      cities {
        id
        name
        timezone {
          id
          title
        }
      }
    }
  }
`

export const UPSERT_COUNTRY_MUTATION = gql`
  mutation UpsertCountryMutation($input: UpsertCountryInput!) {
    upsertCountry(input: $input) {
      id
      name
      numberCode
      code
      currencyCode
      currencySymbol
      defaultTransactionFee
      defaultTransactionFeeType
      minTransactionFee
      registrationPrice
      welcomeBonusAmount
      balanceNotifyThreshold
      balanceNotifyMinThreshold
    }
  }
`

export const UPSERT_CITY_MUTATION = gql`
  mutation UpsertCityMutation($input: UpsertCityInput!) {
    upsertCity(input: $input) {
      id
      name
    }
  }
`

export const DELETE_COUNTRY_MUTATION = gql`
  mutation DeleteCountryMutation($id: Int!) {
    deleteCountry(id: $id)
  }
`

export const DELETE_CITY_MUTATION = gql`
  mutation DeleteCityMutation($id: Int!) {
    deleteCity(id: $id)
  }
`

export const ALL_TIMEZONES_QUERY = gql`
  query AllTimezonesQuery {
    allTimezones {
      timezones {
        id
        title
      }
      total
    }
  }
`
