import React from 'react'
import { Box, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useMutation } from '@apollo/react-hooks'

import { FranchiseeUserForm, IFranchiseeUserFormValues } from '../Forms'
import {
  IFranchisee,
  IUpdateFranchiseeProfileData,
  IUpdateFranchiseeProfileInput,
  IUpdateFranchiseeProfileVariables,
} from '../../../graphql/types/franchisee'
import { UPDATE_FRANCHISEE_PROFILE_MUTATION } from '../../../graphql/queries/franchisee'

interface IProps {
  franchisee: IFranchisee
  open: boolean
  onCancel: () => void
}

export const FranchiseeUserModal: React.FC<IProps> = ({ franchisee, open, onCancel }) => {
  const [updateProfile, { error }] = useMutation<IUpdateFranchiseeProfileData, IUpdateFranchiseeProfileVariables>(
    UPDATE_FRANCHISEE_PROFILE_MUTATION
  )

  const handleSubmit = async (values: IFranchiseeUserFormValues) => {
    const input: IUpdateFranchiseeProfileInput = {
      name: values.name,
      type: Number(values.type),
      cityId: Number(values.cityId),
    }

    await updateProfile({
      variables: { franchiseeId: franchisee.id, input },
    })

    onCancel()
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth onClose={onCancel}>
      <DialogTitle id='form-dialog-title'>Профиль</DialogTitle>
      <DialogContent>
        {!!error && (
          <Box mb={3}>
            <Alert severity='error'>{error}</Alert>
          </Box>
        )}
        <FranchiseeUserForm franchisee={franchisee} onSubmit={handleSubmit} onCancel={onCancel} />
      </DialogContent>
    </Dialog>
  )
}
