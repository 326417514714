import React, { useState } from 'react'
import { ImageOutlined as EmptyIcon, Edit as EditIcon } from '@material-ui/icons'
import { Avatar, Box, CircularProgress, Fab, makeStyles } from '@material-ui/core'
import { compressImage } from '../../../utils'
import { IImage } from '../../../graphql/types/images'

interface IProps {
  image: IImage | null
  onSelect: (file: File) => void
}

export const CategoryImageSelector: React.FC<IProps> = ({ image, onSelect }) => {
  const classes = useStyles()
  const fileInputRef = React.useRef<HTMLInputElement | null>(null)
  const [loading, setLoading] = useState(false)
  const [selectedImage, setSelectedImage] = useState<File | undefined>()

  const handleSelectImage = () => {
    fileInputRef?.current?.click()
  }

  const handleSelectedImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files, validity } = event.target
    const file = files && files[0]

    if (!validity.valid || !file) {
      return null
    }

    try {
      setLoading(true)

      const compressedFile = await compressImage(file, 256)
      setSelectedImage(compressedFile)
      onSelect(compressedFile)
    } catch (error) {
      console.log({ error })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box display='flex'>
      <Box position='relative'>
        <Avatar src={selectedImage ? URL.createObjectURL(selectedImage) : image?.url} className={classes.avatar}>
          <EmptyIcon fontSize='large' />
        </Avatar>
        <Fab
          size='small'
          disabled={loading}
          aria-label='edit'
          className={classes.avatarAction}
          onClick={handleSelectImage}
        >
          {loading ? <CircularProgress size={16} /> : <EditIcon />}
        </Fab>
        <input
          ref={fileInputRef}
          type='file'
          accept='image/*'
          className={classes.hidden}
          onChange={handleSelectedImage}
        />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  avatar: {
    position: 'relative',
    width: 100,
    height: 100,
    maxWidth: '100%',
    overflow: 'auto',
    color: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[200],
  },
  avatarAction: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.speedDial,
    boxShadow: theme.shadows[1],
  },
  hidden: {
    display: 'none',
  },
}))
