import gql from 'graphql-tag'

export const ALL_CATEGORIES_QUERY = gql`
  query AllCategoriesQuery($parentId: Int) {
    allCategories(parentId: $parentId) {
      id
      title
      titles {
        code
        label
      }
      parentId
      sortOrder
      isUsedForMap
      icon {
        id
        url
      }
    }
  }
`

export const UPSERT_CATEGORY_MUTATION = gql`
  mutation UpsertCategoryMutation($input: UpsertCategoryInput!, $icon: Upload) {
    upsertCategory(input: $input, icon: $icon) {
      id
      title
      titles {
        code
        label
      }
      parentId
      sortOrder
      isUsedForMap
      icon {
        id
        url
      }
    }
  }
`

export const DELETE_CATEGORY_MUTATION = gql`
  mutation DeleteCategoryMutation($id: Int!) {
    deleteCategory(id: $id)
  }
`

export const MOVE_CATEGORY_MUTATION = gql`
  mutation MoveCategoryMutation($id: Int!, $parentId: Int, $sortOrders: [CategorySortOrder!]!) {
    moveCategory(id: $id, parentId: $parentId, sortOrders: $sortOrders)
  }
`
