import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/ru'

import PrivateRoute from './components/Routes/PrivateRoute'
import Login from './views/Auth/Login'
import { MainLayout } from './layouts'
import { history } from './core/history'
import { CurrentLanguageProvider } from './services/language'

function App() {
  return (
    <div className='App'>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <CurrentLanguageProvider>
          <Router history={history}>
            <Switch>
              <Route path='/login' name='login' component={Login} />
              <PrivateRoute path='/' component={MainLayout} />
            </Switch>
          </Router>
        </CurrentLanguageProvider>
      </MuiPickersUtilsProvider>
    </div>
  )
}

export default App
