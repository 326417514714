import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  FormControlLabel,
  Switch,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import * as yup from 'yup'
import { DatePicker } from '@material-ui/pickers'
import { useSnackbar } from 'notistack'
import { Delete as DeleteIcon } from '@material-ui/icons'
import moment from 'moment'

import { LoadableButton } from '../../../components/Form'
import { DATE_FORMAT } from '../../../core/constants'
import { IBusinessApiCredential, IBusinessApiCredentialInput } from '../../../graphql/types/external-api'
import { Loader, useConfirm } from '../../../components/UI'
import { useDeleteBusinessApiCredential, useUpdateBusinessApiCredential } from '../../../graphql/hooks/external-api'

interface IProps {
  credential: IBusinessApiCredential
  onClose: () => void
}

interface IFormValues {
  title: string
  expiresAt: string
  isActive: boolean
}

const validationSchema = yup.object().shape({
  title: yup.string().required(),
  expiresAt: yup.string().required(),
  isActive: yup.boolean().required(),
})

export const BusinessEditCredentialModal: React.FC<IProps> = ({ credential, onClose }) => {
  const classes = useStyles()
  const confirm = useConfirm()
  const { enqueueSnackbar } = useSnackbar()
  const [updateCredential, { loading }] = useUpdateBusinessApiCredential(credential.business.id)
  const [deleteCredential, { loading: deleteLoading }] = useDeleteBusinessApiCredential(credential.business.id)

  const initialValues: IFormValues = {
    title: credential.title,
    expiresAt: moment(credential.createdAt).toISOString(),
    isActive: credential.isActive,
  }

  const handleSubmit = async (values: IFormValues) => {
    try {
      const input: IBusinessApiCredentialInput = {
        businessId: credential.business.id,
        ...values,
      }

      await updateCredential({
        variables: { id: credential.id, input },
      })

      enqueueSnackbar('Данные успешно сохранены', { variant: 'success' })
      onClose()
    } catch (err) {
      console.log(err)
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }

  const handleDelete = async () => {
    try {
      await confirm({ message: 'Вы действительно хотите удалить?' })
    } catch {
      return
    }

    try {
      await deleteCredential({
        variables: { id: credential.id },
      })

      enqueueSnackbar('Данные успешно удалены', { variant: 'success' })
      onClose()
    } catch (err) {
      console.log(err)
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ values, errors, setFieldValue, handleChange, submitForm }: FormikProps<IFormValues>) => (
        <Dialog open maxWidth='sm' fullWidth aria-labelledby='form-dialog-title'>
          <DialogTitle id='form-dialog-title'>Редактирование ключа</DialogTitle>
          <DialogContent>
            <Box>
              <Box mb={2}>
                <FormControlLabel
                  control={<Switch checked={values.isActive} />}
                  label='Активный'
                  name='isActive'
                  onChange={handleChange}
                />
              </Box>
              <Box mb={2}>
                <TextField
                  label='Название'
                  name='title'
                  value={values.title}
                  onChange={handleChange}
                  required
                  fullWidth
                  margin='normal'
                  error={!!errors.title}
                />
              </Box>
              <DatePicker
                variant='inline'
                label='Срок действия до'
                value={values.expiresAt}
                onChange={date => setFieldValue('expiresAt', date?.toISOString())}
                autoOk
                format={DATE_FORMAT}
                fullWidth
                error={!!errors.expiresAt}
              />
            </Box>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Box>
              {deleteLoading ? (
                <Loader />
              ) : (
                <IconButton onClick={handleDelete}>
                  <DeleteIcon fontSize='small' color='error' />
                </IconButton>
              )}
            </Box>
            <Box>
              <Button onClick={onClose} color='default'>
                Закрыть
              </Button>
              <LoadableButton loading={loading} color='primary' onClick={submitForm}>
                Сохранить
              </LoadableButton>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  )
}

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
}))
