import React from 'react'
import { FormControlLabel, Switch } from '@material-ui/core'

import { IBusiness } from '../../../graphql/types/businesses'
import { useSwitchBusinessSMS } from '../../../graphql/hooks/businesses'

interface IProps {
  business: IBusiness
}

export const BusinessSmsSwitcher: React.FC<IProps> = ({ business }) => {
  const [switchSMS] = useSwitchBusinessSMS(business.id)
  const [isEnabled, setIsEnabled] = React.useState<boolean>(business.isSMSEnabled)

  const handleSwitch = async () => {
    await switchSMS({
      variables: { businessId: business.id },
    })

    setIsEnabled(!isEnabled)
  }

  return <FormControlLabel control={<Switch checked={isEnabled} />} label='Отправка СМС' onChange={handleSwitch} />
}
