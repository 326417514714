import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
  Drawer,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Box,
} from '@material-ui/core'
import { ExitToApp, ChevronLeft, ExpandLess, ExpandMore } from '@material-ui/icons'
import cn from 'classnames'

import { DRAWER_WIDTH } from '../../core/constants'
import { IDrawerRouteItem } from '../../core/interfaces'
import { observer } from 'mobx-react'
import useStores from '../../stores/hooks/useStores'
import { grey } from '@material-ui/core/colors'

interface IProps {
  routes: IDrawerRouteItem[]
  open: boolean
  onClose: () => void
}

export const AppDrawer: React.FC<IProps> = observer(({ routes, open, onClose }) => {
  const classes = useStyles()
  const [itemsCollapsed, setItemsCollapsed] = React.useState<{ [key: string]: boolean }>({})
  const { authStore } = useStores()

  const handleCollapse = (name: string) => {
    setItemsCollapsed({ [name]: !itemsCollapsed[name] })
  }

  const renderItems = (items: IDrawerRouteItem[]) => {
    return items.map(({ children, name, title, path, icon: ItemIcon, allowedRoles, disabled = false }) => {
      if (allowedRoles && !authStore.userHasRoles(allowedRoles)) {
        return null
      }
      if (children) {
        return (
          <Fragment key={name}>
            <ListItem button disabled={disabled} onClick={() => handleCollapse(name)}>
              {!!ItemIcon && (
                <ListItemIcon title={title}>
                  <ItemIcon />
                </ListItemIcon>
              )}
              <ListItemText primary={title} />
              {itemsCollapsed[name] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={itemsCollapsed[name]} timeout='auto' unmountOnExit>
              <Box bgcolor={grey[200]}>
                <List component='div' disablePadding>
                  {renderItems(children)}
                </List>
              </Box>
            </Collapse>
          </Fragment>
        )
      }

      return (
        <ListItem button component={Link} to={path} disabled={disabled} key={name}>
          {!!ItemIcon && (
            <ListItemIcon title={title}>
              <ItemIcon />
            </ListItemIcon>
          )}
          <ListItemText primary={title} />
        </ListItem>
      )
    })
  }

  return (
    <Drawer
      classes={{
        paper: cn(classes.drawerPaper, !open && classes.drawerPaperClose),
        docked: cn(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
      onClose={onClose}
      variant='permanent'
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={onClose}>
          <ChevronLeft />
        </IconButton>
      </div>
      <Divider light />
      <List>{renderItems(routes)}</List>
      <Divider light />
      <List>
        <ListItem button component={Link} to='/logout'>
          <ListItemIcon title={'Выйти'}>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary='Выйти' />
        </ListItem>
      </List>
    </Drawer>
  )
})

const useStyles = makeStyles(theme => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
    },
  },
}))
