import React from 'react'
import { Box, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useUpsertCountry } from '../../../../graphql/hooks/localization'
import { IUpsertCountryInput, ICountry } from '../../../../graphql/types/localization'
import { CountryForm, ICountryFormValues } from './CountryForm'

interface IProps {
  open: boolean
  country?: ICountry
  onCancel: () => void
}

export const CountryFormModal: React.FC<IProps> = ({ open, country, onCancel }) => {
  const { loading, error, upsertCountry } = useUpsertCountry()

  const handleSubmit = async (values: ICountryFormValues) => {
    const input: IUpsertCountryInput = {
      id: country ? country.id : undefined,
      name: values.name,
      numberCode: parseInt(values.numberCode),
      code: values.code,
      currencyCode: values.currencyCode,
      currencySymbol: values.currencySymbol,
      defaultTransactionFee: parseFloat(values.defaultTransactionFee),
      defaultTransactionFeeType: values.defaultTransactionFeeType,
      minTransactionFee: parseFloat(values.minTransactionFee),
      registrationPrice: parseFloat(values.registrationPrice),
      welcomeBonusAmount: parseFloat(values.welcomeBonusAmount),
      balanceNotifyThreshold: parseFloat(values.balanceNotifyThreshold),
      balanceNotifyMinThreshold: parseFloat(values.balanceNotifyMinThreshold),
    }

    await upsertCountry(input)
    onCancel()
  }

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle id='form-dialog-title'>Страна</DialogTitle>
      <DialogContent>
        {!!error && (
          <Box mb={2}>
            <Alert severity='error'>{error.message}</Alert>
          </Box>
        )}
        <Box>
          <CountryForm country={country} loading={loading} onSubmit={handleSubmit} onCancel={onCancel} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
