import React, { Fragment } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Box, Divider, IconButton, Typography } from '@material-ui/core'
import { FilterList as FilterIcon, Close as CloseIcon } from '@material-ui/icons'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'

import { DATE_TIME_FORMAT } from '../../core/constants'
import { PageHeader, Content } from '../../components/Layout'
import { CreateFab, Loader } from '../../components/UI'
import { DataTable, EmptyDataMessage, IDataTableColumn } from '../../components/Data'
import { IAllFranchiseeData, IAllFranchiseeVariables, IFranchisee } from '../../graphql/types/franchisee'
import { ALL_FRANCHISEE_QUERY } from '../../graphql/queries/franchisee'
import { AddFranchiseeModal } from './components'

interface IProps extends RouteComponentProps {}

const columns: IDataTableColumn<IFranchisee>[] = [
  { label: 'ID', accessor: 'id', style: { width: 70 } },
  { label: 'Франчайзи', accessor: 'name' },
  { label: 'Баланс', accessor: 'balance' },
  { label: 'Город', accessor: item => item.city.name },
  { label: 'Дата регистрации', accessor: item => moment(item.createdAt).format(DATE_TIME_FORMAT) },
]

const Franchisee: React.FC<IProps> = ({ history }) => {
  const [addModalVisible, setAddModalVisible] = React.useState<boolean>(false)
  const { data, loading } = useQuery<IAllFranchiseeData, IAllFranchiseeVariables>(ALL_FRANCHISEE_QUERY, {
    variables: {
      pagination: undefined,
      filter: undefined,
    },
  })
  const franchisee = data?.allFranchiseeByAdmin?.franchisee || []
  const total = data?.allFranchiseeByAdmin?.total || 0

  const toggleModal = () => {
    setAddModalVisible(!addModalVisible)
  }

  const handleClickItem = (item: { id: number; name: string }) => {
    history.push(`/franchisee/${item.id}`)
  }

  return (
    <div>
      <PageHeader title='Франчайзи' />
      <Content noGutter>
        <Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
          <Typography>Всего: {total}</Typography>
          <IconButton disabled>{false ? <CloseIcon /> : <FilterIcon />}</IconButton>
        </Box>
        <Divider />
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            {franchisee.length > 0 ? (
              <DataTable data={franchisee} columns={columns} onClickItem={handleClickItem} />
            ) : (
              <EmptyDataMessage />
            )}
          </Fragment>
        )}
      </Content>
      <CreateFab onClick={toggleModal} />
      {addModalVisible && <AddFranchiseeModal open={addModalVisible} onCancel={toggleModal} />}
    </div>
  )
}

export default Franchisee
