import React from 'react'
import { Box } from '@material-ui/core'
import { CheckCircleOutlined as EnabledIcon, HighlightOffOutlined as DisabledIcon } from '@material-ui/icons'
import { green } from '@material-ui/core/colors'

interface IProps {
  enabled: boolean
}

export const SmsEnabledColumn: React.FC<IProps> = ({ enabled }) => {
  return enabled ? (
    <Box color={green[500]}>
      <EnabledIcon color='inherit' />
    </Box>
  ) : (
    <DisabledIcon color='disabled' />
  )
}
