import React from 'react'
import { CreateFab } from '../../../components/UI'
import { Dialog, DialogTitle, DialogContent, Box, ButtonBaseProps } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useMutation } from '@apollo/react-hooks'
import { UPSERT_FAQ_MUTATION, FAQ_GROUP_BY_ID_QUERY } from '../../../graphql/queries/faq'
import { IFaq, IUpsertFaqData, IUpsertFaqInput, IUpsertFaqVariables } from '../../../graphql/types/faq'
import { FaqForm, IFaqFormValues } from './FaqForm'

interface IProps extends ButtonBaseProps {
  groupId: number
  item?: IFaq
  component?: React.ElementType
}

const FaqEditorModalButton: React.FC<IProps> = ({ groupId, item, component: ButtonComponent, ...props }) => {
  const [open, setOpen] = React.useState(false)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const [upsertFaq, { loading }] = useMutation<IUpsertFaqData, IUpsertFaqVariables>(UPSERT_FAQ_MUTATION)

  const handleToggle = () => {
    setOpen(!open)
  }

  const handleSubmit = async (values: IFaqFormValues) => {
    const input: IUpsertFaqInput = {
      id: item?.id,
      i18nQuestion: values.i18nQuestion,
      i18nAnswer: values.i18nAnswer,
      groupId,
    }

    try {
      await upsertFaq({
        variables: { input },
        refetchQueries: [{ query: FAQ_GROUP_BY_ID_QUERY, variables: { id: groupId } }],
      })
      handleToggle()
    } catch (err) {
      setError((err as Error).message)
    }
  }

  return (
    <>
      {ButtonComponent ? <ButtonComponent {...props} onClick={handleToggle} /> : <CreateFab onClick={handleToggle} />}
      <Dialog open={open} maxWidth='sm' fullWidth>
        <DialogTitle id='form-dialog-title'>{item?.id ? 'Изменить FAQ' : 'Новый FAQ'}</DialogTitle>
        <DialogContent>
          {!!error && (
            <Box mb={3}>
              <Alert severity='error'>{error}</Alert>
            </Box>
          )}
          <Box>
            <FaqForm
              item={item}
              loading={loading}
              submitLabel={item?.id ? 'Сохранить' : 'Создать'}
              onSubmit={handleSubmit}
              onCancel={handleToggle}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default FaqEditorModalButton
