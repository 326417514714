import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { parse, stringify } from 'qs'

interface IQueryParams {
  [key: string]: any
}

type QueryParamsTuple = [
  { query: IQueryParams; rawQuery: string },
  (nextQuery: IQueryParams, method?: 'push' | 'remove') => void
]

export const useQueryParams = (initialParams: IQueryParams = {}): QueryParamsTuple => {
  const { search } = useLocation()
  const { push } = useHistory()

  const query = useMemo(() => {
    const searchQuery = search.substring(1)

    if (!search) {
      return initialParams
    }

    return parse(searchQuery)
  }, [search, initialParams])

  const setQuery = useCallback(
    (nextParams: IQueryParams, method: 'push' | 'remove' = 'push') => {
      let nextQuery = { ...query }

      if (method === 'remove') {
        Object.keys(nextParams).forEach(key => {
          delete nextQuery[key]
        })
      } else {
        nextQuery = { ...query, ...nextParams }
      }

      push({ search: stringify(nextQuery, { encode: false }) })
    },
    [push, query]
  )

  return [{ query, rawQuery: search }, setQuery]
}
